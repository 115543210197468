package com.tekdiving.vp2

import com.tekdiving.deco.*

data class VP2Dive(
    val bottomDepth: Double, val bottomTime: Double,
    val ppO2: Double, val mix: TankMix,
    /* The slower speed in case of saturation, default is 27 meters per days */
    val slowestSpeed: Double = 0.01875
) {
    val program = programFromExposingFactor(bottomDepth, bottomTime)

    val isN2Dive = mix.nitrogen.real > 30

    val k1 = if (isN2Dive) 0.0025 else 0.005

    val maxSpeed = if (isN2Dive) 12.0 else 6.0

    val tank = Tank(TankType.CCR, mix, TankSpec(), ppO2)
}
