package com.tekdiving.plotter

import bulma.*
import io.data2viz.geom.Size
import io.data2viz.geom.size
import kotlinx.html.style
import org.w3c.dom.HTMLElement
import kotlin.math.roundToInt
import kotlin.properties.Delegates.observable

class LineChartController(
    line: List<Line> = emptyList(),
    size: Size = size(800.0, 400.0),
    val stepName: String = "",
    val roundPoints: Boolean = true
) : Controller<List<Line>, Size, Div> {

    val canvas = canvas {
        style = "width:100%"
    }

    override var data: List<Line> by observable(line) { _, old, new ->
        if (old != new) {
            chart = createChart(new)
            legend.columns = createColumns(new)
        }
    }

    var size: Size
        get() = context;
        set(value) {
            context = value
        }

    override var context: Size by observable(size) { _, old, new ->
        if (old != new) {
            chart.size = new
            canvas.root.width = context.width.toInt()
            canvas.root.height = context.height.toInt()
            renderRequest()
        }
    }

    override var readOnly: Boolean = false

    private var chart: LineChart = createChart(data)

    private val stepValueSpan = Label("$stepName -")
    private val legendValueSpans = mutableListOf<HtmlWrapper<HTMLElement>>()

    val legend = Columns(multiline = true).apply { columns = createColumns(data) }

    override val container: Div = Div(canvas, legend)

    override fun refresh() {
        renderRequest()
    }

    private fun createChart(lines: List<Line>) = LineChart(canvas.root, lines, context) { label ->
        val pointsForLabel = chart.pointsForLabel(label)
        stepValueSpan.text = "$stepName $label"
        legendValueSpans.forEachIndexed { index, valueSpan ->
            val get = pointsForLabel?.get(index)?.let {
                if (roundPoints) it.roundToInt() else it
            }
            valueSpan.text = "${get ?: "-"}"
        }
    }

    private fun colorIcon(color: String) = Icon("").apply {
        root.style.backgroundColor = color
        root.style.borderRadius = "50%"
        root.style.color = color
        root.style.marginRight = "0.5rem"
    }

    private fun createColumns(lines: List<Line>): List<Column> {
        legendValueSpans.clear()
        return listOf(Column(stepValueSpan, narrow = true)) +
                lines.map { plot ->
                    val valueSpan = span("0", "is-italic")
                    legendValueSpans.add(valueSpan)
                    Column(
                        Level(
                            center = listOf(
                                colorIcon(plot.strokeColor.rgbHex),
                                span(plot.label),
                                span(":"),
                                valueSpan
                            ),
                            mobile = true
                        ),
                        narrow = true
                    )
                }
    }

    fun renderRequest() {
        chart.renderRequest()
    }
}
