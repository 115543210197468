package com.tekdiving.client

import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.a
import kotlinx.html.dom.create
import kotlinx.html.js.onClickFunction
import org.w3c.dom.*
import org.w3c.dom.url.URLSearchParams
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.Promise

data class Version(
    val date: String
)

const val finalState: Short = 4
const val successStatus: Short = 200

fun fetchVersion(): Promise<Version> = Promise { resolve, reject ->
    val request = XMLHttpRequest()
    request.open("get", "version.json")
    request.onreadystatechange = {
        if (request.readyState == finalState) {
            if (request.status == successStatus) {
                resolve(Version(request.responseText))
            } else {
                reject(Throwable("Can't fetch 'version.json': (${request.status}) ${request.statusText}"))
            }
        }
    }
    request.send()
}

fun showVersion() {
    val dateElement = document.getElementById("date")

    if (dateElement is HTMLElement) {
        val version = fetchVersion()
        version.then { dateElement.innerText = it.date }
        .catch { dateElement.innerText = it.message ?: "error" }
    }
}

fun addMenu() {
    val menu = document.querySelector(".navbar-menu > .navbar-start") as HTMLDivElement
    pages.forEach { page ->
            menu.appendChild(document.create.a(classes = "navbar-item td-${page.id}") {
                +page.title
                onClickFunction = { activatePage(page) }
            })
        }

}

fun updateActivePage(page: Page) {
    // clear active status
    val menu = document.querySelector(".navbar-menu > .navbar-start") as HTMLDivElement
    for (item in menu.querySelectorAll(".navbar-item").asList()) {
        if (item is HTMLElement) {
            item.classList.remove("has-text-weight-bold")
        }
    }
    // adds active status to current menu
    val item = menu.querySelector("a.td-${page.id}")
    if (item is HTMLElement) {
        item.classList.add("has-text-weight-bold")
    }

    // update page parameter in URL
    window.location.let {
        val params = URLSearchParams(it.search)
        params.set("page", page.id)
        val newUrl = "${it.protocol}//${it.host}${it.pathname}?$params"
        window.history.pushState(null, "I3deal ${page.title}", newUrl)
    }
}

fun activateNavBar() {
    val all = document.querySelectorAll(".navbar-burger")
    for (i in 0 until all.length) {
        val burger = all[i] as HTMLElement
        burger.addEventListener("click", { _ ->
            // Get the target from the "data-target" attribute
            val target = burger.dataset["target"]
            if (target != null) {
                val targetElement = document.getElementById(target) as HTMLElement
                burger.classList.toggle("is-active")
                targetElement.classList.toggle("is-active")
            }
        })
    }
}

fun initialize() {
    activateNavBar()
    showVersion()
    addMenu()
}
