package com.tekdiving.deco

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.math.abs
import kotlin.math.ceil
import kotlin.math.roundToInt

/** Diver speed in meter per minutes while descending */
const val standardDescendingSpeed = 25

/** Diver speed while reaching first stop when using Trimix Helium */
const val trimixHeliumFirstStopAscendingSpeed = 6

/** Diver speed while reaching first stop */
const val standardFirstStopAscendingSpeed = 10

/** Diver speed between stops */
const val standardStopAscendingSpeed = 6

fun timeForDistance(distance: Double, speed: Int) = distance / speed

fun bottomTime(depth: List<DivePlot>): Int {
    val maximumDepth = depth.asSequence().map { (_, d) -> d }.maxOrNull() ?: 0.0
    return depth
        .fold(DivePlot(0.0, 0.0, null) to 0.0) { (p, time), c ->
            c to time + if (p.depth >= maximumDepth && c.depth >= maximumDepth) c.time - p.time else 0.0
        }.second.roundToInt()
}

fun toPressure(depth: Double, atmosphericPressure: Double = seaLevelAtmosphericPressure) =
    depth / 10.0 + atmosphericPressure

fun toDepth(pressure: Double, atmosphericPressure: Double = seaLevelAtmosphericPressure) =
    (pressure - atmosphericPressure) * 10.0

/** Point in the dive profile */
@Serializable
data class DivePlot(
    /** Absolute time for the point */
    val time: Double,
    /** Depth for the point */
    val depth: Double,
    /** Tank to use (if set) to reach this point */
    val tankToReach: Tank? = null
)

fun nextTankType(previousType: TankType) = when (previousType) {
    TankType.CCR, TankType.Bailout -> TankType.Bailout
    else -> TankType.Deco
}

@Serializable
sealed class DiveOptions {
    @Serializable
    data class CCROptions(val setOxygenPartialPressure: Double = 1.3) : DiveOptions()

    @Serializable
    data class SCROptions(
        val flowRate: Double = 14.0,
        val oxygenConsumption: Double = 1.0
    ) : DiveOptions() {

        fun loopOxygenRatio(ratio: Double) = ((flowRate * ratio) - oxygenConsumption) / (flowRate - oxygenConsumption)

        fun tankOxygenRatio(loopRatio: Double) = loopRatio + (oxygenConsumption * (1 - loopRatio)) / flowRate
    }

    @Serializable
    class NoOptions : DiveOptions()
}

fun oxygenPartialPressureSet(type: DiveType<*>) = type.options.let {
    if (it is DiveOptions.CCROptions) it.setOxygenPartialPressure else partialPressureOxygenMaxCCR
}


@Serializable
sealed class DiveType<out Options : DiveOptions> {
    abstract val options: Options
    abstract val mainTankType: TankType
    abstract val nextTankType: TankType

    @Transient
    open val depthLimit: Double = expeditionDepthLimit

    abstract fun mainTankMix(profile: DiveProfile): TankMix
    abstract fun secondaryTankMix(profile: DiveProfile, depth: Double, previous: Tank): TankMix?

    abstract fun updateOptions(options: DiveOptions): DiveType<Options>

    @Serializable
    data class CCRDive(
        override val options: DiveOptions.CCROptions = DiveOptions.CCROptions()
    ) : DiveType<DiveOptions.CCROptions>() {
        override val mainTankType: TankType = TankType.CCR
        override val nextTankType: TankType = TankType.CCR
        override fun mainTankMix(profile: DiveProfile) = mainRebreatherTankMix(profile)
        override fun secondaryTankMix(profile: DiveProfile, depth: Double, previous: Tank): TankMix? = null
        override fun updateOptions(options: DiveOptions) =
            if (options is DiveOptions.CCROptions) CCRDive(options) else this

        override fun toString() = "CCR"
    }

    @Serializable
    data class BailoutDive(
        override val options: DiveOptions.CCROptions = DiveOptions.CCROptions()
    ) : DiveType<DiveOptions.CCROptions>() {
        override val mainTankType: TankType = TankType.CCR
        override val nextTankType: TankType = TankType.Bailout
        override fun mainTankMix(profile: DiveProfile) = mainRebreatherTankMix(profile)
        override fun secondaryTankMix(profile: DiveProfile, depth: Double, previous: Tank) =
            newBailoutTankMix(profile, depth, previous)

        override fun updateOptions(options: DiveOptions) =
            if (options is DiveOptions.CCROptions) BailoutDive(options) else this

        override fun toString() = "Bailout"
    }

    @Serializable
    data class SCRDive(
        override val options: DiveOptions.SCROptions = DiveOptions.SCROptions()
    ) : DiveType<DiveOptions.SCROptions>() {
        override val mainTankType: TankType = TankType.SCR
        override val nextTankType: TankType = TankType.SCRDeco
        override val depthLimit: Double = scrDepthLimit
        override fun mainTankMix(profile: DiveProfile) = srcTankMix(profile.maximumPressure, options)
        override fun secondaryTankMix(profile: DiveProfile, depth: Double, previous: Tank) =
            if (previous.type == TankType.SCR) srcTankMix(profile.toPressure(depth), options) else null

        override fun updateOptions(options: DiveOptions) =
            if (options is DiveOptions.SCROptions) SCRDive(options) else this

        override fun toString() = "SCR"
    }

    @Serializable
    object DefaultOCDive : DiveType<DiveOptions.NoOptions>() {
        override val options: DiveOptions.NoOptions = DiveOptions.NoOptions()
        override val mainTankType: TankType = TankType.OC
        override val nextTankType: TankType = TankType.Deco
        override fun mainTankMix(profile: DiveProfile) = mainOpenTankMix(profile)
        override fun secondaryTankMix(profile: DiveProfile, depth: Double, previous: Tank) =
            newDecoTankMix(profile, depth, previous)

        override fun updateOptions(options: DiveOptions) = this
        override fun toString() = "OC"
    }

    companion object {
        val defaultCCRDive = CCRDive()
        val defaultBailoutDive = BailoutDive()
        val defaultSCRDive = SCRDive()
    }
}

fun createDiveProfile(
    depth: List<DivePlot> = listOf(),
    type: DiveType<*> = DiveType.DefaultOCDive,
    consumption: Int = 10,
    atmosphericPressure: Double = seaLevelAtmosphericPressure,
    program: Program = program(depth)
) = DiveProfile(depth, type, consumption, atmosphericPressure, program)

@Serializable
data class DiveProfile(
    /** Depth graph */
    val depth: List<DivePlot>,

    /** What to plan for OC, CCR or Bailout */
    val type: DiveType<*>,

    /** Diver breath consumption in liter per minute */
    val consumption: Int,

    /** Atmospheric pressure */
    val atmosphericPressure: Double,

    /**
     * Forced program to use, specially used inside [program], otherwise it computes
     * a time to surface for [depth] to select the program.
     */
    val program: Program
) {
    /** Pressure for given [depth] in meters */
    fun toPressure(depth: Double) = depth / 10.0 + atmosphericPressure

    /** Depth for given [pressure] in bars */
    fun toDepth(pressure: Double) = (pressure - atmosphericPressure) * 10.0

    /** Profile's final depth in meters */
    @Transient
    val finalDepth = depth.lastOrNull()?.depth ?: 0.0

    @Transient
    val maximumDepth = depth.asSequence().map { (_, d) -> d }.maxOrNull() ?: 0.0

    @Transient
    val maximumPressure = toPressure(maximumDepth)

    /** Profile's final time in minutes */
    @Transient
    val finalTime = depth.lastOrNull()?.time ?: 0.0

    @Transient
    val bottomTime = bottomTime(depth)

    /** Gets the advised gas mix for profile depending on the program. */
    @Transient
    val advisedGasMix = advisedGasMixForProgram(program, maximumDepth)

    /** Tests if [tank] is suitable for given [depth]. It uses hypoxia minimum */
    fun isTankSuitableForDepth(tank: Tank, depth: Double) =
        depth > tankHypoxiaDepth(tank) && depth <= tankMaximumDepth(tank)

    /**
     * Computes the distance from max depth for [tank] compared to given [depth].
     * It returns null if depth isn't inside min and max ppo2.
     * If [untilHypoxia] is true, the hypoxia ppo2 is used for min otherwise it's the decompression min ppo2.
     */
    fun tankDistanceFromOptimalDepth(tank: Tank, depth: Double, untilHypoxia: Boolean = false): Double? {
        val min = if (untilHypoxia) tankHypoxiaDepth(tank) else tankMinimumDepth(tank)
        val max = tankMaximumDepth(tank)
        return if (depth in min..max) max - depth else null
    }

    fun tankHypoxiaDepth(tank: Tank) = ceil(toDepth(tank.hypoxiaPressure(type))).coerceAtLeast(0.0)

    fun tankMinimumDepth(tank: Tank) = ceil(toDepth(tank.minimumPressure(type))).coerceAtLeast(0.0)

    fun tankMaximumDepth(tank: Tank) = ceil(toDepth(tank.maximumPressure(type)))

    /** Compute [mix] density with [pressure] in bars */
    fun tankMixDensity(mix: TankMix, pressure: Double) =
        pressure * (oxygenMass * mix.oxygen.ratio + heliumMass * mix.helium.ratio + nitrogenMass * mix.nitrogen.ratio)

    @Transient
    val valid =
        maximumDepth > 0 && maximumDepth <= type.depthLimit &&
                finalTime > 0 && consumption > 0 && consumption <= 200

}

/**
 * Creates a simple squared [List] that goes to [bottomDepth] for [bottomTime] at [descendingSpeed].
 */
fun squarePlot(bottomDepth: Int, bottomTime: Int, descendingSpeed: Int = standardDescendingSpeed): List<DivePlot> {
    val descendingTime = timeForDistance(bottomDepth.toDouble(), descendingSpeed)
    val bottomDepthDouble = bottomDepth.toDouble()
    return listOf(DivePlot(descendingTime, bottomDepthDouble), DivePlot(descendingTime + bottomTime, bottomDepthDouble))
}

fun squarePlot(level: DivePlot, descendingSpeed: Int = standardDescendingSpeed) =
    leveledPlot(listOf(level), descendingSpeed)

/**
 * Creates a simple squared [DiveProfile] that goes to [bottomDepth] for [bottomTime].
 *
 * See [squarePlot].
 */
fun squareProfile(
    type: DiveType<*>, bottomDepth: Int, bottomTime: Int, consumption: Int,
    descendingSpeed: Int = standardDescendingSpeed
) =
    createDiveProfile(squarePlot(bottomDepth, bottomTime, descendingSpeed), type, consumption)


fun leveledPlot(
    levels: Iterable<DivePlot>,
    descendingSpeed: Int = standardDescendingSpeed,
    startingDepth: Int = 0
): List<DivePlot> {
    val plot = mutableListOf<DivePlot>()
    var currentDepth = startingDepth.toDouble()
    var currentTime = 0.0
    for (level in levels) {
        val depth = level.depth
        val time = level.time

        if (currentDepth != depth) {
            currentTime += timeForDistance(abs(depth - currentDepth), descendingSpeed)
            plot.add(DivePlot(currentTime, depth, level.tankToReach))
        }

        currentTime += time
        plot.add(DivePlot(currentTime, depth, level.tankToReach))

        currentDepth = depth
    }
    return plot
}

