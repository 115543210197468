package com.tekdiving.utp

import com.tekdiving.deco.*
import com.tekdiving.deco.DiveType.CCRDive

data class UtpConfig(
    val capsuleDepth: Double = 12.0,
    val capsuleOxygenPartialPressure: Double = 0.4,
    val diveOxygenPartialPressure: Double = 1.3,
    val exitOxygenPartialPressure: Double = 1.7,
    val heliumRatio: Int = 100,
    val gradientFactors: GradientFactors = GradientFactors(10, 50),
    val normP: Double = 12.0
) {
    // TODO incorrect oxygenPartialPressure
    val tank: Tank = createTank(
        TankType.CCR,
        createTankMix(fixedGazRatio(100 - heliumRatio), fixedGazRatio(heliumRatio))
    )

    val tankPlannerOptions = TankPlannerOptions(plan = false, forcedMainMix = tank.mix)

    val diveType = CCRDive(DiveOptions.CCROptions(diveOxygenPartialPressure))

    fun capsuleSaturation() = let {
        val saturation = Saturation({ capsuleOxygenPartialPressure })

        val pressure = toPressure(capsuleDepth)
        val gradient = gradient(gradientFactors, capsuleDepth, capsuleDepth)
        saturation.stepSaturation(0.0, pressure, 1.2, gradient, tank)
        saturation.stepSaturation(pressure, pressure, 60.0 * 24, gradient, tank)
        saturation
    }

    fun decompression(profile: DiveProfile): Decompression {
        val capsuleSaturation = capsuleSaturation()
        return Decompression(
            profile, tankPlannerOptions,
            forcedGradientFactors = gradientFactors,
            startDepth = capsuleDepth, endDepth = capsuleDepth,
            saturation = Saturation(
                ccrSetOxygenPartialPressure = { oxygenPartialPressureSet(diveType) },
                initialNitrogenSaturation = capsuleSaturation.nitrogenSaturation,
                initialHeliumSaturation = capsuleSaturation.heliumSaturation,
                normNPValue = normP
            ),
            infoLevel = InfoType.Info
        )
    }

}
