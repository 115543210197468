package com.tekdiving.deco

import kotlin.math.ceil
import kotlin.math.exp
import kotlin.math.ln

fun constantInitialSaturation(value: Double) = DoubleArray(compartmentIndexes.size) { value }

class Saturation(

    // TODO this property should have `{ oxygenPartialPressureSet(utpConfig.diveType) }` for default but it needs dive type
    /** Oxygen Partial Pressure set overtime for CCR */
    val ccrSetOxygenPartialPressure: (time: Double) -> Double,

    /** Selected alveolar pressure */
    val alveolarPressure: Double = buhlmannAlveolarPressure,

    /** Value for np norm */
    val normNPValue: Double = 50.0,

    /** Initial nitrogen saturation with air ratio*/
    initialNitrogenSaturation: DoubleArray = constantInitialSaturation((1.0 - oxygenRatioInAir) * seaLevelAtmosphericPressure),

    /** Initial helium saturation with air nitrogen ratio*/
    initialHeliumSaturation: DoubleArray = constantInitialSaturation(0.0)
) {
    /** Time for each computed step */
    private val stepTime = mutableListOf(0.0)

    /** Consecutive tolerated pressure for each step */
    private var toleratedPressure = mutableListOf<Double>().apply {
        add(0.0)

        // TODO  computes tolerated pressure from initial saturations
        /*
        val firstTankToReach = profile.depth.firstOrNull()?.tankToReach
        val firstTank = firstTankToReach ?: tankPlanner.mainTank
        add(npNorm(toleratedPressureForAllCompartments(startDepth, firstTank), normNPValue))
        */

    }

    /** Nitrogen compartment pressure  */
    val nitrogenSaturation = initialNitrogenSaturation

    /** Helium compartment pressure */
    val heliumSaturation = initialHeliumSaturation

    private val otu = mutableListOf(0.0)

    val lastTime get() = stepTime.lastOrNull() ?: 0.0

    val lastToleratedPressure get() = toleratedPressure.lastOrNull() ?: 0.0

    /**
     * Computes the saturation for each compartment with current saturations as initial
     * saturation from [sourcePressure] to [targetPressure] for [duration] minutes using the
     * [tank] ratios with the Schreiner equation.
     *
     * It updates the [nitrogenSaturation] and [heliumSaturation].
     */
    fun schreinerSaturation(sourcePressure: Double, targetPressure: Double, duration: Double, tank: Tank) {
        val deltaP = (targetPressure - sourcePressure) / duration // dive speed in meter/min
        val tankTypeDeltaP = if (tank.type == TankType.CCR) ccrSetOxygenPartialPressure(lastTime) else 0.0
        val p = sourcePressure - alveolarPressure - tankTypeDeltaP

        // computes saturation for nitrogen
        for (i in 0 until nitrogenSaturation.size) {
            nitrogenSaturation[i] =
                schreiner(
                    nitrogenSaturation[i],
                    nitrogenCompartments[i].tau,
                    tank.correctedNitrogenRatio,
                    p,
                    deltaP,
                    duration
                )
        }

        // computes saturation for helium
        for (i in 0 until heliumSaturation.size) {
            heliumSaturation[i] = schreiner(
                heliumSaturation[i],
                heliumCompartments[i].tau,
                tank.correctedHeliumRatio,
                p,
                deltaP,
                duration
            )
        }
    }

    /** Computes schreiner value for one compartment */
    private fun schreiner(a0: Double, tau: Double, r: Double, p: Double, deltaP: Double, duration: Double): Double {
        val k = ln(2.0) / tau
        val e = exp(-k * duration)
        return a0 * e + r * ((p - deltaP / k) * (1 - e) + deltaP * duration)
    }

    /**
     * Computes the minimum acceptable depth for all compartments.
     * The returned depth for each compartment is a double for precision.
     *
     * This method doesn't have any side effect.
     */
    fun toleratedPressureForAllCompartments(gradient: Double, tank: Tank): DoubleArray {
        val nitrogen = tank.mix.nitrogen.ratio
        val helium = tank.mix.helium.ratio
        val consideredNitrogenRatio = if (nitrogen <= 0.0 && helium <= 0.0) 0.001 else nitrogen
        val bothRatio = helium + consideredNitrogenRatio

        return DoubleArray(compartmentCount) { i ->
            val a = (helium * heliumCompartmentA[i] + consideredNitrogenRatio * nitrogenCompartmentA[i]) / bothRatio
            val b = (helium * heliumCompartmentB[i] + consideredNitrogenRatio * nitrogenCompartmentB[i]) / bothRatio
            (nitrogenSaturation[i] + heliumSaturation[i] - gradient * a) / (gradient / b - gradient + 1)
        }
    }

    fun minimumPressure() = toleratedPressure.last()

    /**
     * Computes the time in minutes where no decompression is needed at the
     * given [pressure] with current [saturation] for this [compartment] to
     * be able to reach [targetedPressure] with no stops.
     */
    private fun noDecompressionTime(
        pressure: Double,
        targetedPressure: Double,
        ratio: Double,
        compartment: Compartment,
        saturation: Double
    ): Double {
        // TODO integrate gradient factor in formula
        val p = pressure - alveolarPressure
        val rp = ratio * p
        // TODO The ccr formula isn't the same  rp -> p - ppO2
        val up = rp - compartment.a - targetedPressure / compartment.b
        val down = rp - saturation
        return -compartment.tau * ln(up / down) / ln(2.0)
    }

    fun minimalNoDecompressionTime(pressure: Double, targetedPressure: Double, tank: Tank): Double {
        // filtering nan is important, some compartment needs decompression
        val nitrogenDecoTimes = nitrogenCompartments.map {
            noDecompressionTime(pressure, targetedPressure, tank.mix.nitrogen.ratio, it, nitrogenSaturation[it.index])
        }
        val heliumDecoTimes = heliumCompartments.map {
            noDecompressionTime(pressure, targetedPressure, tank.mix.helium.ratio, it, heliumSaturation[it.index])
        }
        return (nitrogenDecoTimes + heliumDecoTimes).asSequence().filter { !it.isNaN() && it >= 0 }.minOrNull() ?: 0.0
    }

    /**
     * Execute one saturation step from [sourcePressure] to [targetPressure] for [duration] with [tank].
     */
    fun stepSaturation(
        sourcePressure: Double, targetPressure: Double, duration: Double,
        gradient: Double, tank: Tank
    ) {
        val currentTime = stepTime.last()

        schreinerSaturation(sourcePressure, targetPressure, duration, tank)

        val toleratedPressureForAllCompartments = toleratedPressureForAllCompartments(gradient, tank)
        toleratedPressure.add(npNorm(toleratedPressureForAllCompartments, normNPValue))
        val time = currentTime + duration
        stepTime.add(time)

        // computes average ppo2 from source to target for OTU
        val oxygenPartialPressure = when (tank.type) {
            TankType.CCR -> ccrSetOxygenPartialPressure(currentTime) + ccrSetOxygenPartialPressure(time)
            else -> tank.oxygenPartialPressure(sourcePressure) + tank.oxygenPartialPressure(targetPressure)
        } / 2.0
        otu.add(otu.last() + duration * otu(oxygenPartialPressure))
    }

    /** Returns OTU for given time */
    fun otuAtTime(time: Double): Double {
        val index = stepTime.indexOfFirst { time <= it }
        return otu[if (index < 0) otu.lastIndex else index]
    }

    fun minDepthPlot(profile: DiveProfile): List<Plot> =
        (0 until toleratedPressure.size).map {
            Plot(
                stepTime[it],
                ceil(profile.toDepth(toleratedPressure[it])).coerceAtLeast(0.0)
            )
        }

    fun otuPlot(): List<Plot> = stepTime.mapIndexed { i, t -> Plot(t, otu[i]) }

}
