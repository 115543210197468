package com.tekdiving.deco

import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSpanElement
import kotlin.properties.Delegates

class DiveTypeController(
    initialDiveType: DiveType<*>
) : Controller<DiveType<*>> {

    var onUpdate: ((old: DiveType<*>, new: DiveType<*>, DiveTypeController) -> Unit) =
        { _, _, _ -> }

    override var data: DiveType<*> by Delegates.observable(initialDiveType) { _, _, _ -> refresh() }

    override val container: HTMLElement = document.create.div {
        div("field") {
            label("label") { +"Tank type" }
            div("buttons has-addons") {
                span("button is-rounded is-selected is-small td-dive-oc") {
                    i("td-tank-oc")
                    onClickFunction = { update(DiveType.DefaultOCDive) }
                }
                span("button is-rounded is-selected is-small td-dive-scr") {
                    i("td-tank-scr")
                    onClickFunction = { update(DiveType.defaultSCRDive) }
                }
                span("button is-rounded is-selected is-small td-dive-ccr") {
                    i("td-tank-ccr")
                    onClickFunction = { update(DiveType.defaultCCRDive) }
                }
                span("button is-rounded is-selected is-small td-dive-bailout") {
                    +"Bailout"
                    onClickFunction = { update(DiveType.defaultBailoutDive) }
                }
            }
        }
        div("td-dive-options")
        p("help") { +"Dive type" }
    }

    val oc = container.querySelector("span.td-dive-oc") as HTMLSpanElement
    val scr = container.querySelector("span.td-dive-scr") as HTMLSpanElement
    val ccr = container.querySelector("span.td-dive-ccr") as HTMLSpanElement
    val bailout = container.querySelector("span.td-dive-bailout") as HTMLSpanElement

    val options = container.querySelector("div.td-dive-options") as HTMLDivElement

    val scrOptions = document.create.div("level is-mobile") {
        span("level-item help") { +"Flow rate " }
        p("level-item control") {
            input(InputType.text, classes = "input is-small is-rounded td-scr-fr") {
                placeholder = "Flow rate"
                size = "4"
                value = DiveType.defaultSCRDive.options.flowRate.toString()
                onChangeFunction = {
                    val value = scrFlowRate.value.toDoubleOrNull()
                    update(data.updateOptions(data.options.let {
                        if (value != null && it is DiveOptions.SCROptions) it.copy(flowRate =  value) else it
                    }))
                }
            }
        }
        span("level-item help") { +" VO2 " }
        p("level-item control") {
            input(InputType.text, classes = "input is-small is-rounded td-scr-vo2") {
                placeholder = "VO2"
                size = "4"
                value = DiveType.defaultSCRDive.options.oxygenConsumption.toString()
                onChangeFunction = {
                    val value = scrVO2.value.toDoubleOrNull()
                    update(data.updateOptions(data.options.let {
                        if (value != null && it is DiveOptions.SCROptions) it.copy(oxygenConsumption = value) else it
                    }))
                }
            }
        }
    }

    val scrFlowRate: HTMLInputElement = scrOptions.querySelector("input.td-scr-fr") as HTMLInputElement
    val scrVO2: HTMLInputElement = scrOptions.querySelector("input.td-scr-vo2") as HTMLInputElement

    val ccrOptions = document.create.div("level is-mobile") {
        span("level-item help") { +"PPO2" }
        p("level-item control") {
            input(InputType.text, classes = "input is-small is-rounded td-ccr-ppo2") {
                placeholder = "Ppo2"
                size = "4"
                value = DiveType.defaultCCRDive.options.setOxygenPartialPressure.toString()
                onChangeFunction = {
                    val value = ccrPPO2.value.toDoubleOrNull()
                    update(data.updateOptions(data.options.let {
                        if (value != null && it is DiveOptions.CCROptions)
                            it.copy(setOxygenPartialPressure = value) else it
                    }))
                }
            }
        }
    }

    val ccrPPO2: HTMLInputElement = ccrOptions.querySelector("input.td-ccr-ppo2") as HTMLInputElement

    init {
        refresh()
    }

    fun update(new: DiveType<*>) {
        val old = data
        if (new != old) {
            data = new
            onUpdate(old, data, this)
        }
    }

    override fun refresh() {
        oc.classList.remove(isInfo, isSelected)
        scr.classList.remove(isInfo, isSelected)
        ccr.classList.remove(isInfo, isSelected)
        bailout.classList.remove(isInfo, isSelected)
        when (data) {
            DiveType.DefaultOCDive -> oc.classList.add(isInfo, isSelected)
            is DiveType.SCRDive -> scr.classList.add(isInfo, isSelected)
            is DiveType.CCRDive -> ccr.classList.add(isInfo, isSelected)
            is DiveType.BailoutDive -> bailout.classList.add(isInfo, isSelected)
        }

        options.innerHTML = ""
        val diveOptions = data.options.let {
            when (it) {
                is DiveOptions.SCROptions -> {
                    scrFlowRate.value = it.flowRate.toString()
                    scrVO2.value = it.oxygenConsumption.toString()
                    scrOptions
                }
                is DiveOptions.CCROptions -> {
                    ccrPPO2.value = it.setOxygenPartialPressure.toString()
                    ccrOptions
                }
                else -> null
            }
        }
        if (diveOptions != null) {
            options.appendChild(diveOptions)
        }

    }
}

