package com.tekdiving.deco

import bulma.*
import com.tekdiving.plotter.Line
import com.tekdiving.plotter.LineChartController
import com.tekdiving.plotter.LinePoint
import io.data2viz.color.Colors
import kotlin.math.roundToInt
import kotlin.properties.Delegates.observable

class BubbleProbabilityController(
    bubbleProbability: BubbleProbability
) : NoContextController<BubbleProbability, BulmaElement>() {

    val steps = listOf(0.75, 0.5, 0.25, 0.1, 0.01)

    override var data by observable(bubbleProbability)
    { _, old, new -> if (old != new) refresh() }

    override var readOnly: Boolean = true

    val chart: LineChartController = LineChartController(chartLines())

    val table = Table(
        head = listOf(TableHeaderRow(TableHeaderCell("Bubbles"), TableHeaderCell("Time"))),
        body = tableLines(),
        fullWidth = true
    )

    override val container =
        TileAncestor(
            TileParent(
                TileChild(chart),
                TileChild(table),
                vertical = true
            )
        )

    fun tableLines() = steps.map {
        val percentage = "${(it * 100.0).roundToInt()}%"
        val time = formatDayHourMinute(data.timeForProbability(it))
        TableRow(TableCell(percentage), TableCell(time))
    }

    private fun linePoints(): List<LinePoint> =
        (0 .. data.timeForProbability(0.01)).step(20)
            .map { LinePoint(it, data.probabilityForTime(it)) }

    private fun chartLines(): List<Line> = listOf(
        Line(
            label = "Bubble probability",
            points = linePoints(),
            strokeColor = Colors.rgb(252, 226, 5),
            pointRadius = 0.0,
        )
    )

    override fun refresh() {
        table.body = tableLines()
        chart.data = chartLines()
    }
}
