package com.tekdiving.client

import bulma.Column
import bulma.Columns
import bulma.Section
import bulma.Title
import com.tekdiving.deco.*
import com.tekdiving.plotter.Line
import com.tekdiving.plotter.LineChartController
import com.tekdiving.plotter.LinePoint
import com.tekdiving.plotter.QuadraticLine
import io.data2viz.color.Colors
import io.data2viz.math.pct
import kotlinx.html.InputType
import org.w3c.dom.HTMLElement
import kotlin.math.min
import kotlin.math.sqrt

fun reference(root: HTMLElement) {
    root.appendChild(
        Section(
            Title("Tools"),
            tools(),

            Title("Exposing factor"),
            exposingFactorChart(),

            Title("Gas maximum depth"),
            gasMixCharts()
        ).root
    )
}

fun tools(): Columns {
    var depth: Int = 40
    var time: Int = 30
    var ppo2: Double = partialPressureOxygenHypoxia
    val ttsProgram = ResultElement("TTS", "", "Program")
    val mix = ResultElement("Mix", "")
    val pressure = ResultElement("Pressure", "", "in bars")
    val o2 = ResultElement("O2 Ratio", "", "percentage")

    fun updateTools() {
        val pressureValue = toPressure(depth.toDouble())
        val plot = squarePlot(depth, time)
        val tts = ttsForProgram(plot)
        val program = program(plot)

        ttsProgram.text = "$tts"
        ttsProgram.helpText = "$program"
        mix.text = advisedGasMixForProgram(program, depth.toDouble()).name
        pressure.text = pressureValue.format(5)
        o2.text = (ppo2 / pressureValue * 100.0).format(2)
    }

    return Columns(
        Column(
            field(InputType.number, "Depth", "depth", depth.toString(), "in meters") { value ->
                value.toIntOrNull()?.let { depth = it }
                updateTools()
            },
            mobileSize = bulma.ColumnSize.Half, tabletSize = bulma.ColumnSize.S1
        ),
        Column(
            field(InputType.number, "Time", "time", time.toString(), "in minutes") { value ->
                value.toIntOrNull()?.let { time = it }
                updateTools()
            },
            mobileSize = bulma.ColumnSize.S6, desktopSize = bulma.ColumnSize.S1
        ),
        Column(
            field(InputType.text, "PPo2", "ppo2", ppo2.toString(), "in bars") { value ->
                value.toDoubleOrNull()?.let { ppo2 = it }
                updateTools()
            },
            mobileSize = bulma.ColumnSize.S6, desktopSize = bulma.ColumnSize.S1
        ),
        Column(ttsProgram, mobileSize = bulma.ColumnSize.S6, desktopSize = bulma.ColumnSize.S1),
        Column(mix, mobileSize = bulma.ColumnSize.S6, desktopSize = bulma.ColumnSize.S1),
        Column(pressure, mobileSize = bulma.ColumnSize.S6, desktopSize = bulma.ColumnSize.S1),
        Column(o2, mobileSize = bulma.ColumnSize.S6, desktopSize = bulma.ColumnSize.S1),
    )
}

fun exposingFactorChart(): LineChartController {
    val ticks = 21

    val sportColor = Colors.rgb(144, 238, 144, 80.pct)
    val tekColor = Colors.rgb(103, 182, 249, 80.pct)
    val expeditionColor = Colors.rgb(57, 86, 130, 80.pct)

    fun valueForExposingFactor(exposingFactor: Int, maxDepth: Double): List<LinePoint> =
        List(ticks) { LinePoint(it * 10, -min(maxDepth, exposingFactor / sqrt(it * 10.0))) }

    val lines = listOf(
        Line(
            label = "Sport <= $sportExpositionLimit", strokeColor = sportColor,
            strokeWidth = 2.0, lineType = QuadraticLine(90.pct, 90.pct),
            points = valueForExposingFactor(sportExpositionLimit, sportDepthLimit)
        ),
        Line(
            label = "Tek <= $tekExpositionLimit", strokeColor = tekColor,
            strokeWidth = 2.0, lineType = QuadraticLine(50.pct, 50.pct),
            points = valueForExposingFactor(tekExpositionLimit, tekDepthLimit)
        ),
        Line(
            label = "Expedition <= $expeditionExpositionLimit", strokeColor = expeditionColor,
            strokeWidth = 2.0, lineType = QuadraticLine(30.pct, 30.pct),
            points = valueForExposingFactor(expeditionExpositionLimit, expeditionDepthLimit),
        ),
    )

    return LineChartController(lines, stepName = "Time")
}

fun gasMixCharts(): LineChartController {
    val ticks = 100
    val step = 100 / (ticks - 1)
    val maxDepth = 150.0

    val profile = createDiveProfile()

    val openColor = Colors.rgb(232, 249, 254)
    val decoColor = Colors.rgb(103, 182 , 249)
    val bailoutColor = Colors.rgb(57, 86, 130)
    val trimixN2Color = Colors.rgb(144, 238, 144)
    val trimixHeColor = Colors.rgb(90, 210, 90)

    fun valueForMax(maxPpo2: Double): List<LinePoint> = List(ticks) {
        LinePoint((it + 1) * step, min(maxDepth, profile.toDepth(maxPpo2 / (((it + 1) * step) / 100.0))))
    }

    val lines = listOf(
        Line(
            label = "O2 OC (ppo2 = $partialPressureOxygenMaxOpen)", strokeColor = openColor, strokeWidth = 2.0,
            points = valueForMax(partialPressureOxygenMaxOpen)
        ),
        Line(
            label = "O2 Deco (ppo2 = $partialPressureOxygenMaxDeco)", strokeColor = decoColor, strokeWidth = 2.0,
            points = valueForMax(partialPressureOxygenMaxDeco)
        ),
        Line(
            label = "O2 Bailout (ppo2 = $partialPressureOxygenMaxBailout)", strokeColor = bailoutColor, strokeWidth = 2.0,
            points = valueForMax(partialPressureOxygenMaxBailout)
        ),
        Line(
            label = "N2 Trimix N2 (ppn2 = ${partialPressureNitrogenMaxTrimixNitrogen.format(2)})", strokeColor = trimixN2Color, strokeWidth = 2.0,
            points = valueForMax(partialPressureNitrogenMaxTrimixNitrogen)
        ),
        Line(
            label = "N2 Trimix He (ppn2 = ${partialPressureNitrogenMaxTrimixHelium.format(2)})", strokeColor = trimixHeColor, strokeWidth = 2.0,
            points = valueForMax(partialPressureNitrogenMaxTrimixHelium)
        ),
    )

    return LineChartController(lines, stepName = "Time")
}

