package com.tekdiving.deco

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.math.*

/** Atmospheric pressure at sea level in bars */
const val seaLevelAtmosphericPressure = 1.01325

/** Schreiner alveolar pressure */
@Suppress("unused")
const val schreinerAlveolarPressure = 0.0495

/** Buhlmann alveolar pressure */
const val buhlmannAlveolarPressure = 0.0627

/** US Navy alveolar pressure */
@Suppress("unused")
const val usAlveolarPressure = 0.0567

/** Maximum time set to avoid infinite loop */
const val maxStopTime = 1000

const val maxNpNorm = 50.0

const val maximumOtu = 600.0

/** NP Norm */
fun npNorm(vector: DoubleArray, power: Double) =
    if (power >= maxNpNorm) vector.maxOrNull() ?: 0.0
    else vector.fold(0.0) { p, c -> p + c.pow(power) }.pow(1.0 / power)

/** Point in the graph plot */
data class Plot(
    val time: Double,
    val depth: Double
)

data class DecompressionStop(
    /** Time when the stop starts during the dive in minutes */
    val rt: Double,
    /** Depth at witch the decompression stop is needed in meters */
    val depth: Int,
    /** Time to stop for the decompression in minutes */
    val time: Int
) {
    fun tank(planner: TankPlanner) = planner.tankAtTime(rt + time / 2.0)
}

enum class InfoType {
    Info, Warning, Critical
}

data class Info(
    val type: InfoType,
    val where: Plot,
    val message: Message,
    val args: List<Any>
) {
    fun format(messages: Messages) = messages.format(message, args)
}

@Serializable
data class GradientFactors(
    val low: Int, val high: Int
) {
    @Transient
    val lowRatio = low / 100.0

    @Transient
    val highRatio = high / 100.0

    override fun toString(): String {
        return "($low, $high)"
    }
}

/** Compute advised gradient factor from [mix] and [program] */
fun advisedGradientFactors(mix: GasMix, program: Program) = when (mix) {
    GasMix.TrimixHelium -> when (program) {
        Program.Saturation -> GradientFactors(10, 60)
        Program.Expedition -> GradientFactors(10, 60)
        else -> GradientFactors(20, 70)
    }
    GasMix.TrimixNitrogen -> GradientFactors(50, 80)
    GasMix.Nitrox -> GradientFactors(80, 80)
}

/** Computes gradient from gradient factors, currentDepth and maximumDepth */
fun gradient(gradientFactors: GradientFactors, currentDepth: Double, maximumDepth: Double): Double {
    val deltaG = (gradientFactors.lowRatio - gradientFactors.highRatio) / maximumDepth
    return gradientFactors.highRatio + deltaG * currentDepth
}

class Decompression(
    /** Dive profile */
    val profile: DiveProfile,

    /** Tank planner options */
    val tankPlannerOptions: TankPlannerOptions = TankPlannerOptions(),

    /** Forced gradient factors to use, if null the decompression uses the [advisedGradientFactors] ones */
    val forcedGradientFactors: GradientFactors? = null,

    /** Start depth for decompression */
    val startDepth: Double = 0.0,

    /** End depth for decompression */
    val endDepth: Double = 0.0,

    /** Decompression stop delta in meters */
    val decompressionStopDelta: Int = 3,

    /** Saturation used for decompression */
    val saturation: Saturation = Saturation({ oxygenPartialPressureSet(profile.type) }),

    /** Minimum log information type */
    val infoLevel: InfoType = InfoType.Warning
) {
    /**
     * Advised gradient factor depending on profile and/or forced main tank
     */
    val advisedGradientFactors =
        advisedGradientFactors(tankPlannerOptions.forcedMainMix?.type ?: profile.advisedGasMix, profile.program)

    val gradientFactors = forcedGradientFactors ?: advisedGradientFactors

    /** Tank planner used for tank selection */
    val tankPlanner = TankPlanner(profile, tankPlannerOptions)

    /** Checks forced high gradient factor status (Info if delta <= 5, Warning if delta <= 20 otherwise Critical) */
    val forcedGradientFactorsHighStatus
        get() = forcedGradientFactors?.let {
            val delta = (it.high - advisedGradientFactors.high).absoluteValue
            when {
                delta <= 5 -> InfoType.Info
                delta <= 20 -> InfoType.Warning
                else -> InfoType.Critical
            }
        } ?: InfoType.Info

    /** Computed decompression stops */
    private var decompressionStops = mutableListOf<DecompressionStop>()

    /** Reported information, including problems */
    private var reportedInfo = mutableListOf<Info>()

    /** Plots for decompression */
    private var decompressionStopPlots = mutableListOf<Plot>()

    /** Args is a function to only computes args when needed */
    fun report(type: InfoType, time: Double, depth: Double, message: Message, args: () -> List<Any> = { emptyList() }) {
        if (type >= infoLevel) {
            reportedInfo.add(Info(type, Plot(time, depth), message, args()))
        }
    }

    /** All information for the computation */
    val info get() = reportedInfo.toList()

    /** Critical problems */
    val problems get() = reportedInfo.asSequence().filter { it.type == InfoType.Critical }

    /** Warnings */
    val warnings get() = reportedInfo.asSequence().filter { it.type == InfoType.Warning }

    /** Is main tank suitable for maximal depth ? */
    val isMainTankNotSuitable get() = problems.find { it.message == Message.MainTankNotSuitable } != null

    val firstStopAscendingSpeed
        get() = when (tankPlanner.mainTank.mix.type) {
            GasMix.TrimixHelium -> trimixHeliumFirstStopAscendingSpeed
            else -> standardFirstStopAscendingSpeed
        }

    val stopAscendingSpeed get() = standardStopAscendingSpeed

    val stops get() = decompressionStops.toList()

    /** Total dive time */
    val totalTime: Int
        get() = ceil(saturation.lastTime).toInt()

    /** Time to surface in minutes. If 0 the decompression algorithm haven't been computed */
    val timeToSurface: Int
        get() = ceil((saturation.lastTime - profile.finalTime).coerceAtLeast(0.0)).roundToInt()

    /** Time with no decompression needed in minutes at end depth*/
    val noDecompressionTime: Int
        get() = saturation.minimalNoDecompressionTime(
            profile.maximumPressure,
            profile.toPressure(endDepth),
            tankPlanner.mainTank
        ).roundToInt()

    val valid: Boolean
        get() = problems.count() == 0

    /**
     * Execute one saturation step from [sourceDepth] to [targetDepth] for [duration].
     * If no [forcedTank] is given, it uses [tankPlanner#tankForDepthOrMain].
     */
    private fun stepSaturation(
        sourceDepth: Double, targetDepth: Double, duration: Double,
        mainUse: TankUse, forcedTank: Tank? = null
    ) {
        val currentTime = saturation.lastTime
        val tank = forcedTank ?: tankPlanner.tankForDepthOrMain(max(sourceDepth, targetDepth), mainUse)

        /** TODO Find a way to correctly report hypoxia
        val hypoxiaDepth = profile.tankHypoxiaDepth(tank)
        if (hypoxiaDepth > sourceDepth || hypoxiaDepth > targetDepth) {
        // target depth is over minimal, report the problem
        // TODO Localise message
        report(InfoType.Critical, currentTime, targetDepth, tank, {"Hypoxia"})
        }
         */

        val sourcePressure = profile.toPressure(sourceDepth)
        val targetPressure = profile.toPressure(targetDepth)
        val gradient = gradient(gradientFactors, targetDepth, profile.maximumDepth)
        saturation.stepSaturation(sourcePressure, targetPressure, duration, gradient, tank)

        report(InfoType.Info, currentTime, targetDepth, Message.NitrogenSaturation) {
            listOf(saturation.nitrogenSaturation.joinToString(", "))
        }
        report(InfoType.Info, currentTime, targetDepth, Message.HeliumSaturation) {
            listOf(saturation.heliumSaturation.joinToString(", "))
        }

        val minimalDepth = ceiledMinimumDepth()
        if (targetDepth < minimalDepth) {
            // target depth is over minimal, report the problem
            val time = currentTime + duration
            report(InfoType.Info, time, targetDepth, Message.MinimalDepthExceeded) { listOf(minimalDepth, time) }
        }

        tankPlanner.updateConsumption(tank, sourceDepth, targetDepth, duration)
    }

    /**
     * Compute the decompression stops for the complete [profile].
     */
    fun compute() {
        // checks if force tank is suitable for maximal depth
        if (tankPlannerOptions.forcedMainMix != null &&
            !profile.isTankSuitableForDepth(tankPlanner.mainTank, profile.maximumDepth)
        ) {
            val depth = profile.maximumDepth
            report(InfoType.Critical, 0.0, depth, Message.MainTankNotSuitable) { listOf(depth) }
        }

        // reports high gradient factor changes
        when (forcedGradientFactorsHighStatus) {
            InfoType.Critical ->
                report(InfoType.Critical, profile.finalTime, startDepth, Message.InvalidGradientFactorHigh) {
                    listOf(gradientFactors.high, advisedGradientFactors.high)
                }
            InfoType.Warning ->
                report(InfoType.Warning, profile.finalTime, startDepth, Message.NotAdvisedGradientFactorHigh) {
                    listOf(gradientFactors.high, advisedGradientFactors.high)
                }
            else -> { /* nothing to do */
            }
        }

        report(InfoType.Info, 0.0, startDepth, Message.ComputationStarted) { listOf(profile) }

        report(InfoType.Info, 0.0, startDepth, Message.NitrogenSaturation) {
            listOf(saturation.nitrogenSaturation.joinToString(", "))
        }
        report(InfoType.Info, 0.0, startDepth, Message.HeliumSaturation) {
            listOf(saturation.heliumSaturation.joinToString(", "))
        }

        if (!profile.valid) {
            report(InfoType.Critical, 0.0, startDepth, Message.InvalidProfile) {
                listOf(profile.type, profile.maximumDepth, profile.bottomTime, profile.consumption)
            }
        }

        // computes tolerated pressure from initial saturations
        val firstTankToReach = profile.depth.firstOrNull()?.tankToReach
        // computes saturation for each plot as rectangular steps
        profile.depth.fold(DivePlot(0.0, startDepth, firstTankToReach)) { current: DivePlot, next: DivePlot ->
            val duration = next.time - current.time
            if (duration > 0) {
                stepSaturation(current.depth, next.depth, duration, TankUse.Force, next.tankToReach)
            }
            next
        }

        // Should by using main tank ?
        // - Force if CCR since it must be used all the way
        // - Avoid for Bailout since bailout should start at maximum depth
        // - IfAvailable for OC or SCR until the ppo2 reaches the minimum for the tank
        val mainUse = when (profile.type) {
            is DiveType.CCRDive -> TankUse.Force
            is DiveType.BailoutDive -> TankUse.Avoid
            is DiveType.DefaultOCDive -> TankUse.IfAvailable
            is DiveType.SCRDive -> TankUse.IfAvailable
        }

        if (saturation.lastToleratedPressure > profile.atmosphericPressure) {
            val stopAscendingSpeed = standardStopAscendingSpeed

            // stops are needed, compute first stop depth and increase until sea level
            var stopDepth =
                (decompressionStopDelta * (ceiledMinimumDepth() / decompressionStopDelta + 1)).toDouble()

            var currentDepth = profile.finalDepth
            var currentTime = profile.finalTime
            while (stopDepth > endDepth) {

                // computes the saturation while the diver ascends to stop depth
                val speed = if (decompressionStops.isEmpty()) firstStopAscendingSpeed else stopAscendingSpeed
                val ascendingTime = timeForDistance(currentDepth - stopDepth, speed)
                // checks that time is not zero to avoid Nan in saturation
                if (ascendingTime > 0) {
                    stepSaturation(currentDepth, stopDepth, ascendingTime, mainUse)
                }

                // test for each minute at stop time if the next stop can be reached
                var stopTime = 0
                val nextStopDepth = stopDepth - decompressionStopDelta
                val nextStopPressure = profile.toPressure(nextStopDepth)
                while (saturation.lastToleratedPressure >= nextStopPressure) {
                    stepSaturation(stopDepth, stopDepth, 1.0, mainUse)
                    stopTime += 1

                    if (stopTime > maxStopTime) {
                        // stop time is far too big, stop here with an error
                        report(InfoType.Critical, currentTime, stopDepth, Message.StopTooLong) {
                            listOf(stopDepth, stopTime)
                        }
                        return
                    }
                }

                if (stopTime > 0) {
                    // plots the stop
                    val startTime = currentTime + ascendingTime
                    decompressionStopPlots.add(Plot(startTime, stopDepth))
                    decompressionStopPlots.add(Plot(startTime + stopTime, stopDepth))

                    // registers stop
                    decompressionStops.add(DecompressionStop(startTime, stopDepth.toInt(), stopTime))
                }

                currentDepth = stopDepth
                stopDepth = nextStopDepth
                currentTime += ascendingTime + stopTime
            }

            val ascendingTime = timeForDistance(currentDepth, stopAscendingSpeed)
            decompressionStopPlots.add(Plot(currentTime + ascendingTime, endDepth))
            stepSaturation(currentDepth, endDepth, ascendingTime, mainUse)

        } else {
            // no decompression stops are needed, yeah !!
            val ascendingTime = timeForDistance(profile.finalDepth, firstStopAscendingSpeed)
            decompressionStopPlots.add(Plot(profile.finalTime + ascendingTime, endDepth))
            stepSaturation(profile.finalDepth, endDepth, ascendingTime, TankUse.IfAvailable)
        }

        val lastTime = saturation.lastTime
        report(InfoType.Info, lastTime, endDepth, Message.ComputationEnded) { listOf(timeToSurface, stops.size) }

        // checks OTU
        if (finalOtu() > maximumOtu) {
            report(InfoType.Critical, lastTime, endDepth, Message.ExceededMaximumOTU) { listOf(finalOtu(), maximumOtu) }
        }

        // checks if force tank is suitable for maximal depth
        if (!tankPlanner.areTankSpecsSufficient) {
            report(InfoType.Critical, lastTime, endDepth, Message.TankSpecsInsufficient)
        }

        // reports tank problems
        val checkTank: (Tank) -> Unit = {
            if (!it.valid) {
                report(InfoType.Critical, lastTime, endDepth, Message.InvalidTank) { listOf(it.description) }
            }
            if (tankPlanner.tankOrder(it) < 0) {
                report(InfoType.Critical, lastTime, endDepth, Message.TankNotUsed) { listOf(it.description) }
            }

            when (tankPlanner.tankState(it)) {
                TankState.MoreThanFullyBurned -> report(
                    InfoType.Critical, lastTime, endDepth, Message.TankFullyBurned
                ) {
                    listOf(it.description)
                }
                TankState.MoreThanTwoThirdBurned -> report(
                    InfoType.Warning, lastTime, endDepth, Message.TankTwoThirdBurned
                ) {
                    listOf(it.description)
                }
                else -> { /* nothing */
                }
            }
        }
        checkTank(tankPlanner.mainTank)
        tankPlanner.tanks.forEach(checkTank)
    }

    /** List of plots for the dive profile including the stops */
    fun profilePlot(): List<Plot> {
        val result = arrayListOf(Plot(0.0, startDepth))
        result.addAll(profile.depth.map { Plot(it.time, it.depth) })
        result.addAll(decompressionStopPlots)
        return result
    }

    fun minDepthPlot() = saturation.minDepthPlot(profile)

    fun otuPlot() = saturation.otuPlot()

    fun otuAtTime(time: Double) = saturation.otuAtTime(time)

    fun finalOtu() = saturation.otuAtTime(saturation.lastTime)

    fun ceiledMinimumDepth() = ceil(profile.toDepth(saturation.minimumPressure())).toInt()

    fun bubbleProbability() = BubbleProbability(timeToSurface.toDouble(), tankPlanner.mainTank)

    override fun toString(): String = "Decompression for $profile: time to surface = $timeToSurface"
}

@Suppress("unused")
        /** Simpler construction method, used in iOS version of BullMix*/
fun standardDecompression(
    profile: DiveProfile,
    tankPlannerOptions: TankPlannerOptions = TankPlannerOptions(),
    forcedGradientFactors: GradientFactors? = null
): Decompression =
    Decompression(profile, tankPlannerOptions, forcedGradientFactors)
