package com.tekdiving.deco

import bulma.Column
import bulma.Columns
import bulma.Div
import bulma.Title
import com.tekdiving.ideal.DiveConditions
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import org.w3c.dom.*
import kotlin.math.roundToInt
import kotlin.properties.Delegates.observable
import bulma.ColumnSize as BColumnSize

class SquareProfileController(
    initialProfile: DiveProfile = squareProfile(DiveType.DefaultOCDive, 40, 30, 12),
    val initialOptions: TankPlannerOptions = TankPlannerOptions(),
    initialCondition: DiveConditions = DiveConditions()
) {
    var profile: DiveProfile by observable(initialProfile) { _, old, new ->
        if (old != new) {
            diveTypeController.data = new.type
            onUpdateProfile(old, new, this)
            resetOptions()
            updateDecompression()
        }
    }

    var tankPlannerOptions: TankPlannerOptions by observable(initialOptions) { _, old, new ->
        if (old != new) {
            tankSpecsController.data = new.tankSpecs
            onUpdateOptions(old, new, this)
            updateDecompression()
        }
    }

    var conditions
        get() = conditionController.data
        set(value) {
            conditionController.data = value
        }

    var messages by observable(Messages(Locale.En)) { _, _, _ -> updateDecompression() }

    private var decompression: Decompression? = null

    var onUpdateProfile: ((old: DiveProfile, new: DiveProfile, SquareProfileController) -> Unit) =
        { _, _, _ -> }

    var onUpdateOptions: ((old: TankPlannerOptions, new: TankPlannerOptions, SquareProfileController) -> Unit) =
        { _, _, _ -> }

    var onUpdateCondition
        get() = conditionController.onUpdate
        set(value) { conditionController.onUpdate = value }

    val diveTypeController = DiveTypeController(profile.type).apply {
        onUpdate = { _, new, _ -> updateProfile(new) }
    }

    val userRiskFactorController = RiskFactorController().apply { label = "User Risk" }
    val diveRiskFactorController = RiskFactorController().apply { label = "Dive Risk" }
    val conditionController = DiveConditionsController().apply { data = initialCondition }

    var userRisk: RiskFactor
        get() = userRiskFactorController.data
        set(value) {
            userRiskFactorController.data = value
            updateDecompression()
        }

    var diveRisk: RiskFactor
        get() = diveRiskFactorController.data
        set(value) {
            diveRiskFactorController.data = value
            updateDecompression()
        }

    val tankSpecsController = TankSpecsController(tankPlannerOptions.tankSpecs).apply {
        onUpdate = { _, new, _ ->
            tankPlannerOptions = tankPlannerOptions.copy(tankSpecs = new)
        }
    }

    val bubbleProbabilityController = BubbleProbabilityController(
        decompression?.bubbleProbability() ?: BubbleProbability(0.0, createTank())
    )

    val diveChartController = DiveChartController(profile.depth, decompression)

    val diveSection = Div(
        Title("Dive"),
        Columns(
            Column(diveChartController, size = BColumnSize.S8),
            Column(bubbleProbabilityController, size = BColumnSize.S4)
        )
    )

    val tanksController =
        ListController<Tank>(emptyList(), "is-mobile is-centered", size(3, mobile = 10)) { index, data ->
            val tankController = TankController(data, decompression)
            tankController.messages = messages
            tankController.onUpdateMix = if (data.type.main) updateMainTankMix else updateTankMix
            tankController.onUpdateSpec = { _, new, _ -> updateTankSpec(index, new) }
            tankController.onDelete = deleteTank
            tankController
        }

    val container: HTMLElement = document.create.div {
        div("columns is-mobile is-multiline is-centered") {
            div("${columnClass(size(3, 3, 8))} td-dive-type")
            div(columnClass(size(3, 3, 4))) {
                div("field") {
                    label("label") { +"Depth" }
                    div("control td-dive-depth") {
                        input(InputType.text, null, null, "depth", "input is-rounded") {
                            onInputFunction = { updateProfile() }
                        }
                    }
                    p("help") { +"Depth in meters" }
                }
            }
            div(columnClass(size(3, 3, 6))) {
                div("field") {
                    label("level label") {
                        span("level-left") { +"Time" }
                        span("level-right help td-dive-time-error")
                    }
                    div("control td-dive-time") {
                        input(InputType.text, null, null, "time", "input is-rounded") {
                            onInputFunction = { updateProfile() }
                        }
                    }
                    p("help") { +"Time in minutes" }
                }
            }
            div(columnClass(size(3, 3, 6))) {
                div("field") {
                    label("label") { +"Cons." }
                    div("control td-dive-consumption") {
                        input(InputType.text, null, null, "consumption", "input is-rounded") {
                            onInputFunction = { updateProfile() }
                        }
                    }
                    p("help") { +"Consumption in liter per min" }
                }
            }
        }
        div("columns is-mobile is-multiline is-centered") {
            div(columnClass(size(3, 3, 6))) {
                div("field") {
                    label("label") { +"Optimize" }
                    div("td-dive-optimize buttons has-addons") {
                        span("button is-rounded is-selected is-small td-dive-opt-tanks") {
                            +"Tanks"
                            onClickFunction = {
                                tankPlannerOptions = tankPlannerOptions.copy(optimizeTankUsage = true)
                            }
                        }
                        span("button is-rounded is-selected is-small td-dive-opt-deco") {
                            +"Decompression"
                            onClickFunction = {
                                tankPlannerOptions = tankPlannerOptions.copy(optimizeTankUsage = false)
                            }
                        }
                    }
                    p("help") {
                        +"Changes tank specifications"
                        i(classes = "button is-small is-rounded td-tank-specs-toggle") {
                            onClickFunction = { toggleTankSpecs() }
                        }
                    }
                }
            }
            column(ColumnSize(1, 1, 3), "td-user-risk")
            column(ColumnSize(1, 1, 3), "td-dive-risk")
            div(columnClass(size(2, mobile = 6), centered = true)) {
                label("label") { +"Gradient factors" }
                p {
                    span { +"High " }
                    span("td-dive-gh")
                    span { +" / Low " }
                    span("td-dive-gl")
                }
                p("help td-dive-program")
            }
            div(columnClass(size(2, mobile = 6), centered = true)) {
                div("field") {
                    label("label td-dive-result")
                    span("td-dive-result")
                }
            }
            div(columnClass(size(2, mobile = 6), centered = true)) {
                div("field") {
                    label("label td-dive-total") { +"Total time" }
                    span("td-dive-total")
                }
            }
            div(columnClass(size(1, mobile = 6), centered = true)) {
                div("field") {
                    label("label td-dive-otu") { +"OTU" }
                    span("td-dive-otu")
                }
            }
        }

        div("td-tank-specs")
        div("columns is-multiline td-dive-errors")

        div("level") {
            div("level-left") {
                div("level-item") {
                    p("title") {
                        +"Conditions"
                    }
                }
                div("level-item") {
                    a(classes = "level-item button is-small is-rounded is-info td-conditions-button") {
                        +"Show"
                        onClickFunction = {
                            showHideConditions()
                        }
                    }
                }
            }
        }
        div("td-dive-conditions is-hidden")

        div("td-dive-result") {
            div("td-dive-section")

            div("title td-dive-tanks-title") { +"Tanks" }
            div("td-dive-tanks")

            div("title td-dive-stops-title") { +"Stops" }
            div("columns is-mobile is-multiline td-dive-stops")

            div("title td-dive-details-title") { +"Details" }
            div("td-dive-details")

            div("title td-tank-internal-title") {
                +"Internal (Click for more details)"
                onClickFunction = { internalContent.classList.toggle("is-folded") }
            }
            div("is-folded td-tank-internal")
        }
    }

    val depth = container.querySelector("div.td-dive-depth > input") as HTMLInputElement
    val timeError = container.querySelector("span.td-dive-time-error") as HTMLSpanElement
    val time = container.querySelector("div.td-dive-time > input") as HTMLInputElement
    val consumption = container.querySelector("div.td-dive-consumption > input") as HTMLInputElement

    val optimizeTanks = container.querySelector("span.td-dive-opt-tanks") as HTMLSpanElement
    val optimizeDecompression = container.querySelector("span.td-dive-opt-deco") as HTMLSpanElement
    val tankSpecsToggle = container.querySelector("i.td-tank-specs-toggle") as HTMLElement
    val tankSpecs = container.querySelector("div.td-tank-specs") as HTMLDivElement

    val gradientLow = container.querySelector("span.td-dive-gl") as HTMLSpanElement
    val gradientHigh = container.querySelector("span.td-dive-gh") as HTMLSpanElement
    val program = container.querySelector("p.td-dive-program") as HTMLParagraphElement

    val resultLabel = container.querySelector("label.td-dive-result") as HTMLLabelElement
    val result = container.querySelector("span.td-dive-result") as HTMLSpanElement
    val total = container.querySelector("span.td-dive-total") as HTMLSpanElement
    val otu = container.querySelector("span.td-dive-otu") as HTMLSpanElement

    val errors = container.querySelector("div.td-dive-errors") as HTMLDivElement

    val conditionsButton = container.querySelector("a.td-conditions-button") as HTMLElement
    val conditionsDiv = container.querySelector("div.td-dive-conditions") as HTMLDivElement

    val diveResult = container.querySelector("div.td-dive-result") as HTMLDivElement

    val tanksTitle = container.querySelector("div.td-dive-tanks-title") as HTMLDivElement

    val stopsTitle = container.querySelector("div.td-dive-stops-title") as HTMLDivElement
    val stops = container.querySelector("div.td-dive-stops") as HTMLDivElement

    val details = container.querySelector("div.td-dive-details") as HTMLDivElement

    val internalContent = container.querySelector("div.td-tank-internal") as HTMLDivElement

    val updateMainTankMix: (TankMix, TankMix, TankController) -> Unit = { _, mix, _ ->
        tankPlannerOptions = tankPlannerOptions.copy(forcedMainMix = mix)
    }

    val updateTankMix: (TankMix, TankMix, TankController) -> Unit = { old, new, _ ->
        val newForced = tankPlannerOptions.forcedMixes.toMutableList()
        newForced.remove(old)
        newForced.add(new)

        val newRejected = tankPlannerOptions.rejectedMixes.toMutableList()
        newRejected.remove(new)
        tankPlannerOptions = tankPlannerOptions.copy(forcedMixes = newForced, rejectedMixes = newRejected)
    }

    val deleteTank: (Tank, TankController) -> Unit = { deleted, _ ->
        val newForced = tankPlannerOptions.forcedMixes.toMutableList()
        newForced.remove(deleted.mix)
        val newRejected = tankPlannerOptions.rejectedMixes.toMutableList()
        newRejected.add(deleted.mix)
        tankPlannerOptions = tankPlannerOptions.copy(forcedMixes = newForced, rejectedMixes = newRejected)
    }

    private fun updateTankSpec(index: Int, spec: TankSpec) = decompression?.tankPlanner?.let {
        tankPlannerOptions = tankPlannerOptions.copy(tankSpecs = changeTankSpec(it, index, spec))
    }

    private fun toggleTankSpecs() {
        if (tankSpecs.contains(tankSpecsController.container)) {
            // removes tank specs
            tankSpecs.removeChild(tankSpecsController.container)
            tankSpecsToggle.classList.remove(isSelected, isInfo)
        } else {
            // adds tank specs
            tankSpecs.appendChild(tankSpecsController.container)
            tankSpecsToggle.classList.add(isSelected, isInfo)
        }
    }

    init {
        container.appendAsChildOf("div.td-dive-type", diveTypeController.container)
        container.appendAsChildOf("div.td-user-risk", userRiskFactorController.container)
        container.appendAsChildOf("div.td-dive-risk", diveRiskFactorController.container)
        container.appendAsChildOf("div.td-dive-conditions", conditionController.container)
        container.appendAsChildOf("div.td-dive-section", diveSection.root)
        container.appendAsChildOf("div.td-dive-tanks", tanksController.container)

        updateDecompression()
    }

    fun refresh() {
        diveTypeController.refresh()

        optimizeTanks.classList.toggle(isInfo, tankPlannerOptions.optimizeTankUsage)
        optimizeTanks.classList.toggle(isSelected, tankPlannerOptions.optimizeTankUsage)
        optimizeDecompression.classList.toggle(isInfo, !tankPlannerOptions.optimizeTankUsage)
        optimizeDecompression.classList.toggle(isSelected, !tankPlannerOptions.optimizeTankUsage)

        depth.value = "${profile.maximumDepth}"
        time.value = "${profile.bottomTime}"
        time.classList.remove("is-danger")
        timeError.innerText = ""
        consumption.value = "${profile.consumption}"

        decompression.let { decompression ->
            diveResult.classList.toggle("is-hidden", decompression == null)
            if (decompression != null) {
                val decompressionPrinter = DecompressionPrinter(decompression, messages)

                program.innerText = "${profile.program} / ${decompression.tankPlanner.mainTank.mix.type}"

                val gradientFactors = decompression.gradientFactors
                gradientLow.innerText = "${gradientFactors.low}"
                gradientHigh.innerText = "${gradientFactors.high}"

                // updates tanks
                tanksTitle.innerText = "1 + ${decompression.tankPlanner.tanks.size} tanks"

                /** List tanks for the dive, unused first, the main on top and the others in order of descending MOD */
                tanksController.data = decompression.tankPlanner.allTanks

                total.innerText = "${decompression.totalTime} min"
                otu.innerText = "${decompression.finalOtu().roundToInt()}"

                // updates time info
                if (decompression.tankPlannerOptions.tankSpecs != null) {
                    findMaximalBottomTime(decompression)?.let {
                        timeError.innerText = "Maximum is $it min."
                    }
                    decompression.problems.find { it.message == Message.TankSpecsInsufficient }?.let {
                        time.classList.add("is-danger")
                    }
                }

                // updates errors
                errors.innerHTML = ""
                (decompression.problems + decompression.warnings).map { p ->
                    document.create.article("column is-3 message ${classForType(p.type)}") {
                        div("message-header") {
                            +"For ${formatDayHourMinute(p.where.time.roundToInt())} at ${p.where.depth} m"
                        }
                        div("message-body") {
                            +p.format(messages)
                        }
                    }
                }.forEach {
                    errors.appendChild(it)
                }

                // updates stops
                stops.innerHTML = ""
                result.classList.remove("has-text-danger")
                if (decompression.stops.isEmpty()) {
                    stopsTitle.innerText = "No stops"
                    resultLabel.innerText = "No deco time"
                    result.innerText = "${decompression.noDecompressionTime} min"

                } else {
                    stopsTitle.innerText = "${decompression.stops.size} stops"
                    resultLabel.innerText = "Time to surface"
                    result.innerText = "${decompression.timeToSurface} min"
                }

                decompression.stops.map {
                    document.create.div {
                        classes = setOf(
                            "column",
                            "is-offset-1",
                            "is-3-desktop",
                            "is-6-tablet",
                            "is-12-mobile",
                            "has-text-centered"
                        )
                        div {
                            classes = setOf("tag", "has-text-centered", "is-info", "is-medium")
                            +decompressionPrinter.stopDescription(it)
                        }
                    }
                }.forEach { stops.appendChild(it) }

                // update details
                details.innerHTML = ""
                details.innerText = decompressionPrinter.message

                // update internal
                internalContent.innerHTML = ""
                decompression.info.map {
                    document.create.div(
                        "notification ${when (it.type) {
                            InfoType.Info -> ""; InfoType.Warning -> "is-warning"; InfoType.Critical -> "is-danger"
                        }}"
                    ) {
                        div("level") {
                            div("level-start") { +it.type.toString() }
                            div("level-end") { +it.where.toString() }
                        }
                        div {
                            +it.format(messages)
                        }
                    }
                }.forEach { internalContent.appendChild(it) }

            } else {
                if (!result.classList.contains("has-text-danger")) {
                    result.classList.add("has-text-danger")
                }
                resultLabel.innerText = "Error"
                result.innerText = "Invalid parameters"
                total.innerText = "unknown"
            }
        }
    }

    fun showHideConditions() {
        val active = conditionsDiv.classList.toggle("is-hidden")
        conditionsButton.classList.toggle("is-info", active)
        conditionsButton.innerText = if (active) "Show" else "Hide"
    }

    fun updateProfile(newType: DiveType<*> = profile.type) {
        val newDepthValue = depth.value.toInt()
        val newTimeValue = time.value.toInt()
        val newConsumptionValue = consumption.value.toInt()
        val newProfile = squareProfile(newType, newDepthValue, newTimeValue, newConsumptionValue)
        profile = newProfile
    }

    private fun resetOptions() {
        tankPlannerOptions = initialOptions.copy(
            optimizeTankUsage = tankPlannerOptions.optimizeTankUsage,
            tankSpecs = tankPlannerOptions.tankSpecs
        )
    }

    private fun updateDecompression() {
        // don't compute decompression is profile is Saturation
        decompression = if (profile.program < Program.Saturation)
            Decompression(
                profile, tankPlannerOptions, //infoLevel = InfoType.Info,
                saturation = Saturation(
                    { oxygenPartialPressureSet(profile.type) },
                    normNPValue = userRisk.add(diveRisk).p
                )
            ).also {
                it.compute()
                diveChartController.data = it.profile.depth
                diveChartController.context = it
                bubbleProbabilityController.data = it.bubbleProbability()
            }
        else null
        refresh()
    }
}
