package com.tekdiving.deco

import kotlin.math.roundToInt

const val maximumBottomTime = 600

/** Find maximal bottom time for given decompression (using max depth and excluding time). */
fun findMaximalBottomTime(decompression: Decompression): Int? = decompression.profile.let {
    findMaximalBottomTime(it.type, it.maximumDepth.roundToInt(), it.consumption, decompression.tankPlannerOptions)
}

fun findMaximalBottomTime(
    type: DiveType<*>, maxDepth: Int, consumption: Int, options: TankPlannerOptions
): Int? {
    // avoid CCR, since consumption isn't computed it won't find a limit
    if (type is DiveType.CCRDive) return null

    // find maximal time for given depth
    val step = 10
    var time = step
    var sufficient = isTankSpecsSufficient(type, maxDepth, time, consumption, options)
    while (sufficient) {
        // maximal time is too big to be found, returns null
        if (time >= maximumBottomTime) return null
        time += step
        sufficient = isTankSpecsSufficient(type, maxDepth, time, consumption, options)
    }
    val start = (time - step).coerceAtLeast(0)
    return findTimeForSpec(type, maxDepth, start to time, consumption, options)
}

/**
 * Uses a dichotomy to find the correct time inside the given range time. To be working correctly this function
 * needs that for `[range].first` [isTankSpecsSufficient] is true and for `[range].second` is false. It will return
 * the maxed time.
 */
private fun findTimeForSpec(
    type: DiveType<*>, maxDepth: Int, range: Pair<Int, Int>, consumption: Int, options: TankPlannerOptions
): Int {
    if (range.second - range.first <= 1) {
        return range.first
    } else {
        val medium = (range.first + range.second) / 2
        if (isTankSpecsSufficient(type, maxDepth, medium, consumption, options)) {
            return findTimeForSpec(type, maxDepth, medium to range.second, consumption, options)
        } else {
            return findTimeForSpec(type, maxDepth, range.first to medium, consumption, options)
        }
    }
}

private fun isTankSpecsSufficient(
    type: DiveType<*>, maxDepth: Int, time: Int, consumption: Int, options: TankPlannerOptions
): Boolean {
    val profile = squareProfile(type, maxDepth, time, consumption)
    val decompression = Decompression(
        profile, options.copy(forcedMixes = emptyList(), rejectedMixes = emptyList())
    ).apply { compute() }
    return decompression.valid && decompression.warnings.count() == 0
}
