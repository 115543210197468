package com.tekdiving.deco

import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import kotlin.properties.Delegates.observable

class TankSpecController(
    initialTankSpec: TankSpec, initialDeletable: Boolean = false
) : Controller<TankSpec> {

    var deletable: Boolean by observable(initialDeletable) { _, _, newValue ->
        if (newValue) {
            container.removeChild(delete)
        } else {
            container.appendChild(delete)
        }
    }

    override var data: TankSpec by observable(initialTankSpec) { _, _, _ -> refresh() }

    var onUpdate: ((old: TankSpec, new: TankSpec, TankSpecController) -> Unit) =
        { _, _, _ -> }

    var onDelete: ((deleted: TankSpec, TankSpecController) -> Unit) =
        { _, _ -> }

    override val container: HTMLElement = document.create.div("field has-addons") {
        div("control td-tank-volume") {
            input(InputType.text) {
                classes = setOf("input is-small is-rounded")
                size = "4"
                onChangeFunction = { update() }
            }
        }
        div("control td-tank-pressure") {
            input(InputType.text) {
                classes = setOf("input is-small is-rounded")
                size = "3"
                onChangeFunction = { update() }
            }
        }

    }

    val pressure = container.querySelector(".td-tank-pressure > input") as HTMLInputElement
    val volume = container.querySelector(".td-tank-volume > input") as HTMLInputElement

    val delete = document.create.div("control td-tank-delete") {
        button(classes = "delete") {
            onClickFunction = { onDelete(data, this@TankSpecController) }
        }
    }

    init {
        if (deletable) {
            container.appendChild(delete)
        }
        refresh()
    }

    fun update() {
        val old = data
        data = old.copy(
            pressure = pressure.value.toDouble(),
            volume = volume.value.toDouble()
        )
        onUpdate(old, data, this)
    }

    override fun refresh() {
        volume.value = "${data.volume}"
        pressure.value = "${data.pressure}"
    }
}
