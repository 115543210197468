package com.tekdiving.deco

import kotlin.math.pow

fun otuMax(day: Int) = when (day) {
    0 -> 850
    1 -> 650
    2 -> 600
    3 -> 520
    4 -> 450
    5 -> 420
    6 -> 400
    7 -> 350
    8 -> 330
    9 -> 320
    else -> 300
}

fun otu(oxygenPartialPressure: Double) =
    if (oxygenPartialPressure < 0.5) 0.0 else ((2.0 * oxygenPartialPressure) - 1.0).pow(5.0 / 6.0)

