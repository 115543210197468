package com.tekdiving.deco

import kotlin.math.roundToInt

const val footInMeters = 0.3048
const val psiInBars = 0.06894757

enum class UnitSystem {
    International, Imperial;

    @Suppress("SpellCheckingInspection")
    fun distanceUnit(locale: Locale) = when {
        this == International && locale == Locale.En -> "meters"
        this == International && locale == Locale.Fr -> "mètres"
        this == Imperial && locale == Locale.En -> "feet"
        this == Imperial && locale == Locale.Fr -> "pieds"
        else -> "meters"
    }

    fun shortDistanceUnit(locale: Locale) = when {
        this == International && locale == Locale.En -> "m."
        this == International && locale == Locale.Fr -> "m."
        this == Imperial && locale == Locale.En -> "ft"
        this == Imperial && locale == Locale.Fr -> "p."
        else -> "m."
    }

    @Suppress("SpellCheckingInspection")
    fun speedUnit(locale: Locale) = when {
        this == International && locale == Locale.En -> "meters per minute"
        this == International && locale == Locale.Fr -> "mètres par minute"
        this == Imperial && locale == Locale.En -> "feet per minute"
        this == Imperial && locale == Locale.Fr -> "pieds par minute"
        else -> "meters per minutes"
    }

    fun shortSpeedUnit(locale: Locale) = when {
        this == International && locale == Locale.En -> "m./min."
        this == International && locale == Locale.Fr -> "m./min."
        this == Imperial && locale == Locale.En -> "ft/min."
        this == Imperial && locale == Locale.Fr -> "p./min."
        else -> "m./min."
    }

    @Suppress("SpellCheckingInspection")
    fun pressureUnit(locale: Locale) = when {
        this == International && locale == Locale.En -> "bars"
        this == International && locale == Locale.Fr -> "bars"
        this == Imperial && locale == Locale.En -> "psi"
        this == Imperial && locale == Locale.Fr -> "psi"
        else -> "bars"
    }

    @Suppress("SpellCheckingInspection")
    fun days(locale: Locale) = when(locale) {
        Locale.En -> "days"
        Locale.Fr -> "jours"
    }

    @Suppress("SpellCheckingInspection")
    fun hours(locale: Locale) = when(locale) {
        Locale.En -> "hours"
        Locale.Fr -> "heures"
    }

    @Suppress("SpellCheckingInspection")
    fun minutes(locale: Locale) = when(locale) {
        Locale.En -> "minutes"
        Locale.Fr -> "minutes"
    }

    fun formatTime(time: Int, locale: Locale) =
        formatDayHourMinute(time, days(locale), hours(locale), minutes(locale))

    fun shortPressureUnit(locale: Locale) = pressureUnit(locale)

    /** Transforms meters to this distance value unit */
    fun toDistanceValue(meters: Double) = when (this) {
        International -> meters
        Imperial -> metersToFeet(meters)
    }

    /** Transforms meters to this distance value unit */
    fun toDistanceValue(meters: Int) = when (this) {
        International -> meters
        Imperial -> metersToFeet(meters)
    }

    /** Transforms this distance unit to meters */
    fun fromDistanceValue(value: Double) = when (this) {
        International -> value
        Imperial -> feetToMeters(value)
    }

    /** Transforms this distance unit to meters */
    fun fromDistanceValue(value: Int) = when (this) {
        International -> value
        Imperial -> feetToMeters(value)
    }

    /** Transforms meters per minute to this distance speed unit */
    fun toSpeedValue(metersPerMinute: Int) = toDistanceValue(metersPerMinute)

    /** Transforms meters per minute to this distance speed unit */
    fun toSpeedValue(metersPerMinute: Double) = toDistanceValue(metersPerMinute)

    /** Transforms bars to this pressure value unit */
    fun toPressureValue(bars: Double) = when (this) {
        International -> bars
        Imperial -> barsToPsi(bars)
    }

    /** Transforms bars to this pressure value unit */
    fun toPressureValue(bars: Int) = when (this) {
        International -> bars
        Imperial -> barsToPsi(bars)
    }

    /** Transforms this pressure unit to bars */
    fun fromPressureValue(value: Double) = when (this) {
        International -> value
        Imperial -> psiToBars(value)
    }

    /** Transforms this pressure unit to bars */
    fun fromPressureValue(value: Int) = when (this) {
        International -> value
        Imperial -> psiToBars(value)
    }
}

/** Transforms feet in meters */
fun feetToMeters(feet: Double) = feet * footInMeters

/** Transforms feet in meters */
fun feetToMeters(feet: Int) = (feet * footInMeters).roundToInt()

/** Transforms meters in feet */
fun metersToFeet(meters: Double) = meters / footInMeters

/** Transforms meters in feet */
fun metersToFeet(meters: Int) = (meters / footInMeters).roundToInt()

/** Transforms bars in psi */
fun barsToPsi(bars: Double) = bars / psiInBars

/** Transforms bars in psi */
fun barsToPsi(bars: Int) = (bars / psiInBars).roundToInt()

/** Transforms feet in meters */
fun psiToBars(psi: Double) = psi * psiInBars

/** Transforms feet in meters */
fun psiToBars(psi: Int) = (psi * psiInBars).roundToInt()

internal const val dayInMinutes = 60 * 24

fun formatDayHourMinuteFull(time: Int) = formatDayHourMinute(time, "days", "hours", "minutes")

fun formatDayHourMinute(time: Int, days: String = "d.", hours: String = "h.", minutes: String = "min."): String =
    if (time == 0) "T0" else {
        val dayCount = time / dayInMinutes
        val dayRest = time % dayInMinutes
        val hourCount = dayRest / 60
        val minuteCount = dayRest % 60
        listOfNotNull(
            if (dayCount > 0) "$dayCount $days" else null,
            if (dayCount > 0 || hourCount > 0) "$hourCount $hours" else null,
            "$minuteCount $minutes"
        ).joinToString(" ")
    }

fun celsiusToKelvin(celsius: Double) = celsius + 273.15
