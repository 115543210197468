package com.tekdiving.deco

import kotlin.math.sqrt

/** Limit for sport exposition */
const val sportExpositionLimit = 200

/** Limit for Tek exposition */
const val tekExpositionLimit = 450

/** Limit for Expedition exposition */
const val expeditionExpositionLimit = 600

/** Limit for recreation time to surface */
const val recreationTtsLimit = 10

/** Limit for sport time to surface */
const val sportTtsLimit = 60

/** Limit for tek time to surface of 3 hours */
const val tekTtsLimit = 180

/** Limit for expedition time to surface of 6 hours */
const val expeditionTtsLimit = 360

/** Limit for recreational */
const val recreationalDepthLimit = 40

/** Legal limit for sport */
const val sportDepthLimit = 60.0

/** Limit for Tek */
const val tekDepthLimit = 120.0

/** Maximum depth limit for planning (expedition) */
const val expeditionDepthLimit = 150.0

/** Maximum depth limit for SCR (expedition) */
const val scrDepthLimit = 40.0

/** Limit for Nitrox */
const val nitroxDepthLimit = 40

/** Limit the Trimix with nitrogen majority */
const val trimixNitrogenDepthLimit = 60

enum class Program {
    Recreational, Sport, Tek, Expedition, Saturation;
}

/** Find the program for [depth] and [atmosphericPressure] by computing a time to surface with standard parameters */
fun program(depth: List<DivePlot>, atmosphericPressure: Double = seaLevelAtmosphericPressure): Program {
    val maximumDepth = depth.asSequence().map { (_, d) -> d }.maxOrNull() ?: 0.0
    val tts = ttsForProgram(depth, atmosphericPressure)
    return when {
        tts < recreationTtsLimit && maximumDepth <= recreationalDepthLimit -> Program.Recreational
        tts < sportTtsLimit && maximumDepth <= sportDepthLimit -> Program.Sport
        tts < tekTtsLimit && maximumDepth <= tekDepthLimit -> Program.Tek
        tts < expeditionTtsLimit && maximumDepth <= expeditionDepthLimit -> Program.Expedition
        else -> Program.Saturation
    }
}

fun exposingFactor(bottomDepth: Double, bottomTime: Double) = bottomDepth * sqrt(bottomTime)

fun programFromExposingFactor(bottomDepth: Double, bottomTime: Double): Program {
    val exposingFactor = exposingFactor(bottomDepth, bottomTime)
    return when {
        exposingFactor < sportExpositionLimit && bottomDepth <= sportDepthLimit -> Program.Sport
        exposingFactor < tekExpositionLimit && bottomDepth <= tekDepthLimit -> Program.Tek
        exposingFactor < expeditionExpositionLimit && bottomDepth <= expeditionDepthLimit -> Program.Expedition
        else -> Program.Saturation
    }
}

fun programFromExposingFactor(depth: List<DivePlot>): Program {
    val maximumDepth = depth.asSequence().map { (_, d) -> d }.maxOrNull() ?: 0.0
    val bottomTime = bottomTime(depth)
    return programFromExposingFactor(maximumDepth, bottomTime.toDouble())
}

/**
 * Computes the time to surface for given [depth] and [atmosphericPressure] with a standard mix using
 * a CCR circuit for constant ppo2. It'll be used to select the program for the dive.
 */
fun ttsForProgram(depth: List<DivePlot>, atmosphericPressure: Double = seaLevelAtmosphericPressure): Int {
    val program = programFromExposingFactor(depth)
    val profile = DiveProfile(depth, DiveType.defaultCCRDive, 10, atmosphericPressure, program)

    val decompression = Decompression(profile)
    decompression.compute()

    return decompression.timeToSurface
}

fun advisedGasMixForProgram(program: Program, maximumDepth: Double) = when {
    program <= Program.Sport && maximumDepth <= nitroxDepthLimit -> GasMix.Nitrox
    program <= Program.Sport && maximumDepth <= trimixNitrogenDepthLimit -> GasMix.TrimixNitrogen
    else -> GasMix.TrimixHelium
}
