package com.tekdiving.deco

import kotlinx.serialization.Serializable
import kotlin.math.roundToInt

@Serializable
data class RiskFactor(val p: Double) {

    val percentage: Int
        get() = 10 + (((minRiskFactor - p)) / (minRiskFactor - maxRiskFactor) * 90.0).roundToInt()

    fun add(other: RiskFactor) =
        RiskFactor((p - (minRiskFactor - other.p)).coerceIn(maxRiskFactor, minRiskFactor))

}

val UnknownRiskFactor = RiskFactor(50.0)

const val maxRiskFactor = 6.0
const val minRiskFactor = 50.0

const val lowRiskFactor = 45.0
const val mediumRiskFactor = 25.0
const val highRiskFactor = 6.0
