package com.tekdiving.utp

import com.tekdiving.deco.*
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onClickFunction
import org.w3c.dom.*
import kotlin.math.roundToInt


class UnderThePoleController : MultiLevelController {

    var utpConfig = UtpConfig()
        set(value) {
            if (field != value) {
                field = value
                updateLevels()
            }
        }

    override val tanks get() = arrayOf(utpConfig.tank)

    override var levels: List<Level> = listOf(Level(24, 90, utpConfig.tank))
        set(value) {
            if (field != value) {
                field = value
                updateLevels()
            }
        }

    var messages: Messages = Messages(Locale.En)
        set(value) {
            if (field != value) {
                field = value
                updateDecompression()
            }
        }


    override var profile: DiveProfile = squareProfile(utpConfig.diveType, 0, 0, 10)
    var tankPlannerOptions = TankPlannerOptions(plan = false, forcedMainMix = utpConfig.tank.mix)
    var decompression: Decompression = Decompression(profile, tankPlannerOptions).apply { compute() }

    private var utpConfigController: UtpConfigController? = null

    /*
    private var chart: Chart? = null
*/
    val container: HTMLElement = document.create.div {

        // title
        div("title") {
            +"Capsule"
            span(classes = "button is-rounded is-small is-info td-capsule-config-toggle") {
                onClickFunction = { toggleConfig() }
            }
        }

        // levels
        levelTable(40, 20) { th { +"OTU" } }

        // infos
        div("columns is-mobile is-multiline is-centered") {
            div("column is-half-mobile is-2-tablet") {
                div("field") {
                    label("label") { +"Dive total time" }
                    span("td-dive-time")
                    span("help td-dive-time-help")
                }
            }
            div("column is-half-mobile is-2-tablet") {
                div("field td-dive-info") {
                    label("label") { +"Information" }
                    span()
                }
            }
            div(columnClass(size(1, mobile = 6), centered = true)) {
                div("field") {
                    label("label td-dive-otu") { +"OTU" }
                    span("td-dive-otu")
                }
            }
        }

        // errors
        div("columns is-multiline td-dive-errors")

        // dive chart
        div("title") { +"Dive" }
        canvas("td-dive-chart") {}

        div("title td-dive-details-title") { +"Details" }
        div("td-dive-details")

        div("title td-tank-internal-title") {
            +"Internal (Click for more details)"
            onClickFunction = { internalContent.classList.toggle("is-folded") }
        }
        div("is-folded td-tank-internal")
    }

    val title = container.querySelector("div.title") as HTMLDivElement

    val toggleUtpConfig = container.querySelector("span.td-capsule-config-toggle") as HTMLSpanElement

    val diveTime = container.querySelector("span.td-dive-time") as HTMLSpanElement
    val diveTimeHelp = container.querySelector("span.td-dive-time-help") as HTMLSpanElement

    val diveInfoLabel = container.querySelector(".td-dive-info > .label") as HTMLLabelElement
    val diveInfo = container.querySelector(".td-dive-info > span") as HTMLSpanElement

    override val levelsBody = container.querySelector("table.td-dive-levels > tbody") as HTMLTableSectionElement

    val errors = container.querySelector("div.td-dive-errors") as HTMLDivElement

    val chartCanvas = container.querySelector("canvas.td-dive-chart") as HTMLCanvasElement

    val details = container.querySelector("div.td-dive-details") as HTMLDivElement

    val otu = container.querySelector("span.td-dive-otu") as HTMLSpanElement

    val internalContent = container.querySelector("div.td-tank-internal") as HTMLDivElement

    init {
        updateLevels()
    }

    fun otuForLevel(index: Int): Int {
        val time = levels.subList(0, index + 1).fold(0.0) { a, c -> a + c.time }
        return decompression.otuAtTime(time).roundToInt()
    }

    override fun <T> TagConsumer<T>.levelExtra() {
        td {
            span("td-dive-otu")
        }
    }

    override fun refreshLevelExtra(index: Int, level: Level, row: HTMLTableRowElement) {
        val otu = row.querySelector("span.td-dive-otu") as HTMLSpanElement
        val otuForLevel = otuForLevel(index)
        otu.innerText = "$otuForLevel"
        otu.classList.toggle("has-text-danger", otuForLevel > maxOtu)
    }

    fun refresh() {
        toggleUtpConfig.innerText = "${utpConfig.capsuleDepth} m"

        diveTime.innerText = formatDayHourMinute(decompression.totalTime)
        diveTimeHelp.innerText = if (profile.finalTime > 180) "Dive is longer than 3 hours" else ""

        if (decompression.stops.isNotEmpty()) {
            diveInfoLabel.innerText = "TTS"
            val stopsText = when (decompression.stops.size) {
                0 -> "No stop needed"
                1 -> "1 stop"
                else -> "${decompression.stops.size} stops"
            }
            diveInfo.innerText = "${formatDayHourMinute(decompression.timeToSurface)}, $stopsText"
        } else {
            diveInfoLabel.innerText = "No Deco time"

            // computes no deco time with correct parameters
            val noDecoTime = decompression.saturation.minimalNoDecompressionTime(
                profile.maximumPressure,
                profile.toPressure(utpConfig.capsuleDepth),
                utpConfig.tank
            ).roundToInt()

            diveInfo.innerText = formatDayHourMinute(noDecoTime)
        }

        otu.innerText = "${decompression.finalOtu().roundToInt()}"

        refreshLevels()

        // refresh decompression
        val decompressionPrinter = DecompressionPrinter(decompression, messages)

        // updates errors
        errors.innerHTML = ""
        decompression.problems.map { p ->
            document.create.article("column is-3 message is-warning") {
                div("message-header") {
                    +"For ${p.where.time} min. at ${p.where.depth} m"
                }
                div("message-body") {
                    +p.format(messages)
                }
            }
        }.forEach {
            errors.appendChild(it)
        }
/*
        // creates a new chart
        chart?.destroy()

        val chartOptions = BasicLineChartOptions()
        chartOptions.scales.xAxes = arrayOf(BasicLinearAxisOptions())
        chartOptions.scales.xAxes[0].ticks.stepSize = 5
        chartOptions.scales.yAxes = arrayOf(BasicLinearAxisOptions(id = "depth"))
        chartOptions.scales.yAxes[0].ticks.suggestedMin = -decompression.profile.maximumDepth - 5

        chartOptions.tooltips.custom = { tooltipModel ->
            val dataPoints = tooltipModel.dataPoints
            if (dataPoints != null && dataPoints.isNotEmpty()) {
                val extra = mutableListOf<String>()
                val pointTime = dataPoints[0].xLabel.toDouble()

                if (dataPoints[0].datasetIndex == 0) {
                    val problem = decompression.problems.find { it.where.time == pointTime }
                    if (problem != null) {
                        extra.add(problem.format(messages))
                    }
                }

                extra.add(decompression.tankPlanner.tankAtTime(pointTime).description)

                tooltipModel.afterBody = extra.toTypedArray()
                val textSize = tooltipModel.bodyFontSize.toInt()
                tooltipModel.height = tooltipModel.height.toInt() + textSize * extra.size
                tooltipModel.width =
                    max(tooltipModel.width.toInt(), extra.asSequence().map { it.length * textSize / 2 }.maxOrNull() ?: 0)
            }
        }

        // updates chart
        val datasets = arrayOf<LineDataSet>(
            BasicLineDataSet(
                "Ceiling", decompression.minDepthPlot().map { BasicLineChartPlot(it.time, -it.depth) }.toTypedArray(),
                borderColor = "rgb(238, 144, 144)", backgroundColor = "rgba(238, 144, 144, 0.3)", yAxisID = "depth"
            ),
            BasicLineDataSet(
                "Profile", decompression.profilePlot().map { BasicLineChartPlot(it.time, -it.depth) }.toTypedArray(),
                borderColor = "rgb(103, 182, 249)", backgroundColor = "rgba(103, 182, 249, 0.3)", fill = "none",
                lineTension = 0.00, pointBorderWidth = 5, yAxisID = "depth"
            )
        )

        chart = Chart(chartCanvas, BasicLineChartConfig(BasicLineChartData(datasets), chartOptions))
*/
        // update details
        details.innerHTML = ""
        details.innerText = decompressionPrinter.message

        // update internal
        internalContent.innerHTML = ""
        decompression.info.map {
            document.create.div(
                "notification ${when (it.type) {
                    InfoType.Info -> ""; InfoType.Warning -> "is-warning"; InfoType.Critical -> "is-danger"
                }}"
            ) {
                div("level") {
                    div("level-start") { +it.type.toString() }
                    div("level-end") { +it.where.toString() }
                }
                div {
                    +it.format(messages)
                }
            }
        }.forEach { internalContent.appendChild(it) }
    }

    private fun toggleConfig() {
        if (utpConfigController == null) {
            utpConfigController = UtpConfigController(utpConfig).also {
                it.onUpdate = { _, new -> utpConfig = new }
                title.insertAdjacentElement("afterend", it.container)
            }

        } else {
            utpConfigController?.let {
                container.removeChild(it.container)
            }
            utpConfigController = null
        }
    }

    override fun updateProfile() {
        println("Updating profile")
        val depth = leveledPlot(
            levels.map { DivePlot(it.time.toDouble(), it.depth.toDouble(), it.tank) },
            startingDepth = utpConfig.capsuleDepth.roundToInt()
        )
        profile = createDiveProfile(depth, type = utpConfig.diveType)
        updateDecompression()
    }

    private fun updateDecompression() {
        println("Updating decompression")
        decompression = utpConfig.decompression(profile)
        decompression.compute()
        refresh()
    }
}

