package com.tekdiving.deco

import kotlin.math.exp
import kotlin.math.ln
import kotlin.math.roundToInt

class BubbleProbability(val dtr: Double, val tank: Tank) {

    /** Used tank to compute bubbles, if no decompression given, uses an Air (the worst possible case) */

    val n2 = tank.correctedNitrogenRatio
    val he = tank.correctedHeliumRatio
    val o2 = tank.mix.oxygen.ratio

    /** Maximum allowed DTR for given tank */
    val dtrMax = if (n2 >= he) 45.0 else 360.0

    val kMin = 0.004229
    val kMax = 0.01199

    val k1 = kMax - (dtr / dtrMax * (kMax - kMin))

    val k2 = (1.0 - o2).coerceAtLeast(0.1).let { 0.79 / it + 0.79 * he / it }

    val k = k1 * k2

    val y0 = 1.0 + 0.309 * dtr / dtrMax

    // Exponential growth for bubble probability (between 0 and 1).
    fun probabilityForTime(time: Int): Double = (y0 * exp(-k * time.toDouble())).coerceIn(0.0, 1.0)

    fun timeForProbability(p: Double): Int = (ln(p / y0) / -k).coerceAtLeast(0.0).roundToInt()
}
