package com.tekdiving.deco

import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import org.w3c.dom.*
import kotlin.math.roundToInt
import kotlin.properties.Delegates

class TankController(
    initialTank: Tank,
    initialDecompression: Decompression? = null
) : Controller<Tank> {

    override var data: Tank = initialTank
        set(value) {
            field = value
            refresh()
        }

    var decompression: Decompression? = initialDecompression
        set(value) {
            field = value
            refresh()
        }

    var messages by Delegates.observable(Messages(Locale.En)) { _, _, _ -> refresh() }

    var onUpdateMix: ((old: TankMix, new: TankMix, TankController) -> Unit) = { _, _, _ -> }
    var onUpdateSpec: ((old: TankSpec, new: TankSpec, TankController) -> Unit) = { _, _, _ -> }

    var onDelete: ((deleted: Tank, TankController) -> Unit)? = null
        set(value) {
            if (value == null) {
                delete.classList.add("is-invisible")
            } else {
                delete.classList.remove("is-invisible")
            }
            field = value
        }

    var tankSpecController = TankSpecController(data.spec).apply {
        onUpdate = { old, new, _ ->
            onUpdateSpec(old, new, this@TankController)
        }
    }

    override val container: HTMLElement = document.create.div("td-tank") {
        button(classes = "delete is-invisible") {
            onClickFunction = { deleteHandler() }
        }
        span("level is-mobile") {
            span("level-start td-tank-title")
            span("level-item td-tank-depth")
            span("level-end td-tank-message")
            span("level-end td-tank-type")
        }
        div("level is-mobile") {
            div("level-start is-mobile td-tank-spec")
            div("level-end") {
                p("td-tank-consumption") {
                    span("td-tank-burned")
                    span("td-tank-expanded")
                }
                p {  span("level-item td-tank-time") }
            }
        }

        div("td-tank-gas") {
            div("level is-mobile") {
                div()
                div("td-tank-title-min") { +"Min" }
                div("td-tank-title-real") { +"Real" }
                div("td-tank-title-max") { +"Max" }
            }
            div("level is-mobile td-tank-o2") {
                div("td-tank-label") { +"O2" }
                div("td-tank-min")
                div("control td-tank-real") {
                    input(InputType.text) {
                        classes = setOf("input is-small is-rounded")
                        size = "4"
                        onChangeFunction = { updateOxygen() }
                    }
                }
                div("td-tank-max")
            }
            div("level is-mobile td-tank-he") {
                div("td-tank-label") { +"He" }
                div("td-tank-min")
                div("control td-tank-real") {
                    input(InputType.text) {
                        classes = setOf("input is-small is-rounded")
                        size = "4"
                        onChangeFunction = { updateHelium() }
                    }
                }
                div("td-tank-max")
            }
            div("level is-mobile td-tank-n2") {
                div("td-tank-label") { +"N2" }
                div("td-tank-min")
                div("control td-tank-real")
                div("td-tank-max")
            }
        }
    }

    val main: HTMLDivElement = container as HTMLDivElement
    val delete = container.querySelector("button.delete") as HTMLButtonElement

    val title = container.querySelector("span.td-tank-title") as HTMLSpanElement
    val time = container.querySelector("span.td-tank-time") as HTMLSpanElement
    val depth = container.querySelector("span.td-tank-depth") as HTMLSpanElement
    val message = container.querySelector("span.td-tank-message") as HTMLSpanElement
    val type = container.querySelector("span.td-tank-type") as HTMLSpanElement

    val consumption = container.querySelector("p.td-tank-consumption") as HTMLParagraphElement
    val burned = container.querySelector("span.td-tank-burned") as HTMLSpanElement
    val expanded = container.querySelector("span.td-tank-expanded") as HTMLSpanElement

    val oxygen = container.querySelector(".td-tank-o2 > div.td-tank-real > input") as HTMLInputElement
    val minOxygen = container.querySelector(".td-tank-o2 > div.td-tank-min") as HTMLDivElement
    val maxOxygen = container.querySelector(".td-tank-o2 > div.td-tank-max") as HTMLDivElement

    val helium = container.querySelector(".td-tank-he > div.td-tank-real > input") as HTMLInputElement
    val minHelium = container.querySelector(".td-tank-he > div.td-tank-min") as HTMLDivElement
    val maxHelium = container.querySelector(".td-tank-he > div.td-tank-max") as HTMLDivElement

    val nitrogen = container.querySelector(".td-tank-n2 > div.td-tank-real") as HTMLDivElement
    val minNitrogen = container.querySelector(".td-tank-n2 > div.td-tank-min") as HTMLDivElement
    val maxNitrogen = container.querySelector(".td-tank-n2 > div.td-tank-max") as HTMLDivElement

    init {
        container.appendAsChildOf("div.td-tank-spec", tankSpecController.container)
        refresh()
    }

    fun updateOxygen() {
        val old = data.mix
        val new = old.changeOxygen(oxygen.value.toInt())
        data = data.copy(mix = new)
        updateStatus()
        onUpdateMix(old, new, this)
    }

    fun updateHelium() {
        val old = data.mix
        val new = old.changeHelium(helium.value.toInt())
        data = data.copy(mix = new)
        updateStatus()
        onUpdateMix(old, new, this)
    }

    private fun updateStatus() {
        if (data.valid) {
            oxygen.classList.remove("has-background-danger")
            helium.classList.remove("has-background-danger")
            nitrogen.classList.remove("has-background-danger")
        } else {
            oxygen.classList.add("has-background-danger")
            helium.classList.add("has-background-danger")
            nitrogen.classList.add("has-background-danger")
        }
    }

    override fun refresh() {
        updateStatus()

        main.classList.remove("is-warning")
        main.classList.remove("is-danger")

        type.classList.toggle("td-tank-ccr", data.type == TankType.CCR)
        type.classList.toggle("td-tank-oc", data.type != TankType.CCR)

        type.innerText = ""

        expanded.innerText = "${data.spec.expandedVolume.roundToInt()}"
        if (data.type == TankType.CCR) {
            consumption.classList.toggle("is-hidden", true)
            tankSpecController.container.classList.toggle("is-hidden", true)
        } else {
            consumption.classList.toggle("is-hidden", false)
            tankSpecController.container.classList.toggle("is-hidden", false)
        }

        decompression?.let {
            when (it.tankPlanner.tankState(data)) {
                TankState.MoreThanFullyBurned -> {
                    main.classList.add("is-danger")
                    val error = messages.format(Message.TankFullyBurned, listOf(data.description))
                    message.setAttribute("data-content", error)
                }
                TankState.MoreThanTwoThirdBurned -> {
                    main.classList.add("is-warning")
                    val error = messages.format(Message.TankTwoThirdBurned, listOf(data.description))
                    message.setAttribute("data-content", error)
                }
                else -> {
                    message.removeAttribute("data-content")
                }
            }

            if (data == it.tankPlanner.mainTank && it.isMainTankNotSuitable) {
                main.classList.add("is-danger")
                val error = messages.format(Message.MainTankNotSuitable, it.profile.maximumDepth)
                message.setAttribute("data-content", error)
            }

            val timeInterval = it.tankPlanner.timeForTank(data)
            time.innerText = "${timeInterval.first.roundToInt()} to ${timeInterval.second.roundToInt()} min"

            if (data.type == TankType.CCR) {
                depth.innerText = ""
                burned.innerText = "-"
                expanded.innerText = "-"
            } else {
                depth.innerText = " ${it.profile.tankMaximumDepth(data)}"
                burned.innerText = "${it.tankPlanner.burnedForTank(data).roundToInt()}"
                expanded.innerText = "${data.spec.expandedVolume.roundToInt()}"
            }

            if (!data.type.main) {
                type.innerText = "#${it.tankPlanner.tankOrder(data)}"
            }
        }

        title.innerText = data.mix.shortDescription

        val mix = data.mix
        oxygen.value = "${mix.oxygen.real}"
        minOxygen.innerText = "${mix.oxygen.min}"
        maxOxygen.innerText = "${mix.oxygen.max}"
        helium.value = "${mix.helium.real}"
        minHelium.innerText = "${mix.helium.min}"
        maxHelium.innerText = "${mix.helium.max}"


        val nitrogenRatio = mix.nitrogen
        nitrogen.innerText = "${nitrogenRatio.real}"
        minNitrogen.innerText = "${nitrogenRatio.min}"
        maxNitrogen.innerText = "${nitrogenRatio.max}"

    }

    fun deleteHandler() {
        onDelete?.invoke(data, this)
    }
}
