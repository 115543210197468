package com.tekdiving.deco

import bulma.*
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLParagraphElement
import org.w3c.dom.HTMLSpanElement

data class ColumnSize(
    val desktop: Int, val tablet: Int = desktop, val mobile: Int? = null, val centered: Boolean = false
) {
    val classes = "column is-$desktop-desktop is-$tablet-tablet " +
            if (mobile != null) "is-$mobile-mobile" else "" +
                    if (centered) "has-text-centered" else ""
}

fun size(desktop: Int, tablet: Int = desktop, mobile: Int? = null) = ColumnSize(desktop, tablet, mobile)

fun columnClass(size: ColumnSize, centered: Boolean = false) =
    "${size.classes} ${if (centered) "has-text-centered" else ""}"

fun column(content: HTMLElement, size: ColumnSize = size(4)): HTMLDivElement {
    val result = document.createElement("div") as HTMLDivElement
    result.className = "column ${size.classes}"
    result.appendChild(content)
    return result
}

inline fun HTMLElement.appendAsChildOf(selector: String, child: HTMLElement) {
    this.querySelector(selector)?.append(child)
}

fun classForType(type: InfoType) = when (type) {
    InfoType.Critical -> "is-danger"
    InfoType.Warning -> "is-warning"
    else -> ""
}

fun Double.format(digits: Int): String = this.asDynamic().toFixed(digits) as String

@HtmlTagMarker
fun <T, C : TagConsumer<T>> C.columns(classes: String = "", block: DIV.() -> Unit = {}) =
    DIV(attributesMapOf("class", "columns $classes"), this).visitAndFinalize(this, block)

@HtmlTagMarker
fun FlowContent.columns(classes: String = "", block: DIV.() -> Unit = {}) =
    DIV(attributesMapOf("class", "columns $classes"), consumer).visit(block)

@HtmlTagMarker
fun FlowContent.column(size: ColumnSize = ColumnSize(4), classes: String = "", block: DIV.() -> Unit = {}) =
    DIV(attributesMapOf("class", "${size.classes} $classes"), consumer).visit(block)

@HtmlTagMarker
fun FlowContent.field(
    type: InputType = InputType.text,
    label: String = "",
    id: String = "",
    defaultValue: String = "",
    help: String = ""
) =
    div("field") {
        label("label") { +label }
        div("control") {
            input(type, classes = "input") {
                this.id = id
                placeholder = label
                value = defaultValue
            }
        }
        if (help.isNotEmpty()) {
            p("help") { +help }
        }
    }

fun field(
    type: InputType = InputType.text,
    label: String = "", id: String = "",
    defaultValue: String = "", help: String? = null,
    onChange: (String) -> Unit = {}
): Field = Field(
    Label(label),
    Control(
        Input(
            placeholder = label,
            value = defaultValue,
            onChange = { _, v -> onChange(v) }
        ).apply { this.type = type.toString() }
    ).apply { root.id = id }
).apply { help?.let { body += Help(help) } }

class ResultElement(
    label: String = "", value: String = "", help: String = ""
): BulmaElement {
    override val root: HTMLElement = document.create.div {
        label("label") { +label }
        span(classes = "result") { +value }
        p("help") { +help }
    }

    private val span = root.querySelector("span") as HTMLSpanElement
    private val help = root.querySelector("p") as HTMLParagraphElement

    override var text: String
        get() = span.innerText
        set(value) { span.innerText = value }

    var helpText: String
        get() = help.innerText
        set(value) { help.innerText = value }

}
