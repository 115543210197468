package com.tekdiving.deco

import kotlinx.browser.document
import kotlinx.html.button
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import kotlinx.html.label
import org.w3c.dom.HTMLElement
import kotlin.properties.Delegates.observable

class TankSpecsController(
    initialSpecs: List<TankSpec>?
) : Controller<List<TankSpec>?> {

    var onUpdate: (List<TankSpec>?, List<TankSpec>?, Controller<List<TankSpec>?>) -> Unit =
        { _, _, _ -> }


    override var data: List<TankSpec>? by observable(initialSpecs)
    { _, old, new ->
        if (old != new) {
            oldData = old
            tankSpecsController.data = new ?: emptyList()
            onUpdate(old, new, this)
            refresh()
        }
    }

    private var oldData: List<TankSpec>? = null

    val tankSpecsController =
        ListController(
            initialSpecs ?: emptyList(), "is-mobile is-centered", size(2)
        ) { index, value ->
            val tankSpecController = TankSpecController(value, true)
            tankSpecController.onUpdate = { _, new, _ ->
                val old = data
                val newSpecs = old?.toMutableList() ?: mutableListOf()
                newSpecs[index] = new
                data = newSpecs
            }
            tankSpecController.onDelete = { _, _ ->
                val old = data
                old?.let {
                    val newSpecs = it.toMutableList()
                    newSpecs.removeAt(index)
                    data = if (newSpecs.isEmpty()) null else newSpecs
                }
            }
            tankSpecController
        }

    override val container: HTMLElement = document.create.div("td-tank-specs") {
        div("level") {
            label("level-start label") {
                +"Tank specifications"
            }
            button(classes = "level-start is-outlined is-small is-rounded button") {
                +"+"
                onClickFunction = {
                    val old = data
                    val newSpecs = old?.toMutableList() ?: mutableListOf()
                    newSpecs.add(TankSpec(11.0, 210.0))
                    data = newSpecs
                }
            }
        }
    }

    init {
        container.append(tankSpecsController.container)
        refresh()
    }

    override fun refresh() {
        tankSpecsController.refresh()
    }


}
