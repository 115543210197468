package com.tekdiving.vp2

import bulma.*
import bulma.Level
import com.tekdiving.deco.*
import kotlin.math.roundToInt
import kotlin.properties.Delegates

class VP2ModelController : NoContextController<VP2Dive, BulmaElement>() {

    override var data: VP2Dive by Delegates.observable(
        VP2Dive(
            120.0, 50.0,
            1.3, mainTankMix(60.0, 30.0, 1.3)
        )
    ) { _, old, new ->
        if (old != new) {
            model = VP2Model(data)
            chartController.data = model
            chartController.refresh()
            exposingFactorLabel.text = data.program.toString()
            totalTime.text = formatDayHourMinuteFull(model.totalTime.toInt())
            optimizedTotalTime.text = formatDayHourMinuteFull(model.optimizedTotalTime.toInt())
            gainedTime.text = gainedText()
            optimizedTimeDiv.hidden = !model.optimized
            gainedDiv.hidden = !model.optimized

            optimizedSpeedValue.text = optimizedSpeed()
            speedLevel.hidden = !model.optimized
        }
    }

    var model = VP2Model(data)

    fun diveProfile(bottomDepth: Double, bottomTime: Double, ppO2: Double) = createDiveProfile(
        squarePlot(bottomDepth.roundToInt(), bottomTime.roundToInt()),
        type = DiveType.CCRDive(DiveOptions.CCROptions(ppO2))
    )

    fun mainTankMix(bottomDepth: Double, bottomTime: Double, ppO2: Double) =
        mainRebreatherTankMix(diveProfile(bottomDepth, bottomTime, ppO2))

    override var readOnly: Boolean by Delegates.observable(false) { _, old, new ->
        if (old != new) {
            depthText.readonly = new
            timeText.readonly = new
            ppO2Text.readonly = new
        }
    }

    val depthText: Input = Input(data.bottomDepth.toString(), "Depth", rounded = true) { _, value ->
        value.toDoubleOrNull()?.let {
            data = data.copy(bottomDepth = it, mix = mainTankMix(it, data.bottomTime, data.ppO2))
        }
    }

    val timeText: Input = Input(data.bottomTime.toString(), "Time", rounded = true) { _, value ->
        value.toDoubleOrNull()?.let {
            data = data.copy(bottomTime = it, mix = mainTankMix(data.bottomDepth, it, data.ppO2))
        }
    }

    val ppO2Text: Input = Input(data.ppO2.toString(), "Ppo2", rounded = true) { _, value ->
        value.toDoubleOrNull()?.let {
            val newPpO2 = it.coerceIn(0.5, 2.0)
            data = data.copy(ppO2 = newPpO2, mix = mainTankMix(data.bottomDepth, data.bottomTime, it))
        }
    }

    val exposingFactorLabel = Label(data.program.toString())

    val totalTime = Value(formatDayHourMinuteFull(model.totalTime.toInt()))
    val optimizedTotalTime = Value(formatDayHourMinuteFull(model.optimizedTotalTime.toInt()))
    val gainedTime = Value(gainedText())

    val totalTimeDiv = Div(Label("Total time"), totalTime)
    val optimizedTimeDiv = Div(Label("Optimized time"), optimizedTotalTime).apply {
        hidden = !model.optimized
    }
    val gainedDiv = Div(Label("Gained"), gainedTime).apply {
        hidden = !model.optimized
    }

    val timeLevel = Level(center = listOf(totalTimeDiv, optimizedTimeDiv, gainedDiv))

    val slowestSpeedText: Input = Input(slowestSpeed(), "", rounded = true) { _, value ->
        value.toDoubleOrNull()?.let {
            val newSpeed = it.coerceIn(27.0, 60.0) / model.aDayInMinutes
            data = data.copy(slowestSpeed = newSpeed)
        }
    }

    val optimizedSpeedValue = Value(optimizedSpeed())

    val speedLevel = Level(
        center = listOf(
            Label("Saturation speed"), slowestSpeedText, Help("meter / day"),
            Label("Optimized speed"), optimizedSpeedValue, Help("meter / day")
        )
    ).apply {
        hidden = !model.optimized
    }

    val chartController = VP2ModelChartController(model)

    override val container: BulmaElement = Div(
        Level(
            center = listOf(
                HorizontalField(Label("Depth"), Field(Control(depthText))),
                HorizontalField(Label("Time"), Field(Control(timeText))),
                HorizontalField(Label("Ppo2"), Field(Control(ppO2Text))),
                exposingFactorLabel
            )
        ),
        timeLevel,
        speedLevel,
        chartController
    )

    override fun refresh() {
        chartController.refresh()
    }

    fun gainedText(): String {
        val gained = model.totalTime - model.optimizedTotalTime
        val time = formatDayHourMinuteFull(gained.toInt())
        val percentage = (100.0 * gained / model.totalTime).roundToInt()
        return "$time ($percentage%)"
    }

    fun slowestSpeed() = (data.slowestSpeed * model.aDayInMinutes).format(2)

    fun optimizedSpeed() = (model.optimizedSpeed * model.aDayInMinutes).format(2)
}
