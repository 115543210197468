package com.tekdiving.utp

import com.tekdiving.deco.Controller
import com.tekdiving.deco.GradientFactors
import com.tekdiving.deco.isInfo
import com.tekdiving.deco.isSelected
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import org.w3c.dom.*

class UtpConfigController(
    initialConfig: UtpConfig = UtpConfig()
): Controller<UtpConfig> {

    override var data: UtpConfig = initialConfig
        set(value) {
            field = value
            refresh()
        }

    var onUpdate: ((old: UtpConfig, new: UtpConfig) -> Unit) = { _, _ -> }

    override val container: HTMLElement = document.create.div("message") {
        div("columns is-multiline") {
            div("column is-half-mobile is-2-tablet") {
                div("field") {
                    label("label") { +"Capsule Depth" }
                    div("td-capsule-depth buttons has-addons") {
                        listOf(12.0, 18.0).forEach { depth ->
                            span("button is-rounded is-selected is-small") {
                                +"$depth m"
                                onClickFunction = { update(depth) }
                            }
                        }
                    }
                }
            }
            div("column is-half-mobile is-2-tablet") {
                div("field") {
                    label("label") { +"Capsule PPO2" }
                    div("control td-capsule-ppo2") {
                        input(InputType.text, null, null, "ppo2", "input is-rounded") {
                            onInputFunction = { update() }
                        }
                    }
                    p("help") { +"PPo2 for the capsule" }
                }
            }
            div("column is-half-mobile is-2-tablet") {
                div("field") {
                    label("label") { +"Dive PPO2" }
                    div("control td-dive-ppo2") {
                        input(InputType.text, null, null, "ppo2", "input is-rounded") {
                            onInputFunction = { update() }
                        }
                    }
                    p("help") { +"PPo2 for dives" }
                }
            }
            div("column is-half-mobile is-2-tablet") {
                div("field") {
                    label("label") { +"Helium Ratio" }
                    div("control td-tank-helium") {
                        input(InputType.text, null, null, "helium", "input is-rounded") {
                            onInputFunction = { update() }
                        }
                    }
                    p("help") { +"Helium ratio in diluent" }
                }
            }
            div("column is-half-mobile is-2-tablet") {
                div("field") {
                    label("label") { +"Low GF" }
                    div("control td-gf-low") {
                        input(InputType.text, null, null, "gf-low", "input is-rounded") {
                            onInputFunction = { update() }
                        }
                    }
                    p("help") { +"Low gradient factor" }
                }
            }
            div("column is-half-mobile is-2-tablet") {
                div("field") {
                    label("label") { +"High GF" }
                    div("control td-gf-high") {
                        input(InputType.text, null, null, "gf-high", "input is-rounded") {
                            onInputFunction = { update() }
                        }
                    }
                    p("help") { +"High gradient factor" }
                }
            }
            div("column is-half-mobile is-2-tablet") {
                div("field td-norm") {
                    label("label") { +"P Norm" }
                    div("control") {
                        input(InputType.text, null, null, "gf-norm", "input is-rounded") {
                            value = "${data.normP}"
                            onInputFunction = { update() }
                        }
                    }
                    p("help")
                }
            }
        }
    }

    val depthOptions = container.querySelectorAll(".td-capsule-depth > span.button")

    val capsulePpo2 = container.querySelector("div.td-capsule-ppo2 > input") as HTMLInputElement

    val divePpo2 = container.querySelector("div.td-dive-ppo2 > input") as HTMLInputElement

    val heliumRatio = container.querySelector("div.td-tank-helium > input") as HTMLInputElement

    val gfLow = container.querySelector("div.td-gf-low > input") as HTMLInputElement
    val gfHigh = container.querySelector("div.td-gf-high > input") as HTMLInputElement

    val norm = container.querySelector("div.td-norm > .control > input") as HTMLInputElement
    val normHelp = container.querySelector("div.td-norm > .help") as HTMLParagraphElement

    init {
        refresh()
    }

    override fun refresh() {
        for (i in 0 until depthOptions.length) {
            val span = depthOptions[i] as HTMLSpanElement
            val selected = span.innerText.startsWith("${data.capsuleDepth}")
            span.classList.toggle(isSelected, selected)
            span.classList.toggle(isInfo, selected)
        }

        capsulePpo2.value = "${data.capsuleOxygenPartialPressure}"
        divePpo2.value = "${data.diveOxygenPartialPressure}"
        heliumRatio.value = "${data.heliumRatio}"
        gfLow.value = "${data.gradientFactors.low}"
        gfHigh.value = "${data.gradientFactors.high}"
        normHelp.innerText = "Used norm is ${data.normP}"
    }

    fun update(newDepth: Double = data.capsuleDepth) {
        val old = data
        val normP = norm.value.toDouble().coerceIn(6.0, 50.0)
        val new = old.copy(
            capsuleDepth = newDepth,
            capsuleOxygenPartialPressure = capsulePpo2.value.toDouble(),
            diveOxygenPartialPressure = divePpo2.value.toDouble(),
            heliumRatio = heliumRatio.value.toInt(),
            gradientFactors = GradientFactors(gfLow.value.toInt(), gfHigh.value.toInt()),
            normP = normP
        )
        if (new != old) {
            data = new
            onUpdate(old, data)
        }
    }
}
