package com.tekdiving.deco

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.math.max
import kotlin.math.min
import kotlin.math.roundToInt

const val compartmentCount = 16

val compartmentIndexes = arrayOf(
    "1", "2", "3", "4", "5", "6", "7", "8",
    "9", "10", "11", "12", "13", "14", "15", "16"
)

val nitrogenCompartmentTau = doubleArrayOf(
    5.0, 8.0, 12.5, 18.5, 27.0, 38.3, 54.3, 77.0,
    109.0, 146.0, 187.0, 239.0, 305.0, 390.0, 498.0, 635.0
)

val nitrogenCompartmentA = doubleArrayOf(
    1.1696, 1.0, 0.8618, 0.7562, 0.62, 0.5043, 0.441, 0.40,
    0.375, 0.35, 0.3295, 0.3065, 0.2835, 0.2610, 0.2480, 0.2327
)

val nitrogenCompartmentDeltaM = doubleArrayOf(
    1.7928, 1.5352, 1.3847, 1.2780, 1.2306, 1.1857, 1.1504, 1.1223,
    1.0999, 1.0844, 1.0731, 1.0635, 1.0552, 1.0478, 1.0414, 1.0359
)

val nitrogenCompartmentB =
    Array(nitrogenCompartmentDeltaM.size) { 1.0 / nitrogenCompartmentDeltaM[it] }

val heliumCompartmentTau = doubleArrayOf(
    1.88, 3.02, 4.72, 6.99, 10.21, 14.48, 20.53, 29.11,
    41.20, 55.19, 70.69, 90.34, 115.29, 147.42, 188.24, 240.03
)

val heliumCompartmentA = doubleArrayOf(
    1.6189, 1.383, 1.1919, 1.0458, 0.9220, 0.8205, 0.7305, 0.6502,
    0.5950, 0.5545, 0.5333, 0.5189, 0.5181, 0.5176, 0.5172, 0.5119
)

val heliumCompartmentB = doubleArrayOf(
    0.4770, 0.5747, 0.6527, 0.7223, 0.7582, 0.7957, 0.8279, 0.8553,
    0.8757, 0.8903, 0.8997, 0.9073, 0.9122, 0.9171, 0.9217, 0.9267
)

const val oxygenPercentageInAir = 21
const val oxygenRatioInAir = oxygenPercentageInAir / 100.0

const val oxygenMass = 1.43 // grams per liters at sea level pressure
const val heliumMass = 0.18 // grams per liters at sea level pressure
const val nitrogenMass = 1.25 // grams per liters at sea level pressure

const val partialPressureOxygenMaxOpen = 1.4
const val partialPressureOxygenMaxDeco = 1.6
const val partialPressureOxygenMaxBailout = 1.6
const val partialPressureOxygenMaxSCR = 1.6
const val partialPressureOxygenMaxCCR = 1.3

const val partialPressureOxygenHypoxia = 0.21
const val partialPressureOxygenMin = 1.0
const val decoPartialPressureOxygenMin = 0.8

const val equivalentNarcosisTrimixNitrogen = 39
const val equivalentNarcosisTrimixHelium = 26

const val partialPressureNitrogenMaxTrimixNitrogen =
    (1.0 - oxygenRatioInAir) * ((equivalentNarcosisTrimixNitrogen / 10.0) + seaLevelAtmosphericPressure)
const val partialPressureNitrogenMaxTrimixHelium =
    (1.0 - oxygenRatioInAir) * ((equivalentNarcosisTrimixHelium / 10.0) + seaLevelAtmosphericPressure)

/** Default Volume for diluent for CCR */
const val defaultDiluentVolume = 3.0

/** Default Volume for OC */
const val defaultOpenVolume = 15.0

/** Default volume for deco */
const val defaultDecoVolume = 11.0

/** Default volume for bailout */
const val defaultBailoutVolume = 11.0

data class Compartment(
    /** Compartment index */
    val index: Int,
    /** Compartment name */
    val name: String,
    /** Half saturation time in minutes */
    val tau: Double,
    /** Buhlmann a coefficient */
    val a: Double,
    /** Buhlmann b coefficient */
    val b: Double
) {
    /** Buhlmann a coefficient */
    //val a = 2.0*tau.pow(-1.0/3.0)

    /** Buhlmann b coefficient */
    //val b = 1.005 - tau.pow(-1.0/2.0)
}

val nitrogenCompartments = Array(compartmentIndexes.size) {
    Compartment(
        it, "Nitrogen ${compartmentIndexes[it]}",
        nitrogenCompartmentTau[it], nitrogenCompartmentA[it], nitrogenCompartmentB[it]
    )
}

val heliumCompartments = Array(compartmentIndexes.size) {
    Compartment(
        it, "Helium ${compartmentIndexes[it]}",
        heliumCompartmentTau[it], heliumCompartmentA[it], heliumCompartmentB[it]
    )
}

enum class TankType {
    OC, Deco, CCR, Bailout, SCR, SCRDeco;

    val main
        get() = this == OC || this == CCR || this == SCR

    val ccr: Boolean
        get() = this == CCR || this == Bailout

    val useOnlyOnce: Boolean
        get() = this == Deco || this == Bailout

    val defaultVolume
        get() = when (this) {
            OC -> defaultOpenVolume
            CCR -> defaultDiluentVolume
            Deco -> defaultDecoVolume
            Bailout -> defaultBailoutVolume
            SCR -> defaultDecoVolume
            SCRDeco -> defaultDecoVolume
        }

    val maxPpOxygen
        get() = when (this) {
            OC -> partialPressureOxygenMaxOpen
            CCR -> partialPressureOxygenMaxCCR
            Deco -> partialPressureOxygenMaxDeco
            Bailout -> partialPressureOxygenMaxBailout
            SCR -> partialPressureOxygenMaxSCR
            SCRDeco -> partialPressureOxygenMaxSCR
        }

    val minPpOxygen
        get() = when (this) {
            OC -> partialPressureOxygenMin
            Deco -> decoPartialPressureOxygenMin
            CCR -> partialPressureOxygenMin
            Bailout -> partialPressureOxygenMin
            SCR -> partialPressureOxygenMin
            SCRDeco -> decoPartialPressureOxygenMin
        }
}

enum class TankState {
    None, MoreThanTwoThirdBurned, MoreThanFullyBurned;

    val critical get() = this == MoreThanFullyBurned
}

@Serializable
data class GasRatio(
    val min: Int,
    val real: Int,
    val max: Int
) {
    @Transient
    val ratio = real / 100.0

    val valid
        get() =
            min in 0..100 && max in 0..100 &&
                    min <= max && real in min..max
}

val emptyTank = createTank(spec = TankSpec(volume = 1.0, pressure = 1.0))

fun fixedGazRatio(real: Int) = GasRatio(real, real, real)

enum class GasMix { Nitrox, TrimixNitrogen, TrimixHelium }

fun nitrogen(oxygen: GasRatio, helium: GasRatio, maxNitrogenFraction: Int = 100) = GasRatio(
    (100 - oxygen.max - helium.max).coerceAtLeast(0),
    (100 - oxygen.real - helium.real).coerceAtLeast(0),
    (100 - oxygen.min - helium.min).coerceIn(0, maxNitrogenFraction)
)

@Serializable
data class TankSpec(
    val volume: Double = 15.0,
    val pressure: Double = 210.0
) {
    @Transient
    val expandedVolume = volume * pressure

    @Transient
    val valid = volume >= 0 && pressure >= 0

    override fun toString(): String = "$pressure x $volume"
}

@Serializable
data class TankFill(
    val air: Int,
    val helium: Int,
    val oxygen: Int
) {
    @Transient
    val valid = air >= 0 && helium >= 0 && oxygen >= 0
}


fun createTank(
    type: TankType = TankType.OC,
    mix: TankMix = createTankMix(),
    spec: TankSpec = TankSpec(type.defaultVolume),
    forcedMaxOxygenPartialPressure: Double? = null
) = Tank(type, mix, spec, forcedMaxOxygenPartialPressure)

@Serializable
data class Tank(
    val type: TankType, val mix: TankMix,
    val spec: TankSpec, val forcedMaxOxygenPartialPressure: Double?
) {

    /** Computes oxygen partial pressure for given [pressure]. */
    fun oxygenPartialPressure(pressure: Double) = mix.oxygen.ratio * pressure

    @Transient
    val maxPpOxygen = forcedMaxOxygenPartialPressure ?: type.maxPpOxygen

    fun hypoxiaPressure(dive: DiveType<*>) = when {
        type == TankType.CCR -> 0.0
        dive is DiveType.SCRDive -> partialPressureOxygenHypoxia / dive.options.loopOxygenRatio(mix.oxygen.ratio)
        else -> partialPressureOxygenHypoxia / mix.oxygen.ratio
    }

    fun minimumPressure(dive: DiveType<*>) = when {
        type == TankType.CCR -> 0.0
        dive is DiveType.SCRDive -> type.minPpOxygen / dive.options.loopOxygenRatio(mix.oxygen.ratio)
        else -> type.minPpOxygen / mix.oxygen.ratio
    }

    fun maximumPressure(dive: DiveType<*>) = when {
        type == TankType.CCR -> 155.0
        dive is DiveType.SCRDive -> dive.options.loopOxygenRatio(mix.oxygen.ratio).let {
            min(maxPpOxygen / it, mix.partialPressureNitrogenMax / (1.0 - it))
        }
        else -> min(maxPpOxygen / mix.oxygen.ratio, mix.partialPressureNitrogenMax / mix.nitrogen.ratio)
    }

    fun consumption(pressure: Double, duration: Double, consumption: Int, dive: DiveType<*>) = when {
        type == TankType.CCR -> 0.0
        dive is DiveType.SCRDive -> dive.options.flowRate * duration
        else -> pressure * duration * consumption
    }

    /** Useful volume for given tank, it depends of [type] and [spec] */
    fun usefulVolume(profile: DiveProfile) = when (type) {
        TankType.SCR, TankType.SCRDeco ->
            // TODO compute useful volume from profile.maximumDepth */
            2.0 * spec.expandedVolume / 3.0
        else -> 2.0 * spec.expandedVolume / 3.0
    }

    @Transient
    val correctedNitrogenRatio = when {
        mix.nitrogen.real == 0 && mix.helium.real == 0 -> 0.0
        type == TankType.CCR -> mix.nitrogen.ratio / (mix.nitrogen.ratio + mix.helium.ratio)
        else -> mix.nitrogen.ratio
    }

    @Transient
    val correctedHeliumRatio = when {
        mix.nitrogen.real == 0 && mix.helium.real == 0 -> 0.0
        type == TankType.CCR -> mix.helium.ratio / (mix.nitrogen.ratio + mix.helium.ratio)
        else -> mix.helium.ratio
    }

    @Transient
    val valid = spec.valid && mix.valid

    override fun toString(): String = "$description [$type] ($spec) $mix"

    @Transient
    val description = "${type.name} ${mix.description}"

    @Transient
    val shortDescription = "${if (type == TankType.CCR) "CCR" else ""} ${mix.shortDescription}"

    @Transient
    val id = "${type.name.lowercase()}-${mix.id}"

    fun fillFrom(from: Tank = emptyTank): TankFill {
        val fromAir = from.mix.nitrogen.ratio * from.spec.pressure / (1 - oxygenRatioInAir)
        val fromOxygen = from.mix.oxygen.ratio * from.spec.pressure - fromAir * oxygenRatioInAir
        val fromHelium = from.mix.helium.ratio * from.spec.pressure
        val air = mix.nitrogen.ratio * spec.pressure / (1 - oxygenRatioInAir)
        val oxygen = mix.oxygen.ratio * spec.pressure - air * oxygenRatioInAir
        val helium = mix.helium.ratio * spec.pressure
        return TankFill(
            (air - fromAir).roundToInt(),
            (helium - fromHelium).roundToInt(),
            (oxygen - fromOxygen).roundToInt()
        )
    }
}

@Serializable
data class TankPlannerOptions(
    /** If true, planner is able to propose new tanks */
    val plan: Boolean = true,

    /** Main mix for the dive */
    val forcedMainMix: TankMix? = null,

    /** Secondary forced mixes */
    val forcedMixes: List<TankMix> = listOf(),

    /** Secondary forbidden mixes */
    val rejectedMixes: List<TankMix> = listOf(),

    /** If true, it uses tanks until hypoxia if possible */
    val optimizeTankUsage: Boolean = false,

    /** Existing tanks to use if any */
    val tankSpecs: List<TankSpec>? = null
) {
    @Transient
    val hasOptions =
        !plan || forcedMainMix != null || forcedMixes.isNotEmpty() || rejectedMixes.isNotEmpty() ||
                tankSpecs != null || optimizeTankUsage
}

enum class TankUse { Force, Avoid, IfAvailable }

/** Stores the consumption for a tank*/
@Serializable
data class TankConsumption(
    val tank: Tank, val duration: Double, val burned: Double
)

class TankPlanner(
    /** [DiveProfile] to update with tanks */
    val profile: DiveProfile,

    /** options */
    val options: TankPlannerOptions = TankPlannerOptions()

) {
    /** Specs used for planning (must be declared before computed main tank otherwise it's undefined) */
    private var usedSpecsIndex = 0

    private val computedMainTank: Tank = newTank(
        profile.type.mainTankType, when {
            options.forcedMainMix != null -> options.forcedMainMix
            else -> profile.type.mainTankMix(profile)
        }
    )

    private val computedTanks: MutableList<Tank> =
        // don't adds computed tanks if dive type is CCRDive
        if (profile.type is DiveType.CCRDive) mutableListOf() else
        // adds forced tanks in descending maximum depth for better selection
            options.forcedMixes.asSequence()
                .map { newTank(profile.type.nextTankType, it) }
                .sortedByDescending { profile.tankMaximumDepth(it) }.toMutableList()

    /** Consumed volume for tanks */
    private val consumedVolume: MutableList<TankConsumption> = mutableListOf()

    private val used = mutableListOf<Tank>()

    /** Main tank */
    val mainTank: Tank get() = computedMainTank

    /** Complement tanks */
    val tanks: List<Tank> get() = computedTanks

    /** All tanks including non used */
    val allTanks: List<Tank> get() = (tanks - orderedTanks) + mainTank + (orderedTanks - mainTank)

    /** Are given tank spec are sufficient for planning ? */
    val areTankSpecsSufficient
        get() = options.tankSpecs == null || usedTankCount <= options.tankSpecs.size

    /** Count how many tanks where used (only including the tank where the consumption is more than 0, it excludes CCR). */
    val usedTankCount
        get() = consumedVolume.asSequence().filter { it.burned > 0.0 }.map { it.tank }
            .fold(listOf<Tank>()) { a, t -> if (a.find { it === t } == null) a + t else a }.count()

    /** Burned volume for tank */
    fun burnedForTank(tank: Tank) = consumedVolume.asSequence()
        .filter { it.tank === tank }.fold(0.0) { a, c -> a + c.burned }

    /** Is tank available for use */
    fun available(tank: Tank) = (!tank.type.useOnlyOnce || used.find { it === tank } == null) &&
            burnedForTank(tank) < tank.usefulVolume(profile)

    /** Returns tank state */
    fun tankState(tank: Tank): TankState {
        val burned = burnedForTank(tank)
        return when {
            burned <= tank.usefulVolume(profile) -> TankState.None
            burned < tank.spec.expandedVolume -> TankState.MoreThanTwoThirdBurned
            else -> TankState.MoreThanFullyBurned
        }
    }

    /** Returns which tank was used for given time */
    fun tankAtTime(time: Double): Tank {
        var total = 0.0
        for (pair in consumedVolume) {
            if (time > total && time <= total + pair.duration) return pair.tank
            total += pair.duration
        }
        return mainTank
    }

    /** Returns the pair of start and end use of tank */
    fun timeForTank(tank: Tank): Pair<Double, Double> {
        var min = Double.MAX_VALUE
        var max = Double.MIN_VALUE
        var total = 0.0
        for (it in consumedVolume) {
            if (tank === it.tank) {
                min = min(total, min)
                max = max(total + it.duration, max)
            }
            total += it.duration
        }
        return min to max
    }

    /** Returns the tanks in usage order */
    val orderedTanks
        get() = consumedVolume.asSequence().map { it.tank }.fold(listOf<Tank>()) { list, current ->
            if (list.lastOrNull() === current) list else list + current
        }

    /** Tank order in usage */
    fun tankOrder(tank: Tank): Int = orderedTanks.indexOfFirst { it === tank }

    /** Update burned value */
    private fun updateBurned(tank: Tank, duration: Double, burned: Double) {
        val current = consumedVolume.lastOrNull()
        if (current != null && current.tank === tank) {
            // completes previous consumption
            val last = consumedVolume.removeAt(consumedVolume.size - 1)
            consumedVolume.add(TankConsumption(tank, last.duration + duration, last.burned + burned))
        } else {
            if (current != null) used.add(current.tank)
            // adds consumption
            consumedVolume.add(TankConsumption(tank, duration, burned))
        }
    }

    /** Updates consumption for given tank and returns time steps for each used tanks */
    fun updateConsumption(tank: Tank, sourceDepth: Double, targetDepth: Double, duration: Double) {
        // computes tanks consumption
        val sourcePressure = profile.toPressure(max(sourceDepth, targetDepth))
        val stepConsumption = tank.consumption(sourcePressure, duration, profile.consumption, profile.type)
        val tankState = burnedForTank(tank)

        if (stepConsumption + tankState > tank.usefulVolume(profile)) {
            // the step is too big for the tank fill the first tank (coerce at 0 min to avoid negative infill)
            val leftOver = (tank.usefulVolume(profile) - tankState).coerceAtLeast(0.0)
            val fillDuration = leftOver / (sourcePressure * profile.consumption)
            updateBurned(tank, fillDuration, leftOver)

            // consumption that remains
            var consumption = stepConsumption - leftOver
            var elapsedTime = fillDuration
            var currentTank = tank
            while (consumption > 0) {

                // selected current based on sourceDepth, targetDepth and elapsed time
                val currentDepth = (targetDepth - sourceDepth) * elapsedTime / duration + sourceDepth
                val newTank = tankForDepth(currentDepth)
                if (newTank != null && newTank !== currentTank) {

                    // another tank can be used
                    val consumed = min(consumption, newTank.usefulVolume(profile))
                    val consumedDuration = consumed / (sourcePressure * profile.consumption)
                    updateBurned(newTank, consumedDuration, consumed)
                    consumption -= consumed
                    elapsedTime += consumedDuration
                    currentTank = newTank
                } else {
                    // no other tank can be found, overfill this one with the duration for left over consumption
                    val consumedDuration = consumption / (sourcePressure * profile.consumption)
                    updateBurned(currentTank, consumedDuration, consumption)

                    // sets consumption to 0 to end the loop
                    consumption = 0.0
                }
            }

        } else {
            updateBurned(tank, duration, stepConsumption)
        }
    }

    fun newTank(type: TankType, mix: TankMix): Tank {
        val spec =
            if (type != TankType.CCR && options.tankSpecs != null && usedSpecsIndex < options.tankSpecs.size)
                options.tankSpecs[usedSpecsIndex++] else
                TankSpec(type.defaultVolume)
        return createTank(type, mix, spec)
    }

    /** Creates a new [Tank] for given [depth]. It may return the last created one if the is rejected */
    fun createAndAddNewTank(depth: Double): Tank {
        val previousTank = computedTanks.lastOrNull { profile.tankMaximumDepth(it) >= depth } ?: computedMainTank
        val newMix = profile.type.secondaryTankMix(profile, depth, previousTank)
        return when {
            newMix == null || options.rejectedMixes.contains(newMix) -> previousTank
            else -> {
                // adds new tank to computedTanks in the sorted place
                val newTank = newTank(profile.type.nextTankType, newMix)
                val tankMaximumDepth = profile.tankMaximumDepth(newTank)
                val index = computedTanks.indexOfFirst { profile.tankMaximumDepth(it) < tankMaximumDepth }
                computedTanks.add(if (index < 0) computedTanks.size else index, newTank)
                newTank
            }
        }
    }

    /**
     * Searches for the most appropriate Tank for [depth]. If planing is authorized a new tank may be created.
     */
    fun tankForDepth(depth: Double): Tank? {
        // filter tanks that aren't burned
        val availableTanks = computedTanks.filter { available(it) }

        val selectedTank = availableTanks.fold<Tank, Tank?>(null) { p, c ->
            val currentDistance = profile.tankDistanceFromOptimalDepth(c, depth, options.optimizeTankUsage)
            val previousDistance =
                if (p != null) profile.tankDistanceFromOptimalDepth(p, depth, options.optimizeTankUsage) else null
            if (currentDistance != null && (previousDistance == null || currentDistance < previousDistance)) c else p
        }

        return when {
            selectedTank != null -> selectedTank
            options.plan -> createAndAddNewTank(depth)
            else -> null
        }
    }

    /**
     * Finds the most appropriate tank for given depth. If allowed it will propose new tanks.
     * [mainUse] determines how the main tank should be used.
     */
    fun tankForDepthOrMain(depth: Double, mainUse: TankUse): Tank {
        val mainAvailable =
            burnedForTank(computedMainTank) < computedMainTank.usefulVolume(profile) &&
                    profile.tankDistanceFromOptimalDepth(mainTank, depth, options.optimizeTankUsage) != null

        return when {
            mainUse == TankUse.Force -> computedMainTank
            mainUse == TankUse.IfAvailable && mainAvailable -> computedMainTank
            else -> tankForDepth(depth) ?: computedMainTank
        }
    }

}

/** Utility function to handle TankSpec edition from a Tank index */
fun changeTankSpec(planner: TankPlanner, tankIndex: Int, spec: TankSpec): List<TankSpec> {
    val firstIsCCR = planner.mainTank.type == TankType.CCR
    val specIndex = if (firstIsCCR) tankIndex - 1 else tankIndex

    val newTankSpecs = planner.options.tankSpecs?.toMutableList() ?: mutableListOf()
    // grows tankSpecs if needed
    (newTankSpecs.size until specIndex).forEach {
        val index = if (firstIsCCR) it + 1 else it
        newTankSpecs.add(planner.allTanks[index].spec)
    }

    if (specIndex < newTankSpecs.size) {
        newTankSpecs[specIndex] = spec
    } else {
        newTankSpecs.add(spec)
    }
    return newTankSpecs
}
