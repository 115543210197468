package com.tekdiving.vp2

import bulma.BulmaElement
import bulma.NoContextController
import com.tekdiving.deco.DivePlot
import com.tekdiving.plotter.Line
import com.tekdiving.plotter.LineChartController
import com.tekdiving.plotter.LinePoint
import io.data2viz.color.Colors
import io.data2viz.math.pct
import kotlin.properties.Delegates

class VP2ModelChartController(model: VP2Model) : NoContextController<VP2Model, BulmaElement>() {

    override var data: VP2Model by Delegates.observable(model)
    { _, old, new ->
        if (old != new) refresh()
    }

    override var readOnly: Boolean = true

    val chart = LineChartController(dataSet())

    override val container = chart

    private fun chartLine(
        name: String, plot: List<DivePlot>,
        red: Int, green: Int, blue: Int, fill: Boolean = false
    ) = Line(
        label = name,
        strokeColor = Colors.rgb(red, green, blue),
        fillColor = if (fill) Colors.rgb(red, green, blue, 30.pct) else null,
        points = plot.map { LinePoint(it.time.toInt(), -it.depth) },
    )

    private fun dataSet(): List<Line> = if (data.plot != data.optimizedPlot) listOf(
        chartLine("optimized", data.optimizedPlot, 238, 144, 144, true),
        chartLine("profile", data.plot, 103, 182, 249)
    ) else listOf(chartLine("profile", data.plot, 103, 182, 249))

    override fun refresh() {
        // updates chart
        chart.data = dataSet()
    }
}
