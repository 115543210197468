package com.tekdiving.ideal

import com.tekdiving.deco.DiveProfile
import com.tekdiving.deco.TankPlannerOptions
import kotlinx.serialization.Serializable
import kotlin.math.roundToInt

enum class SexType {
    Female, Male, NotSpecified
}

@Serializable
data class UserProfile(
    /** Diver's sex*/
    val sex: SexType? = null,
    /** Diver's age */
    val age: Int? = null,
    /** Diver's weight */
    val weight: Int? = null,
    /** Diver's height in cm */
    val height: Int? = null,
    /** Does the dive has decompression sickness background */
    val decompressionSicknessBackground: Boolean? = null,
    /** Diver suffers Patent Foramen Ovale */
    val patentForamenOvale: Boolean? = null,
    /** Is the diver physical fit ? from 0, not at all to 100 in olympic shape */
    val physicalFitness: Int? = null
) {
    val bmi: Int
        get() {
            if (height == null || height == 0 || weight == null) return 0
            val meters = this.height / 100.0
            return (weight.toDouble() / (meters * meters)).roundToInt()
        }
}

@Serializable
data class User(
    val keycloakId: String,
    val name: String,
    val email: String,
    val profile: UserProfile
) {
    val _id: String = keycloakId
}

@Serializable
data class DiveConditions(
    /** Temperature in celsius degrees */
    val temperature: Double = 18.0,
    /** Time since last dive in minutes */
    val lastDiveTime: Int = 7 * 24 * 60 /* one week */,
    /** Estimated diver's tiredness or stress, 0 not at all, 10 in nervous break down */
    val diverTiredness: Int = 0,
    /** Is the diver dehydrated ? */
    val dehydrated: Boolean = false
)

@Serializable
data class UserDiveProfile(
    val _id: String,
    val userId: String,
    val date: String,
    val diveProfile: DiveProfile,
    val tankPlannerOptions: TankPlannerOptions,
    val conditions: DiveConditions
)

@Serializable
data class UserDiveProfiles(
    val dives: List<UserDiveProfile>
)

enum class EventType {
    CreateUser, SaveUser, CreateDive, SaveDive, DeleteDive
}

@Serializable
data class Event(
    val _id: String,
    val type: EventType,
    val arguments: List<String>,
    val date: String
)

@Serializable
data class Events(
    val events: List<Event>
)
