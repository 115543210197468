package com.tekdiving.deco

import kotlin.math.roundToInt

@Suppress("unused")
fun decodeLocale(locale: String): Locale {
    val known = Locale.values().map { it.name }
    val found = locale.split("-", ".", "_")
        .asSequence()
        .map { it.toLowerCase().capitalize() }
        .find { known.contains(it) }
    return if (found != null) Locale.valueOf(found) else Locale.En
}

enum class Locale {
    En, Fr
}

enum class Message {
    // Problems
    InvalidProfile,
    MainTankNotSuitable,
    InvalidTank,
    TankNotUsed,
    TankFullyBurned,
    TankTwoThirdBurned,
    MinimalDepthExceeded,
    TankSpecsInsufficient,
    InvalidGradientFactorHigh,
    NotAdvisedGradientFactorHigh,
    ExceededMaximumOTU,
    StopTooLong,

    // Description
    ProfileTitle,
    PlanError,
    PlanWarning,
    DecompressionSummary,
    TankDescription,
    ConsumptionDescription,
    StopDescription,
    DetailsDescription,
    Ppo2Description,
    EquivalentNarcosisDescription,
    TankPlanningDescriptionDeco,
    TankPlanningDescriptionTanks,
    ForcedMainTank,
    ForcedTanks,
    RejectedTanks,
    TankSpecs,
    Tanks,
    Exceeded,
    UpToTwoThirds,

    // Debug
    ComputationStarted,
    ComputationEnded,
    NitrogenSaturation,
    HeliumSaturation
}

private val messageArgumentPattern = Regex("([sdp])?\\$([0-9]+)")

/**
 * A Messages object is able to format [Message]s for a given [Locale] and [UnitSystem].
 *
 * Verbatim message can have several macros:
 * - `$[0-9]+` for arguments
 * - `d$[0-9]+` for distance values
 * - `s$[0-9]+` for speed values
 * - `p$[0-9]+` for speed values
 */
@Suppress("KDocUnresolvedReference")
class Messages(val locale: Locale, val unit: UnitSystem = UnitSystem.International) {

    fun verbatim(id: Message) = when (locale) {
        Locale.En -> englishVerbatim(id)
        Locale.Fr -> frenchVerbatim(id)
    }

    private fun englishVerbatim(id: Message) = when (id) {
        Message.InvalidProfile -> "The profile $0 for maximum depth d$1, bottom time $2 minutes and sac $3 is invalid"
        Message.MainTankNotSuitable -> "Selected main tank isn't suitable for a depth of d$0"
        Message.InvalidTank -> "The tank $0 isn't valid"
        Message.TankNotUsed -> "The tank $0 isn't used"
        Message.TankFullyBurned -> "More than the available volume is used for tank $0"
        Message.TankTwoThirdBurned -> "More than two third of the available volume is used for tank $0"
        Message.MinimalDepthExceeded -> "Minimal depth of d$0 is exceeded at $1 minutes"
        Message.TankSpecsInsufficient -> "Given tank specifications are insufficient"
        Message.InvalidGradientFactorHigh -> "High gradient factor $0 is too far from advised $1"
        Message.NotAdvisedGradientFactorHigh -> "High gradient factor $0 is not the advised $1"
        Message.ExceededMaximumOTU -> "OTU is $0 exceeds the recommended of $1"
        Message.StopTooLong -> "Stop at depth $0 is far too long ($1 minutes)"
        Message.ProfileTitle -> "Decompression for dive at maximum depth of d$0 and bottom time of $1 minutes with a consumption of $2 liters per minutes."
        Message.PlanError -> "Error: This plan is strictly discouraged"
        Message.PlanWarning -> "Warning: This plan is not advised"
        Message.DecompressionSummary -> "Time to surface is $0 minutes with $1 stops."
        Message.TankDescription -> "$0, $1 liters x p$2"
        Message.ConsumptionDescription -> "consumption $0 liters"
        Message.StopDescription -> "$0 minutes at d$1 on $2"
        Message.DetailsDescription -> """
                    Details:
                    This decompression was computed using a specialized Bühlmann ZHL-16C with gradient factors of $0.
                    The ascending speed until the first stop is s$1, then the speed between stops is s$2 distant of d$3.
                    The considered alveolar pressure is p$4.
                """.trimIndent()
        Message.Ppo2Description -> "The oxygen partial pressure (ppo2) is between p$0 and p$1 for $2 and between p$3 and p$4 for $5."
        Message.EquivalentNarcosisDescription -> "The limiting depth equivalent narcosis is d$0."
        Message.TankPlanningDescriptionDeco -> "Tank planning is configured for decompression optimization"
        Message.TankPlanningDescriptionTanks -> "Tank planning is configured for tanks optimization"
        Message.ForcedMainTank -> "forced main mix"
        Message.ForcedTanks -> "forced mix"
        Message.RejectedTanks -> "rejected mix"
        Message.TankSpecs -> "planned tanks"
        Message.Tanks -> "tanks"
        Message.Exceeded -> "exceeded"
        Message.UpToTwoThirds -> "used up to two thirds"
        Message.ComputationStarted -> "Computation started for $0"
        Message.ComputationEnded -> "Computation ended with time to surface $0 and $1 stops"
        Message.NitrogenSaturation -> "Nitrogen saturation: $0"
        Message.HeliumSaturation -> "Helium saturation: $0"
    }

    @Suppress("SpellCheckingInspection")
    private fun frenchVerbatim(id: Message) = when (id) {
        Message.InvalidProfile -> "Le profil $0 à la profondeur maximum d$1, temps fond $2 minutes et consomation $3 est invalide"
        Message.MainTankNotSuitable -> "Le bloc principal sélectionné n'est pas adapté à une profondeur de d$0"
        Message.InvalidTank -> "Le bloc $0 n'est pas valide"
        Message.TankNotUsed -> "Le bloc $0 n'est pas utilisé"
        Message.TankFullyBurned -> "Plus que le volume disponible du bloc est utilisé pour $0"
        Message.TankTwoThirdBurned -> "Plus des deux tiers du volume disponible du bloc $0 est utilisé"
        Message.MinimalDepthExceeded -> "La profondeur minimal de d$0 est dépassée à $1 minutes"
        Message.TankSpecsInsufficient -> "Les tailles des blocs fournis sont insuffisants"
        Message.InvalidGradientFactorHigh -> "High gradient factor $0 est trop distant du conseil $1"
        Message.NotAdvisedGradientFactorHigh -> "High gradient factor $0 n'est pas celui conseillé de $1"
        Message.ExceededMaximumOTU -> "L'OTU de $0 depasse la recommendation de $1"
        Message.StopTooLong -> "Palier à la profondeur $0 is est bien trop long ($1 minutes)"
        Message.ProfileTitle -> "Décompression pour une plongée à d$0 maximum et un temps fond de $1 minutes avec une consommation de $2 litres par minutes."
        Message.PlanError -> "Erreur: Cette planification est strictement découragée"
        Message.PlanWarning -> "Attention: Cette planification n'est pas conseillée"
        Message.DecompressionSummary -> "La durée de remontée est de $0 minutes avec $1 paliers."
        Message.TankDescription -> "$0, $1 litres x p$2"
        Message.ConsumptionDescription -> "consommation $0 litres"
        Message.StopDescription -> "$0 minutes à d$1 avec $2"
        Message.DetailsDescription -> """
                    Détails:
                    Cette décompression a été calculée avec un algorithme Bühlmann ZHL-16C spécialisé avec des gradient factors de $0.
                    Le vitesse de remontée jusqu'au premier palier est de s$1, puis de s$2 entre les paliers distants de d$3.
                    La pression alveolaire utilisée est de p$4.
                """.trimIndent()
        Message.Ppo2Description -> "La pression partielle d'oxygène (ppo2) est entre p$0 et p$1 pour $2 et entre p$3 et p$4 pour $5"
        Message.EquivalentNarcosisDescription -> "La profondeur de narcose equivalente limite est d$0."
        Message.TankPlanningDescriptionDeco -> "La planification de bloc est configurée pour l'optimisation de la décompression"
        Message.TankPlanningDescriptionTanks -> "La planification de bloc est configurée pour l'optimisation des blocs"
        Message.ForcedMainTank -> "le mélange principal forcé"
        Message.ForcedTanks -> "les mélanges forcés"
        Message.RejectedTanks -> "les mélanges refusés"
        Message.TankSpecs -> "blocs prévus"
        Message.Tanks -> "blocs"
        Message.Exceeded -> "dépassé"
        Message.UpToTwoThirds -> "utilisé à plus de 2 tiers"
        Message.ComputationStarted -> "Le calcul commence pour $0"
        Message.ComputationEnded -> "Le calcul est terminé avec une DTR de $0 et $1 paliers"
        Message.NitrogenSaturation -> "Saturation d'azote: $0"
        Message.HeliumSaturation -> "Saturation d'hélium: $0"
    }

    fun format(id: Message, vararg args: Any) = format(id, args.toList())

    fun format(id: Message, args: List<Any>) = replaceArguments(verbatim(id), args)

    private fun replaceArguments(input: String, args: List<Any>) =
        findAndReplace(input, messageArgumentPattern) {
            val index = it.groups[2]!!.value.toInt()
            if (index in args.indices) {
                val argument = args[index].toString()
                when (it.groups[1]?.value) {
                    "d" -> "${unit.toDistanceValue(argument.toDouble()).roundToInt()} ${unit.distanceUnit(locale)}"
                    "s" -> "${unit.toSpeedValue(argument.toDouble()).roundToInt()} ${unit.speedUnit(locale)}"
                    "p" -> "${unit.toPressureValue(argument.toDouble())} ${unit.pressureUnit(locale)}"
                    else -> argument
                }
            } else {
                it.groups[0]!!.value
            }
        }

    private fun findAndReplace(input: String, regex: Regex, block: (MatchResult) -> Any): String {
        val result = StringBuilder()
        var lastMatch = 0
        val matches = regex.findAll(input)
        matches.forEach { match ->
            result.append(input.subSequence(lastMatch, match.range.start))
            result.append(block(match))
            lastMatch = match.range.last + 1
        }
        result.append(input.subSequence(lastMatch, input.length))
        return result.toString()
    }
    /*
    fun format(id: Message, args: List<Any>) =
        args.foldIndexed(verbatim(id)) { index: Int, acc: String, any: Any ->
            acc.replace("$$index", "$any")
        }
    */


    fun formatHourMinute(minutes: Int) =
        when (minutes) {
            in 0..59 -> "$minutes minutes"
            else -> "${minutes / 60} h. ${minutes % 60} min."
        }

    fun formatHourMinute(minutes: Double) = formatHourMinute(minutes.roundToInt())

}


class DecompressionPrinter(
    val decompression: Decompression,
    val messages: Messages
) {
    val profile = decompression.profile
    val stops = decompression.stops
    val planner = decompression.tankPlanner
    val options = planner.options
    val mainTank = planner.mainTank

    private fun format(message: Message, vararg args: Any) = messages.format(message, *args)

    private fun Sequence<String>.join(separator: String = ", ", postfix: String = "") =
        this.filterNot { it.isEmpty() }.joinToString(separator, postfix = postfix)

    fun tankDescription(tank: Tank) = sequenceOf(
        format(Message.TankDescription, tank.description, tank.spec.volume, tank.spec.pressure),
        if (tank.type == TankType.CCR) "" else
            format(Message.ConsumptionDescription, planner.burnedForTank(tank).roundToInt()),
        when (planner.tankState(tank)) {
            TankState.MoreThanFullyBurned -> format(Message.Exceeded)
            TankState.MoreThanTwoThirdBurned -> format(Message.UpToTwoThirds)
            TankState.None -> ""
        }
    ).join()

    fun stopDescription(stop: DecompressionStop) =
        messages.format(Message.StopDescription, stop.time, stop.depth, stop.tank(planner).description)

    val tanksMessage = sequenceOf(
        messages.verbatim(Message.Tanks).capitalize() + ":\n",
        planner.allTanks.joinToString("") { " - ${tankDescription(it)}\n" }
    ).join("")

    val ppo2Description = profile.type.let {
        val ppo2 = if (it.mainTankType.ccr) oxygenPartialPressureSet(it) else it.mainTankType.maxPpOxygen
        messages.format(
            Message.Ppo2Description,
            it.mainTankType.minPpOxygen, ppo2, it.mainTankType,
            it.nextTankType.minPpOxygen, it.nextTankType.maxPpOxygen, it.nextTankType
        )
    }

    val equivalentNarcosisDescription =
        format(
            Message.EquivalentNarcosisDescription,
            if (mainTank.mix.helium.ratio > mainTank.mix.nitrogen.ratio)
                equivalentNarcosisTrimixHelium else
                equivalentNarcosisTrimixNitrogen
        )

    val forcedMainMix = if (options.forcedMainMix != null)
        "${format(Message.ForcedMainTank)} ${options.forcedMainMix.description}" else ""

    val forcedMixes = if (options.forcedMixes.isNotEmpty())
        "${format(Message.ForcedTanks)} ${options.forcedMixes.joinToString(", ") { it.description }}" else ""

    val rejectedMixes = if (options.rejectedMixes.isNotEmpty())
        "${format(Message.RejectedTanks)} ${options.rejectedMixes.joinToString(", ") { it.description }}" else ""

    val tankSpecs = if (options.tankSpecs != null)
        "${format(Message.TankSpecs)} ${options.tankSpecs.joinToString(", ") { it.toString() }}" else ""

    val tankPlanner = if (options.hasOptions) sequenceOf(
        format(if (options.optimizeTankUsage) Message.TankPlanningDescriptionTanks else Message.TankPlanningDescriptionDeco),
        forcedMainMix, forcedMixes, rejectedMixes, tankSpecs
    ).join(", ", ".") else ""

    val errors = if (!decompression.valid) sequenceOf(
        format(Message.PlanError) + ":",
        decompression.problems.map { "- ${it.format(messages)}" }.join("\n")
    ).join("\n", "\n") else ""

    val warnings = if (decompression.warnings.count() > 0) sequenceOf(
        format(Message.PlanWarning),
        decompression.warnings.map { "- ${it.format(messages)}" }.join("\n")
    ).join("\n", "\n") else ""

    val details =
        sequenceOf(
            format(
                Message.DetailsDescription,
                decompression.gradientFactors,
                decompression.firstStopAscendingSpeed,
                decompression.stopAscendingSpeed,
                decompression.decompressionStopDelta,
                decompression.saturation.alveolarPressure
            ),
            ppo2Description,
            equivalentNarcosisDescription,
            tankPlanner
        ).join("\n")


    val message = sequenceOf(
        format(Message.ProfileTitle, profile.maximumDepth, profile.bottomTime, profile.consumption),
        errors, warnings,
        format(Message.DecompressionSummary, decompression.timeToSurface, stops.size),
        stops.joinToString("") { " - ${stopDescription(it)}\n" },
        tanksMessage,
        details
    ).join("\n")
}
