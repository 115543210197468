package com.tekdiving.deco

import com.tekdiving.plotter.Line
import com.tekdiving.plotter.LineChartController
import com.tekdiving.plotter.LinePoint
import io.data2viz.color.Colors
import io.data2viz.math.pct
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onClickFunction
import org.w3c.dom.*
import kotlin.math.roundToInt

val maxOtu = 850

val spec = TankSpec(volume = 40.0, pressure = 300.0)
val air = createTank(TankType.OC, forcedMaxOxygenPartialPressure = 1.7)
val nx50 = createTank(TankType.OC, createTankMix(fixedGazRatio(50)), spec, forcedMaxOxygenPartialPressure = 1.7)
val fullO2 = createTank(TankType.OC, createTankMix(fixedGazRatio(100)), spec, forcedMaxOxygenPartialPressure = 1.7)

class MultiLevelDiveProfileController(
    initialLevels: List<Level> = listOf(
        Level(10, 60, air),
        Level(20, 180, nx50),
        Level(7, 60, fullO2),
        Level(25, 120, nx50)
    )
) : MultiLevelController {
    override val tanks = arrayOf(air, nx50, fullO2)

    override var levels: List<Level> = initialLevels
        set(value) {
            if (field != value) {
                field = value
                updateLevels()
            }
        }

    var messages: Messages = Messages(Locale.En)
        set(value) {
            if (field != value) {
                field = value
                updateDecompression()
            }
        }


    override var profile: DiveProfile = createDiveProfile()
    private val tankPlannerOptions = TankPlannerOptions(plan = false)
    private var decompression: Decompression = Decompression(profile)

    private var profileChart: LineChartController = LineChartController()
    private var otuChart: LineChartController = LineChartController()

    val container: HTMLElement = document.create.div {
        // tests
        div("title") { +"Multi" }

        levelTable {
            th { +"OTU" }
            th { +"Deco" }
        }

        div("columns is-multiline td-dive-errors")

        div("title") { +"Dive" }
        div("td-dive-chart") {}

        div("title") { +"OTU" }
        div("td-otu-chart") {}

        div("title td-dive-details-title") { +"Details" }
        div("td-dive-details")

        div("title td-tank-internal-title") {
            +"Internal (Click for more details)"
            onClickFunction = { internalContent.classList.toggle("is-folded") }
        }
        div("is-folded td-tank-internal")
    }

    override val levelsBody = container.querySelector("table.td-dive-levels > tbody") as HTMLTableSectionElement

    val errors = container.querySelector("div.td-dive-errors") as HTMLDivElement

    val profileChartDiv = container.querySelector("div.td-dive-chart") as HTMLDivElement

    val otuChartDiv = container.querySelector("div.td-otu-chart") as HTMLDivElement

    val details = container.querySelector("div.td-dive-details") as HTMLDivElement

    val internalContent = container.querySelector("div.td-tank-internal") as HTMLDivElement

    init {
        updateLevels()
        profileChartDiv.appendChild(profileChart.root)
        otuChartDiv.appendChild(otuChart.root)
    }

    fun otuForLevel(index: Int): Int {
        val time = levels.subList(0, index + 1).fold(0.0) { a, c -> a + c.time }
        return decompression.otuAtTime(time).roundToInt()
    }

    fun ttsForLevel(index: Int): Int {
        val depth = leveledPlot(levels.subList(0, index + 1).map { DivePlot(it.time.toDouble(), it.depth.toDouble(), it.tank) })
        val program = programFromExposingFactor(depth)
        val profile = createDiveProfile(depth, program = program)

        val decompression = Decompression(profile)
        decompression.compute()
        return decompression.timeToSurface
    }

    override fun <T> TagConsumer<T>.levelExtra() {
        td {
            span("td-dive-otu")
        }
        td {
            span("td-dive-tts")
        }
    }

    override fun refreshLevelExtra(index: Int, level: Level, row: HTMLTableRowElement) {
        val otu = row.querySelector("span.td-dive-otu") as HTMLSpanElement
        val otuForLevel = otuForLevel(index)
        otu.innerText = "$otuForLevel"
        if (otuForLevel > maxOtu) {
            otu.classList.add("has-text-danger")
        } else {
            otu.classList.remove("has-text-danger")
        }

        val deco = row.querySelector("span.td-dive-tts") as HTMLSpanElement
        val tts = ttsForLevel(index)
        deco.innerText = "$tts min."
    }

    fun refresh() {
        refreshLevels()

        // refresh decompression
        val decompressionPrinter = DecompressionPrinter(decompression, messages)

        // updates errors
        errors.innerHTML = ""
        decompression.problems.map { p ->
            document.create.article("column is-3 message is-warning") {
                div("message-header") {
                    +"For ${p.where.time} min. at ${p.where.depth} m"
                }
                div("message-body") {
                    +p.format(messages)
                }
            }
        }.forEach {
            errors.appendChild(it)
        }

        // creates a new chart
        profileChart.data = listOf(
            Line(
                label = "Ceiling", strokeWidth = 2.0,
                strokeColor = Colors.rgb(144, 238, 144), fillColor = Colors.rgb(144, 238, 144, 50.pct),
                points = decompression.minDepthPlot().map { LinePoint(it.time.roundToInt(), -it.depth) },
            ),
            Line(
                "Profile", strokeWidth = 2.0, strokeColor = Colors.rgb(103, 182, 249), pointRadius = 5.0,
                points = decompression.profilePlot().map { LinePoint(it.time.roundToInt(), -it.depth) },
            )
        )

        otuChart.data = listOf(
            Line(
                label = "OTU Limit", strokeWidth = 2.0, strokeColor = Colors.Web.red,
                points = listOf(LinePoint(0, maxOtu.toDouble()), LinePoint(decompression.totalTime, maxOtu.toDouble())),
            ),
            Line(
                label = "OTU", strokeWidth = 2.0, strokeColor = Colors.Web.orange,
                points = decompression.otuPlot().map { LinePoint(it.time.roundToInt(), it.depth) },
            )
        )

        /*
        chartOptions.tooltips.custom = { tooltipModel ->
            val dataPoints = tooltipModel.dataPoints
            if (dataPoints != null && dataPoints.isNotEmpty()) {
                val extra = mutableListOf<String>()
                val pointTime = dataPoints[0].xLabel.toDouble()

                if (dataPoints[0].datasetIndex == 0) {
                    val problem = decompression.problems.find { it.where.time == pointTime }
                    if (problem != null) {
                        extra.add(problem.format(messages))
                    }
                }

                extra.add(decompression.tankPlanner.tankAtTime(pointTime).description)

                tooltipModel.afterBody = extra.toTypedArray()
                val textSize = tooltipModel.bodyFontSize.toInt()
                tooltipModel.height = tooltipModel.height.toInt() + textSize * extra.size
                tooltipModel.width = max(tooltipModel.width.toInt(), extra.asSequence().map { it.length * textSize / 2 }.maxOrNull() ?: 0)
            }
        }
         */

        // update details
        details.innerHTML = ""
        details.innerText = decompressionPrinter.message

        // update internal
        internalContent.innerHTML = ""
        decompression.info.map {
            document.create.div(
                "notification ${when (it.type) {
                    InfoType.Info -> ""; InfoType.Warning -> "is-warning"; InfoType.Critical -> "is-danger"
                }}"
            ) {
                div("level") {
                    div("level-start") { +it.type.toString() }
                    div("level-end") { +it.where.toString() }
                }
                div {
                    +it.format(messages)
                }
            }
        }.forEach { internalContent.appendChild(it) }
    }

    override fun updateProfile() {
        println("Updating profile")
        profile = createDiveProfile(leveledPlot(levels.map { DivePlot(it.time.toDouble(), it.depth.toDouble(), it.tank) }))
        updateDecompression()
    }

    private fun updateDecompression() {
        println("Updating decompression")
        decompression = Decompression(profile, tankPlannerOptions, infoLevel = InfoType.Info)
        decompression.compute()
        refresh()
    }
}
