package com.tekdiving.deco

import com.tekdiving.ideal.DiveConditions
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onInputFunction
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import kotlin.properties.Delegates.observable

class DiveConditionsController : Controller<DiveConditions> {

    var onUpdate: ((old: DiveConditions, new: DiveConditions, DiveConditionsController) -> Unit) =
        { _, _, _ -> }

    override var data by observable(DiveConditions()) { _, old, new ->
        if (old != new) {
            onUpdate(old, new, this)
            refresh()
        }
    }

    override val container: HTMLElement = document.create.columns("is-mobile is-multiline") {
        column(size(2, 2, 6)) {
            div("field") {
                label("label") { +"Temperature" }
                div("control td-dive-temperature") {
                    input(InputType.text, null, null, "temperature", "input is-rounded") {
                        onInputFunction = { update() }
                    }
                }
                p("help") { +"Water temperature in celsius degrees" }
            }
        }
        column(size(2, 2, 6)) {
            div("field") {
                label("label") { +"Last dive" }
                div("control td-dive-lastdive") {
                    input(InputType.text, null, null, "lastdive", "input is-rounded") {
                        onInputFunction = { update() }
                    }
                }
                p("help") { +"Time in minutes since last dive" }
            }
        }
        column(size(2, 2, 6)) {
            div("field") {
                label("label") { +"Tiredness" }
                div("control td-dive-tiredness") {
                    input(InputType.text, null, null, "tiredness", "input is-rounded") {
                        onInputFunction = { update() }
                    }
                }
                p("help") { +"Diver's tiredness or stress, 0 for not at all, 10 in nervous break down" }
            }
        }
        column(size(2, 2, 6)) {
            div("field td-dive-dehydrated") {
                label("label") {
                    input(InputType.checkBox, classes = "checkbox") {
                        onChangeFunction = { update() }
                    }
                    +"Diver is dehydrated"
                }
            }
        }
    }

    val temperature = container.querySelector("div.td-dive-temperature > input") as HTMLInputElement
    val lastdive = container.querySelector("div.td-dive-lastdive > input") as HTMLInputElement
    val tiredness = container.querySelector("div.td-dive-tiredness > input") as HTMLInputElement
    val dehydrated = container.querySelector("div.td-dive-dehydrated > .label > .checkbox") as HTMLInputElement

    init {
        refresh()
    }

    override fun refresh() {
        temperature.value = "${data.temperature}"
        lastdive.value = "${data.lastDiveTime}"
        tiredness.value = "${data.diverTiredness}"
        dehydrated.checked = data.dehydrated
    }

    fun update() {
        data = data.copy(
            temperature = temperature.value.toDouble(), lastDiveTime = lastdive.value.toInt(),
            diverTiredness = tiredness.value.toInt(), dehydrated = dehydrated.checked
        )
    }
}
