package com.tekdiving.deco

import kotlinx.browser.document
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.label
import kotlinx.html.progress
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlin.properties.Delegates.observable

class RiskFactorController : Controller<RiskFactor> {

    var label = "Estimated risk"
        set(value) {
            field = value
            riskLabel.innerText = value
        }

    override var data by observable(UnknownRiskFactor) { _, _, _ ->
        refresh()
    }

    override val container: HTMLElement = document.create.div("field") {
        label("label") { +label }
        div("control")
    }


    val riskResult = container.querySelector("div.control") as HTMLDivElement
    val riskLabel = container.querySelector("label.label") as HTMLElement

    init {
        refresh()
    }

    override fun refresh() {
        val color = when {
            data.p >= lowRiskFactor -> "success"
            data.p >= mediumRiskFactor -> "warning"
            data.p >= highRiskFactor -> "danger"
            else -> ""
        }

        riskLabel.classList.remove("has-text-success", "has-text-warning", "has-text-danger")
        if (color.isNotEmpty()) riskLabel.classList.add("has-text-$color")

        riskResult.innerHTML = ""
        riskResult.appendChild(document.create.div {
            progress("progress ${if (color.isNotEmpty()) "is-$color" else ""}") {
                value = "${data.percentage}"
                max = "100"
            }
        })
    }
}
