package com.tekdiving.deco

import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import org.w3c.dom.*

const val isSelected = "is-selected"
const val isInfo = "is-info"

data class Level(
    val depth: Int,
    val time: Int,
    val tank: Tank
)

interface MultiLevelController {

    val tanks: Array<Tank>

    var levels: List<Level>

    val levelsBody: HTMLTableSectionElement

    val profile: DiveProfile

    fun updateProfile()

    fun addStep(depth: Int = 10, time: Int = 60) {
        val newLevels = levels.toMutableList()
        newLevels.add(Level(depth, time, tanks[0]))
        levels = newLevels
    }

    fun removeStep(index: Int) {
        val newLevels = levels.toMutableList()
        newLevels.removeAt(index)
        levels = newLevels
    }

    fun updateStep(index: Int, tank: Tank = levels[index].tank) {
        val div = levelsBody.children[index] as HTMLTableRowElement

        val depthInput = div.querySelector("div.td-dive-depth > input") as HTMLInputElement
        val newDepth = depthInput.value.toInt()

        val timeInput = div.querySelector("div.td-dive-time > input") as HTMLInputElement
        val newTime = timeInput.value.toInt()

        val newLevel = Level(newDepth, newTime, tank)
        if (newLevel != levels[index]) {
            val newLevels = levels.toMutableList()
            newLevels[index] = newLevel
            levels = newLevels
        }
    }

    fun <T> TagConsumer<T>.levelExtra() {}

    fun updateLevels() {
        val lineCount = levelsBody.childElementCount
        when {
            lineCount < levels.size -> {
                // adds missing rows
                for (i in 0 until (levels.size - lineCount)) {
                    levelsBody.append {
                        level(lineCount + i)
                    }
                }
            }
            lineCount > levels.size -> {
                // removes rows
                for (i in 0 until (lineCount - levels.size)) {
                    val i1 = lineCount - i - 1
                    val child = levelsBody.children[i1]
                    if (child != null) {
                        levelsBody.removeChild(child)
                    }
                }
            }
        }
        updateProfile()
    }

    fun refreshLevelExtra(index: Int, level: Level, row: HTMLTableRowElement) {}

    fun refreshLevels() {
        // refresh each level
        for (i in 0 until levels.size) {
            val level = levels[i]
            val row = levelsBody.children[i] as HTMLTableRowElement

            val depth = row.querySelector("div.td-dive-depth > input") as HTMLInputElement
            depth.value = "${level.depth}"

            val time = row.querySelector("div.td-dive-time > input") as HTMLInputElement
            time.value = "${level.time}"

            if (tanks.size > 1) {
                tanks.forEach {
                    val button = row.querySelector("span.td-dive-${it.id}") as HTMLSpanElement
                    button.classList.toggle(isSelected, it == level.tank)
                    button.classList.toggle(isInfo, it == level.tank)
                }
            }

            val info = row.querySelector("div.td-dive-depth > label.help") as HTMLLabelElement
            val maximumDepth = profile.tankMaximumDepth(level.tank)
            if (maximumDepth < level.depth) {
                info.innerText = "${level.tank.description} is rated for a maximum depth of $maximumDepth meters"
            } else {
                info.innerText = ""
            }

            refreshLevelExtra(i, level, row)
        }
    }

    fun FlowContent.levelTable(
        defaultDepth: Int = 10, defaultTime: Int = 60,
        extraHeaders: TR.() -> Unit = {}
    ) =
        table("td-dive-levels table is-striped is-hoverable is-fullwidth") {
            thead {
                tr {
                    th {
                        span("button is-small is-rounded") {
                            +"+"
                            onClickFunction = { addStep(defaultDepth, defaultTime) }
                        }
                    }
                    if (tanks.size > 1) th { +"Tank" }
                    th {
                        +"Depth"
                        br
                        label("help") { +"in meters" }
                    }
                    th {
                        +"Time"
                        br
                        label("help") { +"in min." }
                    }
                    extraHeaders()
                    th { +"" }
                }
            }
            tbody { /* Place for levels */ }
        }


    fun <T> TagConsumer<T>.level(index: Int, extra: TR.() -> Unit = {}) =
        tr {
            td("td-dive-level") { +"${index + 1}" }
            if (tanks.size > 1) {
                td {
                    div("field") {
                        div("td-dive-type buttons has-addons") {
                            tanks.forEach { tank ->
                                span("button is-rounded is-selected is-small td-dive-${tank.id}") {
                                    +tank.shortDescription
                                    onClickFunction = { updateStep(index, tank) }
                                }
                            }
                        }
                    }
                }
            }
            td {
                div("field") {
                    div("control td-dive-depth") {
                        input(InputType.text, null, null, "depth", "input is-rounded is-small") {
                            onInputFunction = { updateStep(index) }
                        }
                        label("help")
                    }
                }
            }
            td {
                div("field") {
                    div("control td-dive-time") {
                        input(InputType.text, null, null, "time", "input is-rounded is-small") {
                            onInputFunction = { updateStep(index) }
                        }
                    }
                }
            }
            levelExtra()
            td {
                span("button is-small is-rounded") {
                    +"-"
                    onClickFunction = { removeStep(index) }
                }
            }
        }
}
