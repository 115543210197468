package com.tekdiving.deco

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.math.floor
import kotlin.math.max
import kotlin.math.roundToInt

fun createTankMix(
    oxygen: GasRatio = fixedGazRatio(oxygenPercentageInAir),
    helium: GasRatio = fixedGazRatio(0),
    nitrogen: GasRatio = nitrogen(oxygen, helium)
) = TankMix(oxygen, helium, nitrogen)

@Serializable
data class TankMix(val oxygen: GasRatio, val helium: GasRatio, val nitrogen: GasRatio) {
    val type
        get() = when (helium.real) {
            0 -> GasMix.Nitrox
            in 1..30 -> GasMix.TrimixNitrogen
            else -> GasMix.TrimixHelium
        }

    @Transient
    val partialPressureNitrogenMax =
        if (helium.ratio > nitrogen.ratio)
            partialPressureNitrogenMaxTrimixHelium else
            partialPressureNitrogenMaxTrimixNitrogen

    fun changeOxygen(newO2: Int): TankMix {
        val delta = newO2 - oxygen.real
        val newHe = if (delta > 0 && nitrogen.real < delta) helium.real - (delta - nitrogen.real) else helium.real
        return copy(
            oxygen = oxygen.copy(real = newO2),
            helium = helium.copy(real = newHe),
            nitrogen = nitrogen.copy(real = 100 - newO2 - newHe)
        )
    }

    fun changeHelium(newHe: Int): TankMix {
        val delta = newHe - helium.real
        val newO2 = if (delta > 0 && nitrogen.real < delta) oxygen.real - (delta - nitrogen.real) else oxygen.real
        return copy(
            oxygen = oxygen.copy(real = newO2),
            helium = helium.copy(real = newHe),
            nitrogen = nitrogen.copy(real = 100 - newO2 - newHe)
        )
    }

    val valid
        get() = oxygen.valid && helium.valid && nitrogen.valid && oxygen.ratio + helium.ratio <= 1.0

    val id
        get() = when {
            oxygen.real == 100 -> "o2"
            helium.real == 0 && oxygen.real == 21 -> "air"
            helium.real == 0 -> "nx-${oxygen.real}"
            nitrogen.real == 0 -> "hx-${oxygen.real}"
            else -> "tx-${oxygen.real}-${helium.real}"
        }

    val description
        get() = when {
            oxygen.real == 100 -> "100% o2"
            helium.real == 0 && oxygen.real == 21 -> "Air"
            helium.real == 0 -> "Nitrox ${oxygen.real}"
            nitrogen.real == 0 -> "Heliox ${oxygen.real}/${helium.real}"
            else -> "Trimix ${oxygen.real}/${helium.real}"
        }

    val shortDescription
        get() = when {
            oxygen.real == 100 -> "100% o2"
            helium.real == 0 && oxygen.real == 21 -> "Air"
            helium.real == 0 -> "Nx ${oxygen.real}"
            nitrogen.real == 0 -> "Hx ${oxygen.real}/${helium.real}"
            else -> "Tx ${oxygen.real}/${helium.real}"
        }

    override fun toString(): String =
        "o2 [${oxygen.min},${oxygen.real},${oxygen.max}] " +
                "he[${helium.min},${helium.real},${helium.max}] " +
                "n2[${nitrogen.min},${nitrogen.real},${nitrogen.max}]"
}

/**
 * Creates ccr main tank. When choosing main rebreather.
 */
fun mainRebreatherTankMix(profile: DiveProfile): TankMix {
    val oxygen = when (profile.advisedGasMix) {
        GasMix.Nitrox -> GasRatio(15, oxygenPercentageInAir, oxygenPercentageInAir)
        GasMix.TrimixNitrogen -> GasRatio(15, 15, oxygenPercentageInAir)
        GasMix.TrimixHelium -> GasRatio(0, if (profile.maximumDepth < 130) 7 else 5, 8)
    }

    val helium = when (profile.advisedGasMix) {
        GasMix.Nitrox -> GasRatio(0, 0, 30)
        GasMix.TrimixNitrogen -> GasRatio(0, 30, 30)
        GasMix.TrimixHelium -> GasRatio(62, if (profile.maximumDepth < 130) 70 else 77, 100)
    }

    return TankMix(oxygen, helium, nitrogen(oxygen, helium))
}

/**
 * Round given [value] to an integer using the given [threshold].
 * The default threshold value is selected to allow the best precision for oxygen percentage in tanks
 */
private fun adjustedRound(value: Double, threshold: Double = 0.92): Int {
    val floored = floor(value).toInt()
    return if (value - floored <= threshold) floored else floored + 1
}

fun gasFraction(partialPressure: Double, absolutePressure: Double) =
    (100 * partialPressure / absolutePressure).coerceAtMost(100.0)

/** Creates main open tank */
fun mainOpenTankMix(profile: DiveProfile): TankMix {
    val minOxygen = adjustedRound(gasFraction(TankType.OC.minPpOxygen, profile.maximumPressure)).coerceAtMost(21)
    val maxOxygen = maxOxygen(TankType.OC, profile.maximumPressure)
    val oxygen = GasRatio(minOxygen, maxOxygen, maxOxygen)

    val referenceMix = profile.advisedGasMix
    val maxNitrogenFraction = floor(
        when (referenceMix) {
            GasMix.TrimixHelium -> gasFraction(
                partialPressureNitrogenMaxTrimixHelium,
                profile.maximumPressure
            ).coerceAtMost(30.0)
            else -> gasFraction(
                partialPressureNitrogenMaxTrimixNitrogen,
                profile.maximumPressure
            )
        }
    ).roundToInt()

    val minHelium = (100 - oxygen.max - maxNitrogenFraction).coerceAtLeast(0)
    val maxHelium = 100 - oxygen.min
    val helium = when (profile.advisedGasMix) {
        GasMix.Nitrox -> GasRatio(0, 0, 15)
        GasMix.TrimixNitrogen -> GasRatio(minHelium, minHelium, 30)
        GasMix.TrimixHelium -> GasRatio(50.coerceAtLeast(minHelium), minHelium.coerceAtLeast(50), maxHelium)
    }
    val nitrogen = nitrogen(oxygen, helium, maxNitrogenFraction)
    return TankMix(oxygen, helium, nitrogen)
}

fun maxNitrogenFraction(previous: Tank, pressure: Double) = floor(
    when (previous.mix.type) {
        GasMix.TrimixHelium -> gasFraction(
            partialPressureNitrogenMaxTrimixHelium,
            pressure
        )
        else -> gasFraction(
            partialPressureNitrogenMaxTrimixNitrogen,
            pressure
        )
    }
).roundToInt()

/** Computes bailout tank */
fun newBailoutTankMix(profile: DiveProfile, depth: Double, previous: Tank): TankMix {
    val pressure = profile.toPressure(depth)
    val previousRealHelium = previous.mix.helium.real
    val maxNitrogenFraction = maxNitrogenFraction(previous, pressure)
    val maxO2FromPpo2 = maxOxygen(TankType.Bailout, pressure)
    val minO2FromPpo2 = adjustedRound(gasFraction(TankType.Bailout.minPpOxygen, pressure))

    val minHelium = max(100 - maxO2FromPpo2 - maxNitrogenFraction, previousRealHelium - 30).coerceAtLeast(0)
    val realHelium = if (previous.type == TankType.CCR)
        previousRealHelium.coerceAtMost(100 - minO2FromPpo2) else
        minHelium
    val maxOxygenFromHelium = 100 - realHelium

    val minOxygen = minO2FromPpo2.coerceAtMost(maxOxygenFromHelium)
    val maxHelium = (100 - minOxygen).coerceAtLeast(0)
    val maxOxygen = maxO2FromPpo2.coerceAtMost(maxOxygenFromHelium)

    val oxygen = GasRatio(minOxygen, maxOxygen, maxOxygen)
    val helium = GasRatio(minHelium, realHelium, maxHelium)
    val nitrogen = nitrogen(oxygen, helium, maxNitrogenFraction)
    return TankMix(adjustOxygen(oxygen, helium), helium, nitrogen)
}

fun newDecoTankMix(profile: DiveProfile, depth: Double, previous: Tank): TankMix {
    val pressure = profile.toPressure(depth)
    val maxOxygen = maxOxygen(TankType.Deco, pressure)
    val minOxygen = adjustedRound(gasFraction(TankType.Deco.minPpOxygen, pressure))
    val oxygen = GasRatio(minOxygen, maxOxygen, maxOxygen)
    val maxNitrogenFraction = maxNitrogenFraction(previous, pressure)
    val helium = heliumForDeco(oxygen, maxNitrogenFraction, previous.mix.helium)
    val nitrogen = nitrogen(oxygen, helium, maxNitrogenFraction)
    return TankMix(adjustOxygen(oxygen, helium), helium, nitrogen)
}


/** Creates main open tank */
fun srcTankMix(pressure: Double, options: DiveOptions.SCROptions): TankMix {
    val minOxygen = adjustedRound(
        options.tankOxygenRatio(gasFraction(TankType.SCR.minPpOxygen, pressure))
    ).coerceAtLeast(21)
    val maxOxygen = adjustedRound(options.tankOxygenRatio(gasFraction(TankType.SCR.maxPpOxygen, pressure)))
    val oxygen = GasRatio(minOxygen, maxOxygen, maxOxygen)
    val maxNitrogenFraction = floor(gasFraction(partialPressureNitrogenMaxTrimixNitrogen, pressure)).roundToInt()
    val helium = GasRatio(0, 0, 0)
    val nitrogen = nitrogen(oxygen, helium, maxNitrogenFraction)
    return TankMix(oxygen, helium, nitrogen)
}

/** Computes maximum oxygen for given pressure and tank, adapting limits for better advise */
fun maxOxygen(tankType: TankType, pressure: Double): Int =
    adjustedRound(gasFraction(tankType.maxPpOxygen, pressure)).let {
        when (it) {
            99, 100 -> 100
            else -> it
        }
    }

/** Computes for deco  tank */
fun heliumForDeco(oxygen: GasRatio, maxNitrogenFraction: Int, previousHelium: GasRatio): GasRatio {
    val min = max(100 - oxygen.max - maxNitrogenFraction, previousHelium.real - 30).coerceAtLeast(0)
    val real = min.coerceIn(min, max(min, previousHelium.real))
    val max = when (oxygen.max + maxNitrogenFraction) {
        in 0..80 -> 100 - oxygen.min
        in 80..100 -> 30
        else -> 15
    }.coerceIn(real, max(real, previousHelium.max))
    return GasRatio(min, real, max)
}

/** Adjusts oxygen in case of more than 100% ratio from oxygen + helium, since oxygen is computed first */
fun adjustOxygen(oxygen: GasRatio, helium: GasRatio) = (oxygen.real + helium.real).let {
    if (it > 100) oxygen.copy(real = oxygen.real - it + 100, max = oxygen.max - it + 100)
    else oxygen
}
