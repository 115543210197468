package com.tekdiving.deco

import bulma.BulmaElement
import bulma.Controller
import com.tekdiving.plotter.Line
import com.tekdiving.plotter.LineChartController
import com.tekdiving.plotter.LinePoint
import com.tekdiving.plotter.QuadraticLine
import io.data2viz.color.Colors
import io.data2viz.math.pct
import kotlin.math.roundToInt
import kotlin.properties.Delegates

class DiveChartController(
    plot: List<DivePlot>, decompression: Decompression?,
    val onPlotUpdate: ((List<DivePlot>) -> Unit)? = null
) : Controller<List<DivePlot>, Decompression?, BulmaElement> {

    private val ceilingColor = Colors.rgb(238, 144, 144)
    private val ceilingFillColor = ceilingColor.withAlpha(30.pct)
    private val profileColor = Colors.rgb(103, 182, 249)

    override var data: List<DivePlot> by Delegates.observable(plot)
    { _, old, new ->
        if (old != new) onPlotUpdate?.invoke(data)
    }

    override var context by Delegates.observable(decompression)
    { _, old, new -> if (old != new) refresh() }

    override var readOnly: Boolean = true

    val messages: Messages = Messages(Locale.En)

    val plotter = LineChartController(lines(), stepName = "Time")

    override val container = plotter

    /*
    private val chartOptions = BasicLineChartOptions().apply {
        scales.xAxes = arrayOf(BasicLinearAxisOptions())
        scales.xAxes[0].ticks.stepSize = 5
        scales.xAxes[0].ticks.stepSize = 5
        scales.xAxes[0].ticks.suggestedMax = maxTime
        scales.xAxes[0].ticks.callback = { value, _ -> formatDayHourMinute(value.toInt()) }

        scales.yAxes = arrayOf(BasicLinearAxisOptions())
        scales.yAxes[0].apply {
            ticks.beginAtZero = true
            ticks.suggestedMin = maxDepth
            ticks.callback = { value, _ -> "${value.toInt()} m." }
        }

        tooltips.custom = { tooltipModel ->
            val dataPoints = tooltipModel.dataPoints
            if (dataPoints != null && dataPoints.isNotEmpty()) {
                val extra = mutableListOf<String>()
                val pointTime = dataPoints[0].xLabel.toDouble()

                context?.let { decompression ->
                    if (dataPoints[0].datasetIndex == 0) {
                        decompression.problems.find { it.where.time == pointTime }?.let {
                            extra.add(it.format(messages))
                        }
                    }

                    extra.add(decompression.tankPlanner.tankAtTime(pointTime).description)
                }

                tooltipModel.afterBody = extra.toTypedArray()
                val textSize = tooltipModel.bodyFontSize.toInt()
                tooltipModel.height = tooltipModel.height.toInt() + textSize * extra.size
                tooltipModel.width =
                    max(tooltipModel.width.toInt(), extra.asSequence().map { it.length * textSize / 2 }.maxOrNull() ?: 0)
            }
        }

        // No animation
        animation.duration = 50

        if (onPlotUpdate != null) {
            // Move to cave diving planning
            dragData = true
            dragX = true
            onDragStart = { _, element ->
                val dataSet = element._chart.data.datasets[element._datasetIndex.toInt()]
                val time = dataSet.data?.get(element._index.toInt())?.x?.toDouble() ?: -1.0
                time > 0 && time <= (context?.profile?.finalTime ?: 120.0)
            }
            onDragEnd = { _, _, index, value ->
                val new = data.toMutableList()
                val plotIndex = index.toInt() - 1
                val previousTime = if (plotIndex <= 0) 0.0 else new[plotIndex - 1].time
                val newTime = if (plotIndex >= new.size - 1) Double.MAX_VALUE else new[plotIndex + 1].time
                val time = value.x.toDouble().coerceIn(previousTime, newTime)
                new[plotIndex] = DivePlot(time, -value.y.toDouble())
                data = new
            }

            onClick = { event, _ ->
                chart.getElementsAtXAxis(event).find { it._datasetIndex == 1 }?.let {
                    val new = data.toMutableList()
                    val index = it._index.toInt()
                    if (event.shiftKey) {
                        // delete a point
                        new.removeAt((index - 1).coerceIn(0, new.size))
                    } else {
                        // add a point
                        val plotIndex = index - 1
                        val before = when {
                            plotIndex < 0 -> DivePlot(0.0, 0.0)
                            plotIndex >= new.size - 1 -> data.last()
                            else -> data[plotIndex]
                        }
                        val after = when {
                            plotIndex < new.size - 1 -> data[plotIndex + 1]
                            else -> before.copy(time = before.time + 10)
                        }
                        val newIndex = (plotIndex + 1).coerceIn(0, new.size)
                        new.add(
                            newIndex,
                            DivePlot((before.time + after.time) / 2.0, (before.depth + after.depth) / 2.0)
                        )
                    }
                    data = new
                }
            }
        }
    }
    */

    private fun lines(): List<Line> = context?.let { context ->
        listOf(
            Line(
                "ceiling", pointRadius = 0.0, lineType = QuadraticLine(50.pct, 50.pct),
                strokeColor = ceilingColor, fillColor = ceilingFillColor, strokeWidth = 2.0,
                points = context.minDepthPlot().map { LinePoint(it.time.roundToInt(), -it.depth) }
            ),
            Line(
                "profile", pointRadius = 5.0, strokeColor = profileColor,
                strokeWidth = 2.0, lineType = QuadraticLine(50.pct, 50.pct),
                points = context.profilePlot().map { LinePoint(it.time.roundToInt(), -it.depth) }
            ),
        )
    } ?: listOf(
        Line(
            "profile", pointRadius = 5.0,
            strokeColor = profileColor, strokeWidth = 2.0,
            points = data.map { LinePoint(it.time.roundToInt(), -it.depth) }
        )
    )

    override fun refresh() {
        // updates chart
        plotter.data = lines()
        plotter.refresh()
    }
}
