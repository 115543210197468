package com.tekdiving.client

import com.tekdiving.deco.FillTankController
import com.tekdiving.deco.MultiLevelDiveProfileController
import com.tekdiving.deco.PlotProfileController
import com.tekdiving.deco.SquareProfileController
import com.tekdiving.utp.UnderThePoleController
import com.tekdiving.vp2.VP2ModelController
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URLSearchParams

data class Page(
    val title: String,
    val id: String,
    val callback: (root: HTMLElement) -> Unit
) {
    fun authorized(): Boolean = true
}

val pages = listOf(
    Page("Planning", "planning", ::planning),
    //Page("Cave", "cave", ::cave),
    //Page("Multi", "multi", ::multi),
    Page("Capsule", "capsule", ::capsule),
    Page("VP2", "vp2", ::vp2),
    Page("Fill", "fill", ::fill),
    Page("Reference", "reference", ::reference),
)

const val contentSelector = "section.td-main div.container"

fun activatePage(page: Page) {
    updateActivePage(page)

    val root = document.querySelector(contentSelector) as HTMLElement
    root.innerHTML = ""
    page.callback(root)
}

fun main() {
    index()
}

@HtmlTagMarker
fun HtmlBlockTag.idealHeader() =
    section("section") {
        val navBarId = "mainNavBar"
        div("container") {
            nav("navbar is-transparent") {
                div("navbar-brand") {
                    a(href = "/", classes = "navbar-item ") {
                        img("I3deal diving", "assets/images/logo-i3deal-blue-200.png") {
                            width = "200px"
                        }
                    }
                    div("navbar-burger burger") {
                        attributes["data-target"] = navBarId
                        span { }
                        span { }
                        span { }
                    }
                }
                div("navbar-menu") {
                    id = navBarId
                    div("navbar-start")
                    div("navbar-end") {
                        div("navbar-item") {
                            div("field is-grouped") {
                                p("control") {
                                    span("tag is-info") { id = "date"; +"Date" }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

@HtmlTagMarker
fun HtmlBlockTag.idealFooter() {
    footer("footer") {
        div("level") {
            div("level-item has-text-centered") {
                strong { +"I3deal diving platform" }
                +Entities.nbsp
                +" by "
                +Entities.nbsp
                a("https://www.tekdiving-sciences.com") {
                    img("Tek Diving", "assets/images/logo-tekdiving-200.png") { }
                }
                +"."
            }
        }
    }
}

@Suppress("unused")
@JsName("index")
fun index() {
    val root = document.body!! //document.querySelector(contentSelector) as HTMLElement
    root.appendChild(document.create.div("container") {
        idealHeader()
        section("central td-main") {
            div("container") {
                div("card") {
                    a {
                        div("card-content") {
                            div("media") {
                                div("media-left") {
                                    figure("image is-48x48") {
                                        img("Planning", "assets/images/icons8-scuba-diving-100.png")
                                    }
                                }
                                div("media-content") {
                                    p("title is-4") { +"Dive planning" }
                                    p("subtitle is-6") { +"Plan safe dive for your physiology" }
                                }
                            }
                        }
                        div("card-image") {
                            figure("image is-2by1") {
                                img("Diver", "assets/images/diver1-2782.jpeg")
                            }
                        }
                        onClickFunction = {
                            activatePage(pages[0])
                        }
                    }
                }
            }
        }
        idealFooter()
    })

    // gets params active page if any to activate it is allowed
    val params = URLSearchParams(window.location.search)
    params.get("page")?.let { id ->
        pages.find { it.id == id }?.let { page ->
            activatePage(page)
        }
    }

    initialize()

}

fun cave(root: HTMLElement) {
    root.appendChild(document.create.div("title") { +"Cave planning" })
    root.appendChild(PlotProfileController().container)
}

fun fill(root: HTMLElement) {
    root.appendChild(FillTankController().container)
}

fun planning(root: HTMLElement) {
    val controller = SquareProfileController()
    root.appendChild(controller.container)
}

fun capsule(root: HTMLElement) {
    root.appendChild(UnderThePoleController().container)
}

fun vp2(root: HTMLElement) {
    root.appendChild(VP2ModelController().root)
}

fun multi(root: HTMLElement) {
    root.appendChild(MultiLevelDiveProfileController().container)
}
