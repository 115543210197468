package com.tekdiving.vp2

import com.tekdiving.deco.*
import kotlin.math.log10
import kotlin.math.pow

@OptIn(ExperimentalStdlibApi::class)
class VP2Model(val dive: VP2Dive) {

    val aDayInMinutes = 60.0 * 24.0

    val bottomDepth = dive.bottomDepth
    val bottomTime = dive.bottomTime

    val optimizedSpeed: Double = 0.05 * dive.ppO2

    val averageDepth: Double = dive.k1 * bottomDepth * bottomTime

    private fun speed(depth: Double): Double {
        val b: Double = dive.slowestSpeed / averageDepth.pow(2)
        val speed = 10.0.pow(2.0 * log10(depth) + log10(b))
        return speed.coerceIn(dive.slowestSpeed, dive.maxSpeed)
    }

    val plot = buildList {
        val descendingTime = timeForDistance(bottomDepth, standardDescendingSpeed)
        add(DivePlot(0.0, 0.0))
        add(DivePlot(descendingTime, bottomDepth))
        add(DivePlot(descendingTime + bottomTime, bottomDepth))

        if (dive.program >= Program.Saturation) {
            val time = bottomDepth / dive.slowestSpeed + descendingTime + bottomTime
            add(DivePlot(time, 0.0))
        } else {
            // Test the speed each minute
            var currentTime = descendingTime + bottomTime
            var currentDepth = bottomDepth
            while (currentDepth > 0) {
                val speed = speed(currentDepth)
                if (speed <= 0) break
                currentDepth -= speed.coerceAtMost(currentDepth)
                currentTime += 1

                add(DivePlot(currentTime, currentDepth))
            }
        }
    }


    val totalTime = plot.last().time

    val timeToSurface = totalTime - (timeForDistance(bottomDepth, standardDescendingSpeed) + bottomTime)

    val otu = otu(dive.ppO2)

    val optimizedPlot =
        if (dive.program >= Program.Saturation && totalTime > (aDayInMinutes + bottomTime)) buildList {

            // dive is at saturation and takes more than a day
            val descendingTime = timeForDistance(bottomDepth, standardDescendingSpeed)
            add(DivePlot(0.0, 0.0))
            add(DivePlot(descendingTime, bottomDepth))
            add(DivePlot(descendingTime + bottomTime, bottomDepth))

            // uses otu to compute how much of decompression can be used and
            // stay at a ppo2 where the otu doesn't grow for the rest of the day.
            var day = 0
            var currentTime = descendingTime + bottomTime
            var currentDepth = bottomDepth

            /**
             * Updates currentDepth and currentTime width given [speed] and [time].
             * It adds a [DivePlot] at targeted point.
             * If currentDepth reaches zero before the time it stops when it reaches zero.
             *
             * If the currentDepth is already zero no plot is added.
             */
            fun updateTimeAndDepth(speed: Double, time: Double) {
                if (currentDepth > 0.0) {
                    val distance = speed * time
                    if (distance < currentDepth) {
                        currentDepth -= distance
                        currentTime += time
                    } else {
                        val timeToZero = currentDepth / speed
                        currentDepth = 0.0
                        currentTime += timeToZero
                    }
                    add(DivePlot(currentTime, currentDepth))
                }
            }

            while (currentDepth > 0) {
                // find time for current ppo2 to reach maximum ppo2
                val timeAtSpeed = (otuMax(day) / otu).coerceAtMost(aDayInMinutes)
                updateTimeAndDepth(optimizedSpeed, timeAtSpeed)
                if (timeAtSpeed < aDayInMinutes) {
                    updateTimeAndDepth(dive.slowestSpeed, aDayInMinutes - timeAtSpeed)
                }
                day += 1
            }
        } else plot

    val optimizedTotalTime = optimizedPlot.last().time

    val optimized = totalTime > optimizedTotalTime

}
