package com.tekdiving.deco

import kotlinx.browser.document
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.h1
import kotlinx.html.js.div
import kotlinx.html.span
import org.w3c.dom.HTMLSpanElement
import kotlin.properties.Delegates.observable

class FillTankController : Controller<Pair<Tank, Tank>> {

    val defaultSourceTank = createTank(
        type = TankType.OC,
        mix = createTankMix(GasRatio(21, 21, 100), GasRatio(0, 0, 100)),
        spec = TankSpec(pressure = 1.0)
    )

    val defaultTargetTank = createTank(
        type = TankType.OC,
        mix = createTankMix(GasRatio(1, 21, 100), GasRatio(0, 0, 100))
    )

    override var data: Pair<Tank, Tank> by observable(defaultSourceTank to defaultTargetTank)
    { _, _, new ->
        sourceController.data = new.first
        targetController.data = new.second
        refresh()
    }

    val sourceController = TankController(data.first).apply {
        onUpdateSpec = { _, new, _ -> updateSourceTank(spec = new) }
        onUpdateMix = { _, new, _ -> updateSourceTank(mix = new) }
    }

    val targetController = TankController(data.second).apply {
        onUpdateSpec = { _, new, _ -> updateTargetTank(spec = new) }
        onUpdateMix = { _, new, _ -> updateTargetTank(mix = new) }
    }

    fun updateSourceTank(spec: TankSpec = data.first.spec, mix: TankMix = data.first.mix) {
        data = data.first.copy(spec = spec, mix = mix) to data.second
    }

    fun updateTargetTank(spec: TankSpec = data.second.spec, mix: TankMix = data.second.mix) {
        data = data.first to data.second.copy(spec = spec, mix = mix)
    }

    override val container = document.create.div("columns is-centered") {
        div("column is-3-tablet is-12-mobile td-source") {
            h1("title") { +"Source" }
        }

        div("column is-2-tablet is-12-mobile") {
            h1("title") { +"Fill with" }

            div(" td-tank") {
                div("level") {
                    span("level-start label") { +"Oxygen" }
                    span("level-start td-fill-o2")
                }
                div("level") {
                    span("level-start label") { +"Helium" }
                    span("level-start td-fill-he")
                }
                div("level") {
                    span("level-start label") { +"Air" }
                    span("level-start td-fill-air")
                }
            }
        }
        div("column is-3-tablet is-12-mobile td-target") {
            h1("title") { +"Target" }
        }
    }

    val air = container.querySelector("span.td-fill-air") as HTMLSpanElement
    val helium = container.querySelector("span.td-fill-he") as HTMLSpanElement
    val oxygen = container.querySelector("span.td-fill-o2") as HTMLSpanElement

    init {
        container.appendAsChildOf("div.td-source", sourceController.container)
        container.appendAsChildOf("div.td-target", targetController.container)
        refresh()
    }

    override fun refresh() {
        val fill = data.second.fillFrom(data.first)
        air.innerText = "${fill.air} bars"
        helium.innerText = "${fill.helium} bars"
        oxygen.innerText = "${fill.oxygen} bars"
    }
}
